import React, { Component } from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { enableContext } from "../../auth/secret";
import Loading from "../../components/Loading";
import { Button } from "../../components/UTControls";
import { APP_NAME } from "../../constants/app";
import { dashboardHeaders } from "../../constants/dictionary";
import ActionBar from "../../containers/ActionBar";
import DashboardDetail from "../../containers/modals/dashboardDetail";
import { SortIcon, _generateTableHeader, customSort, tableStyle } from "../../helpers/tableHelper";
import { fetch_dashboard } from "../../redux/Dashboard/actions";
import { ChangeSidebar } from "../../redux/Navs/actions";

class index extends Component {
	state = {
		records: [],
		isLoading: true,
		isShowModal: false,
		dataTableColumns: [],
		selectedRecord: {},
	};

	componentDidMount = () => {
		this.props.ChangeSidebar({ currentPage: this.props.match.url, actionName: "Dashboard" });
		this.props.fetch_dashboard({ page: 1, rowsPerPage: 10000, orderBy: "id desc" });
		this.setState({ dataTableColumns: _generateTableHeader(dashboardHeaders, ["detail"], [this.handleViewDetail]) });
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.dashboard !== this.props.dashboard) {
			this.setState({ records: this.props.dashboard, isLoading: false });
		}
	}
	handleViewDetail = (record) => {
		this.setState({ selectedRecord: record, isShowModal: true });
	};

	render() {
		return (
			<div className="ut-container">
				<ActionBar title={this.props.actionName}>
					<Button
						color="success"
						size="md"
						buttonStyle="filled"
						name="បង្ហាញលើទូរទស្សន៍"
						onClick={() => {
							enableContext
								? window.open(`${window.location.origin}/${APP_NAME}/app/tv-view`)
								: window.open(`${window.location.origin}/app/tv-view`);
						}}
					/>
				</ActionBar>
				<DashboardDetail
					detail={this.state.selectedRecord}
					isShow={this.state.isShowModal}
					toggle={() => this.setState({ isShowModal: !this.state.isShowModal })}
				/>
				<DataTable
					fixedHeader
					noDataComponent={<span style={{ margin: "20px 0px" }}>គ្មានទិន្នន័យ</span>}
					fixedHeaderScrollHeight="calc(100vh - 220px)"
					noHeader
					pointerOnHover
					striped
					highlightOnHover
					data={this.state.records}
					columns={this.state.dataTableColumns}
					sortIcon={<SortIcon />}
					customStyles={tableStyle}
					progressPending={this.state.isLoading}
					progressComponent={<Loading />}
					sortFunction={customSort}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	dashboard: state.Dashboard.dashboard,

	actionName: state.Navs.actionName,
});

export default connect(mapStateToProps, { ChangeSidebar, fetch_dashboard })(index);
