import { GET_SYSTEM_ROLE, FIND_SYSTEM_ROLE } from "./types";

const initialState = {
	systemRoles: [],
	systemRole: {},
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case GET_SYSTEM_ROLE:
			state.systemRoles = actions.payload.systemRoles;
			return { ...state };
		case FIND_SYSTEM_ROLE:
			state.systemRole = actions.payload.systemRole;
			return { ...state };
		default:
			return { ...state };
	}
};
