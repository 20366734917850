import { CHANGE_SIDEBAR, CHANGE_REPORT_SIDEBAR, CHANGE_SETTING_SIDEBAR } from "./types";

const initialState = {
	currentPage: window.location.pathname,
	currentSetting: window.location.pathname,
	actionName: "",
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case CHANGE_SIDEBAR: {
			state.currentPage = actions.payload.currentPage;
			state.actionName = actions.payload.actionName ? actions.payload.actionName : "";
			return { ...state };
		}
		case CHANGE_REPORT_SIDEBAR: {
			state.currentReport = actions.payload.currentReport;
			state.actionName = actions.payload.actionName ? actions.payload.actionName : "";
			return { ...state };
		}
		case CHANGE_SETTING_SIDEBAR: {
			state.currentSetting = actions.payload.currentSetting;
			state.actionName = actions.payload.actionName ? actions.payload.actionName : "";
			return { ...state };
		}
		default:
			return { ...state };
	}
};
