import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import ActionBar from "../ActionBar";
import { translation } from "../../constants/translate";

class index extends Component {
	render() {
		return (
			<div className="ut-setting-sidebar">
				<ActionBar title="Setting" centered underlined />
				<div>
					{this.props.Menus.map((menu) => {
						if (menu.id === "Setting") {
							return menu.subs.map((sub) => {
								return <SideItem path={sub.to} name={sub.label} active={this.props.actionName} />;
							});
						}
						return undefined;
					})}
				</div>
			</div>
		);
	}
}
class SideItem extends Component {
	state = {};

	render() {
		return (
			<React.Fragment>
				<Link to={this.props.path} style={{ textDecoration: "none" }}>
					<span className={this.props.name === this.props.active ? "ut-setting-sidebar-menu-active" : "ut-setting-sidebar-menu"}>
						{translation[this.props.name]}
					</span>
				</Link>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	actionName: state.Navs.actionName,
	currentSetting: state.Navs.currentSetting,
	Menus: state.Layout.Menus,
});

export default connect(mapStateToProps, {})(index);
