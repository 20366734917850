export const translation = {
  //Menu
  Dashboard: 'ផ្ទាំងព័ត៌មាន',
  Tracking: 'តាមដាន',
  'Vehicle Brokes': 'ឡានខូច',
  'Vehicle Change Tires': 'ប្ដូរកង់ឡាន',
  'Vehicle Engine Oil': 'ប្ដូរប្រេងម៉ាស៊ីន',
  'Vehicle Accident Information': 'គ្រោះថ្នាក់',
  'Vehicle Type': 'ប្រភេទយានយន្ត',
  'Year Vehicle': 'ឆ្នាំផលិត',
  'Choose': 'ជ្រើសរើស',
  Vehicles: 'យានយន្ដ',
  Branches: 'សាខា',
  Report: 'របាយការណ៍',
  Setting: 'ការកំណត់',
  Users: 'អ្នកប្រើប្រាស់',
  'System Roles': 'ការកំណត់សិទ្ធិអ្នកប្រើប្រាស់',
  'Change Password': 'ផ្លាស់ប្ដូរលេខសំងាត់',

  //Control
  search: 'ស្វែងរក',
  searchBranch: 'ស្វែងរកសាខា',
  searchCar: 'ស្វែងរកយាន្តយន្ត',
  searching: 'កំពុងស្វែងរក...',
  'ADD NEW': 'បង្កើតថ្មី',
  'REFRESH': 'ទំព័រដើម',
  BACK: 'ត្រឡប់ក្រោយ',
  UPDATE: 'កែប្រែ',
  UPDATING: 'កំពុងកែប្រែ...',
  SAVE: 'រក្សាទុក',
  SAVING: 'កំពុងរក្សាទុក...',
  Date: 'កាលបរិច្ឆេទ',
  Driver: 'អ្នកបើកបរ',
  Direction: 'ទិសដៅ',
  injuryStatus: 'ជនរងគ្រោះ និងស្ថានភាពរបួស',
  'Accident Reason': 'មូលហេតុនៃការគ្រោះថ្នាក់ និងការខូចខាតរថយន្ដ',
  'Compensation Date': 'កាលបរិច្ឆេទបញ្ចប់រឿង និងសំណង',
  'Oil Amount': 'ចំនួនប្រេងម៉ាស៊ីន',
  'Change Oil Date': 'ថ្ងៃបានប្ដូរប្រេងម៉ាស៊ីន',
  'Date From': 'ចាប់ពីថ្ងៃ',
  'Date To': 'ដល់ថ្ងៃ',
  'Report Type': 'ប្រភេទរបាយការណ៍',

  //Action
  'Add Tracking': 'ចេញដំណើរ',
  Origin: 'ដើមទី',
  Passing: 'ឆ្លងកាត់',
  Destination: 'ចុងទី',
  Remark: 'កំណត់សម្គាល់',

  'View Vehicle Type': 'បង្ហាញប្រភេទយានយន្ដ',
  'Add Vehicle Type': 'បង្កើតប្រភេទយានយន្ដថ្មី',
  'Edit Vehicle Type': 'កែប្រែប្រភេទយានយន្ដ',

  'View Vehicle Repair': 'បង្ហាញព័ត៌មានឡានខូច',
  'Add Vehicle Repair': 'បង្កើតព័ត៌មានឡានខូចថ្មី',
  'Edit Vehicle Repair': 'កែប្រែព័ត៌មានឡានខូច',

  'View Change Tire': 'បង្ហាញព័ត៌មានប្ដូរកង់ឡាន',
  'Add Change Tire': 'បង្កើតព័ត៌មានប្ដូរកង់ឡានថ្មី',
  'Edit Change Tire': 'កែប្រែព័ត៌មានប្ដូរកង់ឡាន',

  'View Change Oil': 'បង្ហាញព័ត៌មានប្ដូរប្រេងម៉ាស៊ីន',
  'Add Change Oil': 'បង្កើតព័ត៌មានប្ដូរប្រេងម៉ាស៊ីនថ្មី',
  'Edit Change Oil': 'កែប្រែព័ត៌មានប្ដូរប្រេងម៉ាស៊ីន',

  'View Accident': 'បង្ហាញព័ត៌មានគ្រោះថ្នាក់',
  'Add Accident': 'បង្កើតព័ត៌មានគ្រោះថ្នាក់ថ្មី',
  'Edit Accident': 'កែប្រែព័ត៌មានគ្រោះថ្នាក់',

  'View Vehicle': 'បង្ហាញយានយន្ដ',
  'Add Vehicle': 'បង្កើតយានយន្ដថ្មី',
  'Edit Vehicle': 'កែប្រែយានយន្ដ',
  'Vehicle Name': 'ឈ្មោះយានយន្ដ',

  'View Branch': 'បង្ហាញសាខា',
  'Add Branch': 'បង្កើតសាខា',
  'Edit Branch': 'កែប្រែសាខា',

  'View User': 'បង្ហាញអ្នកប្រើប្រាស់',
  'Add User': 'បង្កើតអ្នកប្រើប្រាស់',
  'Edit User': 'កែប្រែអ្នកប្រើប្រាស់',
  Profile: 'រូបថត',
  'Full Name': 'ឈ្មោះពេញ',
  Username: 'ឈ្មោះគណនី',
  Gender: 'ភេទ',
  'System Role': 'សិទ្ធិប្រើប្រាស់',
  Telephone: 'លេខទូរស័ព្ទ',

  Password: 'ពាក្យសំងាត់',
  'Re-Type Password': 'វាយពាក្យសំងាត់ម្ដងទៀត',

  'View System Role': 'បង្ហាញសិទ្ធិអ្នកប្រើប្រាស់',
  'Add System Role': 'បង្កើតសិទ្ធិអ្នកប្រើប្រាស់',
  'Edit System Role': 'កែប្រែសិទ្ធិអ្នកប្រើប្រាស់',
  'Select Available Users': 'ជ្រើសរើសអ្នកប្រើប្រាស់',

  'Old Password': 'ពាក្យសំងាត់ចាស់',
  'New Password': 'ពាក្យសំងាត់ថ្មី',

  //Table
  'N°': 'លេខរៀង',
  NAME: 'យានយន្ត',
  BRANCH: 'សាខា',
  USERNAME: 'ឈ្មោះគណនី',
  'VEHICLE YEAR': 'ឆ្នាំផលិត',
  'SITUATION VEHICLE': 'ឡានថ្មី ឬមួយទឹក',
  DRIVER: 'អ្នកបើកបរ',
  'VEHICLE TYPE': 'ប្រភេទយានយន្ត',
  VEHICLE: 'យានយន្ត',
  OLD_TIRE_SERIAL: 'លេខកូដកង់ចាស់',
  NEW_TIRE_SERIAL: 'លេខកូដកង់ថ្មី',
  OLD_WHEEL: 'លេខកូដកង់សាគួរចាស់',
  NEW_WHEEL: 'លេខកូដកង់សាគួរថ្មី',
  'TOTAL TIRE CHANGED': 'ចំនួនកង់ដែលបានប្ដូរ',
  TYPE: 'ប្រភេទ',
  ORIGIN: 'ដើមទី',
  DESTINATION: 'ចុងទី',
  START: 'ម៉ោងចេញដំណើរ',
  PASSING: 'ឆ្លងកាត់',
  ARRIVED: 'បានមកដល់',
  END: 'ម៉ោងមកដល់',
  DATE: 'កាលបរិច្ឆេទ',
  DATE_FROM: 'កាលបរិច្ឆេទចាប់ផ្ដើម',
  DATE_TO: 'កាលបរិច្ឆេទបញ្ចប់',
  DURATION: 'រយៈពេល',
  'SYSTEM ROLE': 'សិទ្ធិប្រើប្រាស់',
  'CREATE DATE': 'ថ្ងៃបង្កើត',
  REMARK: 'សំគាល់',
  DESCRIPTION: 'បរិយាយអំពីឡានខូច',
  'CHANGE SPAREPARTS': 'គ្រឿងបន្លាស់សំខាន់ដែលបានប្ដូរ',
  'CHANGE OIL DURATION': 'រយៈពេលបានប្តូរប្រេងម៉ាស៊ីន',
  'CHANGE OIL DATE': 'ថ្ងៃបានប្តូរប្រេងម៉ាស៊ីន',
  'CHANGE OIL CYCLE': 'ចំនួនដងបានប្ដូរ',
  OIL_AMOUNT: 'ចំនួនប្រេងម៉ាស៊ីន (L)',
  DIRECTION: 'ទិសដៅ',
  SITUATION: 'ស្ថានភាព',
  'TOTAL AMOUNT': 'សរុបទឹកប្រាក់',
  ACTIONS: 'សកម្មភាព',
  DETAIL: 'លំអិត',
  TRIPCOUNT: 'ចំនួនធ្វើដំណើរ',
  CODE_OLD_TIRE: 'លេខកូដកង់ចាស់ទី ',
  CODE_NEW_TIRE: 'លេខកូដកង់ថ្មីទី ',
  CODE_OLD_TIRE_REPLACE: 'លេខកូដកង់សាគួរចាស់ទី​​​ ',
  CODE_NEW_TIRE_REPLACE: 'លេខកូដកង់សាគួរថ្មីទី​​​ ',
};
