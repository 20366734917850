import { GET_MODULETYPE } from "./types";
import { baseUrl, headersJsonToken } from "../../auth/secret";
import { authfetch } from "../../auth";

export const GetModuleType = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/module-type/list`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			dispatch({
				type: GET_MODULETYPE,
				payload: {
					moduleTypes: data.body.data,
				},
			});
		})
		.catch((error) => {
			console.error(error);
			dispatch({
				type: GET_MODULETYPE,
				payload: {
					moduleTypes: [],
				},
			});
		});
};
