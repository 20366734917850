import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { GetPath, handleData } from "../../../constants/handleData";
import { ChangeSidebar } from "../../../redux/Navs/actions";
import AddEdit from "./addEdit";
import List from "./list";

function VehicleType({ match, ChangeSidebar }) {
   GetPath("VehicleType", "vehicle-type/")
   ChangeSidebar({ currentPage: match.url, actionName: "Vehicle Type" });
   return (
      <div className="ut-container">
         <Switch>
            <Redirect exact from={`${match.url}`} to={`${match.url}/${handleData.VehicleType.path}`} />
            <Route path={`${match.url}/list`} component={List} />
            <Route path={`${match.url}/add`} component={AddEdit} />
            <Route path={`${match.url}/view/:id`} component={AddEdit} />
            <Route path={`${match.url}/edit/:id`} component={AddEdit} />

            <Redirect to={`${match.url}/list`} />
         </Switch>
      </div>
   );
}

const mapStateToProps = (state) => ({
   //
});

export default connect(mapStateToProps, { ChangeSidebar })(VehicleType);
