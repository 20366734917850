import React from "react";
import Select from "react-select";

import { NormalSelectStyle } from "../../assets/styles/constant";

export default function LabelSelect(props) {
	return (
		<div style={{ minWidth: 225 }}>
			<span className="ut-float-label">
				{props.floater}
				{props.required ? <code> *</code> : ""}
			</span>
			<Select
				isMulti={props.isMulti}
				styles={NormalSelectStyle}
				placeholder={props.placeholder}
				onChange={props.onChange}
				options={props.options}
				defaultValue={props.defaultValue}
				value={props.value}
				isClearable={props.isClearable}
				isDisabled={props.isDisabled}
			/>
		</div>
	);
}
