import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { translation } from "../../constants/translate";

class index extends React.Component {
	render() {
		return (
			<div className="ut-sidebar ut-h-100">
				<div className="ut-sidebar-body">
					{this.props.Menus.map((menu, i) =>
						menu.status ? (
							<Menu
								key={menu.id}
								isActive={this.props.currentPage.toLowerCase() === menu.to.toLowerCase()}
								icon={menu.icon}
								name={menu.id}
								path={menu.to}
								subs={menu.subs}
							/>
						) : undefined
					)}
				</div>
			</div>
		);
	}
}

class Menu extends React.Component {
	render() {
		return (
			<React.Fragment>
				<Link to={this.props.path} style={{ textDecoration: "none" }}>
					<div className="ut-sidebar-menu ut-all-center ut-flex-column">
						<img src={this.props.icon} style={this.props.isActive ? {} : { filter: "grayscale(95%)", opacity: "0.85" }} alt="" />
						<span
							className={`ut-sidebar-menu-name text-center
						${this.props.isActive ? "ut-color-primary" : "ut-color-dark"}`}
						>
							<span style={{ fontSize: 12, fontFamily: "Koh_Santepheap Bold" }}>{translation[this.props.name]}</span>
						</span>
					</div>
				</Link>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	Menus: state.Layout.Menus,
	currentPage: state.Navs.currentPage,
});

export default connect(mapStateToProps, {})(index);
