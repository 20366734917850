import { APP_NAME } from "../constants/app";
import { detect } from "detect-browser";

const characterList = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
const CookieList = ["accessToken", "refreshToken", "deviceId", "deviceName"];

export function getDeviceInfo() {
	let browser = detect(),
		deviceInfo = { deviceName: "", deviceId: "" };

	deviceInfo.deviceName = `${browser!.os},${browser!.name},v${browser!.version}`;
	deviceInfo.deviceId = makeid(36);

	return deviceInfo;
}

function makeid(length: number) {
	var result = "";
	var charactersLength = characterList.length;
	for (var i = 0; i < length; i++) {
		result += characterList.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export function generateCookiesName() {
	let secretCode = 0;
	for (let i = 0; i < APP_NAME.length; i++) {
		secretCode += APP_NAME.charCodeAt(i) * APP_NAME.length;
	}

	secretCode = Math.round(secretCode / APP_NAME.length);
	let GeneratedCookiesName: any = {};
	CookieList.forEach((cookie) => {
		let tempCookieString = "";
		for (let j = 0; j < cookie.length; j++) {
			let charCode = Math.floor((cookie.charCodeAt(j) * characterList.length * cookie.length) / secretCode);
			charCode = charCode > characterList.length ? charCode - characterList.length : charCode;
			tempCookieString += characterList.charAt(Math.floor(charCode));
		}

		GeneratedCookiesName[cookie] = tempCookieString;
	});

	return GeneratedCookiesName;
}