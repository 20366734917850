import React, { Component } from "react";
import { connect } from "react-redux";
import Profile from "../../../assets/icons/userProfile.svg";
import { ReactComponent as Cross } from "../../../assets/icons/x.svg";
import { baseUrl } from "../../../auth/secret";
import { Button, Input, Label, LabelSelect } from "../../../components/UTControls";
import { successDesc } from "../../../constants/dictionary";
import { GetPath, ResetRecords, handleData } from "../../../constants/handleData";
import { translation } from "../../../constants/translate";
import ActionBar from "../../../containers/ActionBar";
import Confirmation from "../../../containers/modals/Confirmation";
import { isEmptyOrWhiteSpace, titlePrefix } from "../../../helpers";
import { add_branch, find_branch, update_branch } from "../../../redux/Branch/actions";

class index extends Component {
	state = {
		id: this.props.match.params?.id,
		branchName: "",
		userList: [],
		vehicleTypeList: [],
		originUserList: [],
		originVehicleTypeList: [],
		isSummited: false,
		isRequesting: false,
		isShowConfirmation: false,
		titlePrefix: titlePrefix(this.props.match.url),
		currentUrl: "/app/branches/list",
	};
	componentDidMount() {
		GetPath("Branch", "branches/")
		if (this.state.id) {
			if (this.props.branch.label) {
				this.setState({
					branchName: this.props.branch.label,
					userList: this.props.branch.userList,
					vehicleTypeList: this.props.branch.vehicleTypeList,
				});
			} else {
				this.props.find_branch({ id: this.state.id });
			}
		}
		// is data is already input
		if (Object.keys(handleData.Branch.data).length !== 0) {
			const data = handleData.Branch.data;
			this.setState({
				branchName: data.branchName,
				userList: data.userList,
				vehicleTypeList: data.vehicleTypeList,
			});
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.branch !== this.props.branch) {
			this.setState(
				{ branchName: this.props.branch.label, userList: this.props.branch.userList, vehicleTypeList: this.props.branch.vehicleTypeList },
				() => {
					let tempVehicleTypes = [],
						tempUsers = [];
					this.props.vehicleTypes.forEach((vehicleType) => {
						if (!this.state.vehicleTypeList.find((ele) => ele.value === vehicleType.value)) {
							tempVehicleTypes.push(vehicleType);
						}
					});
					this.props.users.forEach((user) => {
						if (!this.state.userList.find((ele) => ele.value === user.value)) {
							tempUsers.push(user);
						}
					});
					this.setState({ originVehicleTypeList: tempVehicleTypes, originUserList: tempUsers });
				}
			);
		}

		if (prevProps.vehicleTypes !== this.props.vehicleTypes) {
			let tempVehicleTypes = [];
			this.props.vehicleTypes.forEach((vehicleType) => {
				if (!this.state.vehicleTypeList.find((ele) => ele.value === vehicleType.value)) {
					tempVehicleTypes.push(vehicleType);
				}
			});
			this.setState({ originVehicleTypeList: tempVehicleTypes });
		}

		if (prevProps.users !== this.props.users) {
			let tempUsers = [];
			this.props.users.forEach((user) => {
				if (!this.state.userList.find((ele) => ele.value === user.value)) {
					tempUsers.push(user);
				}
			});
			this.setState({ originUserList: tempUsers });
		}
	}
	componentWillUnmount() {
		handleData.Branch = {
			...handleData.Branch,
			data: {
				branchName: this.state.branchName,
				userList: this.state.userList,
				vehicleTypeList: this.state.vehicleTypeList,
			}
		};
	}
	resetForm() {
		this.setState({
			id: null,
			branchName: "",
			userList: [],
			vehicleTypeList: [],

			originUserList: [],
			originVehicleTypeList: [],

			isSummited: false,
			isRequesting: false,
			isShowConfirmation: false,
		});
	}

	gatherData() {
		if (isEmptyOrWhiteSpace(this.state.branchName)) {
			let brancheUserList = this.state.userList.map((user) => {
				return { id: user.value };
			});
			let branchVehicleTypeList = this.state.vehicleTypeList.map((vehicleType) => {
				return { vehicleTypeId: vehicleType.value };
			});

			let dataCollection = {
				name: this.state.branchName,
				brancheUserList,
				branchVehicleTypeList,
			};

			return dataCollection;
		} else return null;
	}

	handleBack = () => {
		handleData.Branch = {
			...handleData.Branch, path: "list"
		}
		this.setState({ isShowConfirmation: false });
		setTimeout(() => { this.props.history.push(`${this.state.currentUrl}`) }, 100);
	};

	handleSave = () => {
		let branch = this.gatherData();
		this.setState({ isSummited: true });
		if (branch) {
			this.setState({ isRequesting: true });
			if (this.state.id) {
				branch.id = this.state.id;
				this.props.update_branch(branch, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("Branch")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			} else {
				this.setState({ isRequesting: true });
				this.props.add_branch(branch, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("Branch")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			}
		}
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleRemoveUser = (user, index) => {
		let tempUser = this.state.userList;
		tempUser.splice(index, 1);

		this.setState({ userList: tempUser, originUserList: [...this.state.originUserList, user] });
	};

	handleRemoveVehicleType = (vehicleType, index) => {
		let tempVehicleType = this.state.vehicleTypeList;
		tempVehicleType.splice(index, 1);

		this.setState({ vehicleTypeList: tempVehicleType, originVehicleTypeList: [...this.state.originVehicleTypeList, vehicleType] });
	};

	render() {
		return (
			<div>
				<Confirmation handleClose={this.handleBack} isShow={this.state.isShowConfirmation} confirmType="SUCCESS" desc={successDesc} />
				<ActionBar title={`${this.state.titlePrefix} Branch`}>
					<Button name={translation["BACK"]} color="secondary" size="md" buttonStyle="filled" onClick={this.handleBack} />
				</ActionBar>
				<div style={{ width: 400 }}>
					<div>
						<Label title={translation["Branches"]} required />
						<Input
							autoFocus
							disabled={this.state.titlePrefix === "View"}
							name="branchName"
							placeholder={translation["Branches"]}
							onChange={this.onChange}
							value={this.state.branchName}
						/>
						<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.branchName))}>Branch name is required</small>
					</div>
				</div>
				<div className="ut-d-flex">
					<div style={{ width: 400 }}>
						<LabelSelect
							placeholder="select user"
							options={this.state.originUserList}
							isDisabled={this.state.titlePrefix === "View"}
							onChange={(selected) => {
								if (!this.state.vehicleTypeList.includes(selected)) {
									let tempUser = this.state.originUserList;
									let index = tempUser.indexOf(selected);
									tempUser.splice(index, 1);
									this.setState({ userList: [...this.state.userList, selected], originUserList: [...tempUser] });
								} else {
									//
								}
							}}
							value={""}
						/>
						<div className="ut-select-list">
							{this.state.userList.map((user, i) => (
								<div>
									<div>
										<img
											src={isEmptyOrWhiteSpace(user.avatar) ? `${baseUrl}${user.avatar}` : Profile}
											alt="profile"
											style={{ width: 25, height: 25, objectFit: "cover", borderRadius: 20 }}
										/>
										<span>{user.label}</span>
									</div>
									{this.state.titlePrefix !== "View" && <Cross onClick={() => this.handleRemoveUser(user, i)} />}
								</div>
							))}
						</div>
					</div>
					<div style={{ width: 400 }}>
						<LabelSelect
							placeholder="vehicle type"
							options={this.state.originVehicleTypeList}
							isDisabled={this.state.titlePrefix === "View"}
							onChange={(selected) => {
								if (!this.state.vehicleTypeList.includes(selected)) {
									let tempVehicleType = this.state.originVehicleTypeList;
									let index = tempVehicleType.indexOf(selected);
									tempVehicleType.splice(index, 1);
									this.setState({
										vehicleTypeList: [...this.state.vehicleTypeList, selected],
										originVehicleTypeList: [...tempVehicleType],
									});
								} else {
									//
								}
							}}
							value={""}
						/>
						<div className="ut-select-list">
							{this.state.vehicleTypeList.map((vehicleType, i) => (
								<div>
									<span>{vehicleType.label}</span>
									{this.state.titlePrefix !== "View" && <Cross onClick={() => this.handleRemoveVehicleType(vehicleType, i)} />}
								</div>
							))}
						</div>
					</div>
				</div>
				{this.state.titlePrefix !== "View" && (
					<Button
						name={
							this.state.id
								? this.state.isRequesting
									? translation["UPDATING"]
									: translation["UPDATE"]
								: this.state.isRequesting
									? translation["SAVING"]
									: translation["SAVE"]
						}
						color={this.state.id ? "warning" : "success"}
						size="md"
						buttonStyle="filled"
						onClick={this.handleSave}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	branch: state.Branch.branch,
	users: state.User.users,
	vehicleTypes: state.VehicleType.vehicleTypes,
});

export default connect(mapStateToProps, { add_branch, update_branch, find_branch })(index);
