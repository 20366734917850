import React from "react";

export default function Loading() {
	return (
		<div className="loadingio-spinner-magnify-ku1bo5jcz8l">
			<div className="ldio-vox51pk72ko">
				<div>
					<div>
						<div></div>
						<div></div>
					</div>
				</div>
			</div>
		</div>
	);
}
