import { GET_VEHICLE_TYPE, FIND_VEHICLE_TYPE } from "./types";

const initialState = {
	vehicleTypes: [],
	vehicleType: {},
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case GET_VEHICLE_TYPE:
			state.vehicleTypes = actions.payload.vehicleTypes;
			return { ...state };
		case FIND_VEHICLE_TYPE:
			state.vehicleType = actions.payload.vehicleType;
			return { ...state };
		default:
			return { ...state };
	}
};
