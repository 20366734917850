import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import UserLayout from "../../layouts/UserLayout";

import Login from "./login";

export default function index({ match }) {
	return (
		<UserLayout>
			<Switch>
				<Redirect exact from={`${match.url}`} to={`${match.url}/login`} />
				<Route exact path={`${match.url}/login`} component={Login} />
			</Switch>
		</UserLayout>
	);
}
