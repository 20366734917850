import React from "react";
import { Modal } from "react-bootstrap";

import { Button } from "../../components/UTControls";
import { ReactComponent as Check } from "../../assets/icons/Success.svg";
import { ReactComponent as Cross } from "../../assets/icons/Delete.svg";

export default function index(props) {
	return (
		<Modal centered show={props.isShow} size="sm">
			<Modal.Body className="ut-all-center ut-flex-column" style={{ padding: 25 }}>
				{props.confirmType === "DELETE" ? <Cross className="ut-popup-animate" /> : <Check className="ut-popup-animate" />}
				<span style={{ fontSize: 12, padding: "10px 0" }}>{props.desc}</span>
				<div className="ut-d-flex">
					{props.confirmType === "DELETE" ? (
						<>
							<Button onClick={props.handleConfirm} color="secondary" name="មែន" size="sm" buttonStyle="filled" />
							<Button onClick={props.handleReject} color="light" name="ទេ" size="sm" buttonStyle="filled" />
						</>
					) : (
						<Button onClick={props.handleClose} name="ចាកចេញ" size="sm" buttonStyle="filled" />
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
}
