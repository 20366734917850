import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { GetPath, handleData } from "../../../../constants/handleData";
import { ChangeSettingSidebar } from "../../../../redux/Navs/actions";
import { fetch_user } from "../../../../redux/User/actions";
import AddEdit from "./addEdit";
import List from "./list";

class index extends React.Component {
	state = {};
	componentDidMount = () => {
		GetPath("SystemRole", "system-roles/")
		handleData.Setting = { path: "system-roles" };
		this.props.fetch_user({ page: 1, rowsPerPage: 1000 });
		this.props.ChangeSettingSidebar({ currentSetting: this.props.match.url, actionName: "System Roles" });
	};

	render() {
		const { match } = this.props;
		return (
			<Switch>
				<Redirect exact from={`${match.url}`} to={`${match.url}/${handleData.SystemRole.path}`} />
				<Route exact path={`${match.url}/list`} component={List} />
				<Route exact path={`${match.url}/add`} component={AddEdit} />
				<Route exact path={`${match.url}/edit/:id`} component={AddEdit} />
			</Switch>
		);
	}
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { ChangeSettingSidebar, fetch_user })(index);
