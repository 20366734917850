import React from "react";
import { toKhmerNum } from "../../helpers";
import { Accident, ChangeOil, ChangeTire, VehicleRepair } from "./reportContent";
import "./styles.scss";

const months = ["", "មករា", "កុម្ភៈ", "មីនា", "មេសា", "ឧសភា", "មិថុនា", "កក្កដា", "សីហា", "កញ្ញា", "តុលា", "វិច្ឆិកា", "ធ្នូ"];
export default class ReportLayout extends React.Component {
	render() {
		const isVehicleRepaire = (this.props.reportType.type === 'រថយន្ដខូច');
		return (
			<div className="ut-report">
				<div className="ut-report-title mt-3">
					<span>របាយការណ៍{isVehicleRepaire && this.props.reportType.type}</span>
					<span hidden={isVehicleRepaire}>{this.props.reportType.type}</span>
					<span>
						កាលបរិច្ឆេទ៖{" "}
						{this.props.dateFrom.length ? (
							`ថ្ងៃទី ${toKhmerNum(parseInt(this.props.dateFrom[0]))} ខែ ${months[parseInt(this.props.dateFrom[1])]} ឆ្នាំ ${toKhmerNum(
								parseInt(this.props.dateFrom[2])
							)}
									ដល់ ថ្ងៃទី ${toKhmerNum(parseInt(this.props.dateTo[0]))} ខែ ${months[parseInt(this.props.dateTo[1])]} ឆ្នាំ ${toKhmerNum(
								parseInt(this.props.dateTo[2])
							)}`
						) : (
							<span style={{ fontFamily: "Koh Santepheap" }}>.................................</span>
						)}
					</span>
				</div>
				<div className="ut-report-table">
					{this.props.reportType.value === 2 ? (
						<VehicleRepair reports={this.props.reports} />
					) : this.props.reportType.value === 3 ? (
						<ChangeTire reports={this.props.reports} />
					) : this.props.reportType.value === 4 ? (
						<ChangeOil reports={this.props.reports} />
					) : (
						<Accident reports={this.props.reports} />
					)}
				</div>
			</div>
		);
	}
}
