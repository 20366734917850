import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input, Label, LabelSelect } from "../../../../components/UTControls";
import { successDesc } from "../../../../constants/dictionary";
import { GetPath, ResetRecords, handleData } from "../../../../constants/handleData";
import { translation } from "../../../../constants/translate";
import ActionBar from "../../../../containers/ActionBar";
import Confirmation from "../../../../containers/modals/Confirmation";
import { isEmptyOrWhiteSpace, titlePrefix } from "../../../../helpers";
import { find_user, update_user } from "../../../../redux/User/actions";

class index extends Component {
	state = {
		id: this.props.match.params?.id,
		name: "",
		username: "",
		gender: "",
		telephone: "",
		systemRole: "",
		password: "",
		reTypePassword: "",
		branchList: [],

		isSummited: false,
		isRequesting: false,
		isShowConfirmation: false,

		titlePrefix: titlePrefix(this.props.match.url),
		currentUrl: "/app/setting/users/list",
	};

	componentDidMount() {
		GetPath("User", "users/")
		if (this.state.id) {
			if (this.props.user.label) {
				this.setState({
					name: this.props.user.label,
					username: this.props.user.username,
					gender: this.props.user.gender,
					telephone: this.props.user.telephone,
					systemRole: this.props.user.systemRole,
					branchList: this.props.user.branchList,
				});
			} else {
				this.props.find_user({ id: this.state.id });
			}
		}
		// is data is already input
		if (Object.keys(handleData.User.data.reset).length !== 0) {
			const data = handleData.User.data.reset;
			this.setState({
				username: data.username,
				systemRole: data.systemRole,
				password: data.password,
				reTypePassword: data.reTypePassword,
			});
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.user !== this.props.user) {
			this.setState({
				name: this.props.user.label,
				username: this.props.user.username,
				gender: this.props.user.gender,
				telephone: this.props.user.telephone,
				systemRole: this.props.user.systemRole,
				branchList: this.props.user.branchList,
			});
		}
	}
	componentWillUnmount() {
		handleData.User = {
			...handleData.User,
			data: {
				...handleData.User.data,
				reset: {
					username: this.state.username,
					systemRole: this.state.systemRole,
					password: this.state.password,
					reTypePassword: this.state.reTypePassword,
				}
			}
		};
	}
	resetForm() {
		this.setState({
			id: null,
			name: "",
			username: "",
			gender: "",
			telephone: "",
			systemRole: "",
			password: "",
			reTypePassword: "",
			branchList: [],

			isSummited: false,
			isRequesting: false,
			isShowConfirmation: false,
		});
	}

	gatherData() {
		if (isEmptyOrWhiteSpace(this.state.username, this.state.systemRole, this.state.password, this.state.reTypePassword)) {
			if (this.state.password === this.state.reTypePassword) {
				let dataCollection = new FormData();
				dataCollection.append("fullName", this.state.name);
				dataCollection.append("username", this.state.username);
				dataCollection.append("sex", this.state.gender.value);
				dataCollection.append("telephone", this.state.telephone);
				dataCollection.append("roleId", this.state.systemRole?.value);
				dataCollection.append("password", this.state.password);
				this.state.branchList.forEach((branch) => {
					dataCollection.append("userBranch", branch.value);
				});

				return dataCollection;
			} else alert("Password does not match!");
		}
		return null;
	}

	handleBack = () => {
		this.props.history.push(`${this.state.currentUrl}`);
	};

	handleSave = () => {
		let user = this.gatherData();
		this.setState({ isSummited: true });
		if (user) {
			this.setState({ isRequesting: true });
			if (this.state.id) {
				user.append("id", this.state.id);
				this.props.update_user(user, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("User")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			}
		}
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		return (
			<div>
				<Confirmation handleClose={this.handleBack} isShow={this.state.isShowConfirmation} confirmType="SUCCESS" desc={successDesc} />
				<ActionBar title={`${this.state.titlePrefix} User`}>
					<Button name={translation["BACK"]} color="secondary" size="md" buttonStyle="filled" onClick={this.handleBack} />
				</ActionBar>
				<div className="ut-setting-scrollable">
					<div className="ut-d-flex">
						<div style={{ width: 400 }}>
							<div>
								<Label title={translation["Username"]} required />
								<Input
									disabled={this.state.titlePrefix === "View"}
									name="username"
									placeholder={translation["Username"]}
									onChange={this.onChange}
									value={this.state.username}
								/>
								<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.username))}>Username is required</small>
							</div>
							<div>
								<Label title={translation["Password"]} required />
								<Input
									disabled={this.state.titlePrefix === "View"}
									name="password"
									placeholder={translation["Password"]}
									onChange={this.onChange}
									type="password"
									value={this.state.password}
								/>
								<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.password))}>Password is required</small>
							</div>
							<br />
							{this.state.titlePrefix !== "View" && (
								<Button
									name={this.state.isRequesting ? translation["UPDATING"] : translation["UPDATE"]}
									color="warning"
									size="md"
									buttonStyle="filled"
									onClick={this.handleSave}
								/>
							)}
						</div>
						<div style={{ width: 400 }}>
							<div>
								<Label title={translation["System Role"]} required />
								<LabelSelect
									placeholder={translation["System Role"]}
									options={this.props.systemRoles}
									isDisabled={this.state.titlePrefix === "View"}
									onChange={(selected) => this.setState({ systemRole: selected })}
									value={this.state.systemRole}
								/>
								<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.systemRole))}>
									System Role is required
								</small>
							</div>
							<div>
								<Label title={translation["Re-Type Password"]} required />
								<Input
									disabled={this.state.titlePrefix === "View"}
									name="reTypePassword"
									placeholder={translation["Re-Type Password"]}
									onChange={this.onChange}
									type="password"
									value={this.state.reTypePassword}
								/>
								<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.reTypePassword))}>
									Re-Type Password is required
								</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	systemRoles: state.SystemRole.systemRoles,
	user: state.User.user,
});

export default connect(mapStateToProps, { update_user, find_user })(index);
