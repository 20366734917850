import { GET_VEHICLE, FIND_VEHICLE } from "./types";

const initialState = {
	vehicles: [],
	vehicle: {},
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case GET_VEHICLE:
			state.vehicles = actions.payload.vehicles;
			return { ...state };
		case FIND_VEHICLE:
			state.vehicle = actions.payload.vehicle;
			return { ...state };
		default:
			return { ...state };
	}
};
