export const deleteDesc = "តើអ្នកចង់លុបចោលមែនទេ?";
export const successDesc = "បានរក្សាទុក";

export const dashboardHeaders = [
	{ label: "N°", value: "no", width: "60px" },
	{ label: "VEHICLE", value: "vehicle.label", width: "100px" },
	{ label: "ORIGIN", value: "origin.label" },
	{ label: "PASSING", value: "passingText" },
	{ label: "DESTINATION", value: "destination.label" },
	{ label: "START", value: "start", note: "remarkStart", width: "170px" },
	{ label: "END", value: "end", note: "remarkStop" },
	{ label: "DURATION", value: "duration" },
	{ label: "ARRIVED", value: "arrived[0].label" },
	{ label: "DETAIL", value: "detail", width: "70px" },
];

export const tvHeaders = [
	{ label: "N°", value: "no", width: "75px" },
	{ label: "VEHICLE", value: "vehicle.label", width: "100px" },
	{ label: "TYPE", value: "vehicleType.label" },
	{ label: "ORIGIN", value: "origin.label" },
	{ label: "DESTINATION", value: "destination.label" },
	{ label: "START", value: "start", width: "145px", time: true },
	{ label: "END", value: "end", width: "145px", time: true },
	{ label: "ARRIVED", value: "arrived[0].label" },
	{ label: "DURATION", value: "duration", width: "100px" },
];

export const trackingHeaders = [
	{ label: "N°", value: "no", width: "100px" },
	{ label: "VEHICLE", value: "vehicle.label" },
	{ label: "ORIGIN", value: "origin.label" },
	{ label: "PASSING", value: "passingText" },
	{ label: "DESTINATION", value: "destination.label" },
	{ label: "START", value: "start" },
	{ label: "ACTIONS", value: "action", width: "300px" },
];

export const vehicleRepairHeaders = [
	{ label: "N°", value: "no", width: "150px" },
	{ label: "DATE", value: "date" },
	{ label: "VEHICLE", value: "vehicle.label" },
	{ label: "DESCRIPTION", value: "descriptionNote" },
	{ label: "TOTAL AMOUNT", value: "totalAmountText" },
	{ label: "ACTIONS", value: "action", width: "150px" },
];

export const changeTireHeaders = [
	{ label: "N°", value: "no", width: "80px" },
	{ label: "DATE", value: "date", width: "120px" },
	{ label: "VEHICLE", value: "vehicle.label", width: "120px" },
	{ label: "OLD_TIRE_SERIAL", value: "oldTiresSerialNote" },
	{ label: "NEW_TIRE_SERIAL", value: "newTiresSerialNote" },
	{ label: "OLD_WHEEL", value: "oldTireReplaceNote" },
	{ label: "NEW_WHEEL", value: "newTireReplaceNote" },
	{ label: "REMARK", value: "remark" },
	{ label: "ACTIONS", value: "action", width: "150px" },
];

export const changeOilHeaders = [
	{ label: "N°", value: "no", width: "150px" },
	{ label: "VEHICLE", value: "vehicle.label" },
	{ label: "OIL_AMOUNT", value: "oilAmount" },
	{ label: "SITUATION", value: "situation.label" },
	{ label: "DATE", value: "date" },
	{ label: "ACTIONS", value: "action" },
];

export const accidentHeaders = [
	{ label: "N°", value: "no", width: "150px" },
	{ label: "VEHICLE", value: "vehicle.label" },
	{ label: "DRIVER", value: "driver" },
	{ label: "DIRECTION", value: "directionNote" },
	{ label: "DATE", value: "date" },
	{ label: "ACTIONS", value: "action", width: "180px" },
];

export const reportHeaders = [
	{ label: "N°", value: "no", width: "75px" },
	{ label: "VEHICLE", value: "vehicle.label", width: "100px" },
	{ label: "TYPE", value: "vehicleType.label", width: "100px" },
	{ label: "ORIGIN", value: "origin.label", width: "120px" },
	{ label: "PASSING", value: "passing" },
	{ label: "DESTINATION", value: "destination.label", width: "120px" },
	{ label: "START", value: "start", width: "145px" },
	{ label: "END", value: "end", width: "145px" },
	{ label: "DURATION", value: "duration", width: "100px" },
	{ label: "REMARK", value: "remark" },
	{ label: "ACTIONS", value: "action" },
];

export const summaryReportHeaders = [
	{ label: "N°", value: "no", width: "150px" },
	{ label: "TYPE", value: "vehicleType.label" },
	{ label: "VEHICLE", value: "vehicle.label" },
	{ label: "TRIPCOUNT", value: "numberOfTrip" },
];

export const reportVehicleRepair = [
	{ label: "N°", value: "no", width: "75px" },
	{ label: "DATE", value: "date", width: "120px" },
	{ label: "TYPE", value: "vehicleType.label" },
	{ label: "VEHICLE", value: "vehicle.label" },
	{ label: "YEAR", value: "yearMade" },
	{ label: "STATUS", value: "type" },
	{ label: "CHANGE SPAREPARTS", value: "sparepartsNote" },
	{ label: "DESCRIPTION", value: "descriptionNote" },
	{ label: "TOTAL AMOUNT", value: "amount" },
];

export const reportChangeTire = [
	{ label: "N°", value: "no", width: "75px" },
	{ label: "DATE", value: "date", width: "120px" },
	{ label: "TYPE", value: "vehicleType.label", width: "100px" },
	{ label: "VEHICLE", value: "vehicle.label", width: "100px" },
	{ label: "OLD TIRE SERIAL", value: "oldTiresSerialNote" },
	{ label: "NEW TIRE SERIAL", value: "newTiresSerialNote" },
	{ label: "OLD TIRE REPLACE", value: "oldTireReplaceNote" },
	{ label: "NEW TIRE REPLACE", value: "newTireReplaceNote" },
	{ label: "TOTAL TIRE CHANGED", value: "totalTireChanged" },
	{ label: "REMARK", value: "remark", width: "300px" },
];

export const reportChangeOil = [
	{ label: "N°", value: "no", width: "75px" },
	{ label: "DATE", value: "changeOilDate", width: "120px" },
	{ label: "TYPE", value: "vehicleType.label", width: "100px" },
	{ label: "VEHICLE", value: "vehicle.label", width: "100px" },
	{ label: "OIL_AMOUNT", value: "oilAmount" },
	{ label: "SITUATION", value: "situation.label" },
	{ label: "CHANGE OIL CYCLE", value: "changeOilCycle" },
	{ label: "CHANGE OIL DURATION", value: "changeOilDurationKh" },
];

export const reportAccident = [
	{ label: "N°", value: "no", width: "75px" },
	{ label: "DATE", value: "date", width: "100px" },
	{ label: "TYPE", value: "vehicleType.label", width: "90px" },
	{ label: "VEHICLE", value: "vehicle.label", width: "100px" },
	{ label: "DRIVER", value: "driver", width: "100px" },
	{ label: "DIRECTION", value: "directionNote", width: "150px" },
	{ label: "Accident Reason", value: "accidentLocationNote", width: "270px" },
	{ label: "injuryStatus", value: "injuryStatusNote", width: "180px" },
	{ label: "Compensation Date", value: "compensationDateNote", width: "120px" },
];

export const vehicleTypeHeaders = [
	{ label: "N°", value: "no", width: "200px" },
	{ label: "NAME", value: "label" },
	{ label: "CREATE DATE", value: "createdDate" },
	{ label: "ACTIONS", value: "action", width: "200px" },
];

export const vehicleHeaders = [
	{ label: "N°", value: "no", width: "75px" },
	{ label: "NAME", value: "label" },
	{ label: "VEHICLE TYPE", value: "vehicleType.label" },
	{ label: "VEHICLE YEAR", value: "yearMade" },
	{ label: "SITUATION VEHICLE", value: "situationVehicle" },
	{ label: "CHANGE OIL DURATION", value: "changeOilDurationKh" },
	{ label: "CHANGE OIL DATE", value: "changeOilDate" },
	{ label: "CREATE DATE", value: "createdDate" },
	{ label: "ACTIONS", value: "action", width: "200px" },
];

export const branchHeaders = [
	{ label: "N°", value: "no", width: "200px" },
	{ label: "BRANCH", value: "label" },
	{ label: "CREATE DATE", value: "createdDate" },
	{ label: "ACTIONS", value: "action", width: "200px" },
];

export const userHeaders = [
	{ label: "N°", value: "no", width: "75px" },
	{ label: "NAME", value: "label" },
	{ label: "USERNAME", value: "username" },
	{ label: "SYSTEM ROLE", value: "systemRole.label" },
	{ label: "CREATE DATE", value: "createdDate" },
	{ label: "ACTIONS", value: "action", width: "200px" },
];

export const systemRoleHeaders = [
	{ label: "N°", value: "no", width: "100px" },
	{ label: "NAME", value: "label" },
	{ label: "CREATE DATE", value: "createdDate" },
	{ label: "ACTIONS", value: "action", width: "200px" },
];
