import { GET_TRACKING } from "./types";

const initialState = {
	trackings: [],
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case GET_TRACKING:
			state.trackings = actions.payload.trackings;
			return { ...state };
		default:
			return { ...state };
	}
};
