import React from "react";
import { Modal } from "react-bootstrap";

import { Button, TextArea } from "../../components/UTControls";
import { ReactComponent as Exclamation } from "../../assets/icons/Exclamation.svg";

export default function index(props) {
	return (
		<Modal centered show={props.isShow} size={props.passingState === 0 ? "md" : "sm"}>
			<Modal.Body className="ut-all-center ut-flex-column" style={{ padding: 25 }}>
				{props.passingState === 0 ? (
					<>
						<span style={{ padding: "10px 0", textAlign: "center" }}>សម្គាល់</span>
						<div className="ut-w-100">
							<TextArea name="remark" onChange={props.onChange} />
						</div>
						<div className="ut-d-flex ut-w-100">
							<div style={{ width: "50%" }}>
								<Button block onClick={props.handleReject} color="light" name="ចាកចេញ" size="sm" buttonStyle="filled" />
							</div>
							<div style={{ width: "50%" }}>
								<Button block onClick={props.handleConfirm} color="secondary" name="រក្សាទុក" size="sm" buttonStyle="filled" />
							</div>
						</div>
					</>
				) : (
					<>
						<Exclamation className="ut-popup-animate" />
						<span style={{ fontSize: 12, padding: "10px 0", textAlign: "center" }}>{props.desc}</span>
						<div className="ut-d-flex">
							<Button onClick={props.handleConfirm} color="secondary" name="មែន" size="sm" buttonStyle="filled" />
							<Button onClick={props.handleReject} color="light" name="ទេ" size="sm" buttonStyle="filled" />
						</div>
					</>
				)}
			</Modal.Body>
		</Modal>
	);
}
