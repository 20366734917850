import Moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input, Label, LabelDatePicker, LabelSelect, TextArea } from "../../../components/UTControls";
import { successDesc } from "../../../constants/dictionary";
import { GetPath, ResetRecords, handleData } from "../../../constants/handleData";
import { translation } from "../../../constants/translate";
import ActionBar from "../../../containers/ActionBar";
import Confirmation from "../../../containers/modals/Confirmation";
import { isEmptyOrWhiteSpace, titlePrefix, toKhmerNum } from "../../../helpers";
import { add_change_tire, find_change_tire, update_change_tire } from "../../../redux/ChangeTire/actions";

class index extends Component {
	state = {
		id: this.props.match.params?.id,
		date: new Date(),
		vehicle: "",
		remark: "",
		vehicleTires: [],
		vehicleTireReplaces: [],

		isSummited: false,
		isRequesting: false,
		isShowConfirmation: false,
		titlePrefix: titlePrefix(this.props.match.url),
		currentUrl: "/app/change-tire/list",
	};

	componentDidMount() {
		GetPath("ChangeTire", "change-tire/")
		if (this.state.id) {
			this.props.find_change_tire({ id: this.state.id });
		}
		// is data is already input
		if (Object.keys(handleData.ChangeTire.data).length !== 0) {
			const data = handleData.ChangeTire.data;
			this.setState({
				date: new Date(data.date),
				vehicle: data.vehicle,
				vehicleTires: data.vehicleTires,
				vehicleTireReplaces: data.vehicleTireReplaces,
				remark: data.remark,
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.changeTire !== this.props.changeTire) {
			const data = this.props.changeTire
			this.setState({
				date: data.date ? new Date(data.date.split("-").reverse().join("-")) : "",
				vehicle: data.vehicle,
				vehicleTires: data.vehicleTires,
				vehicleTireReplaces: data.vehicleTireReplaces,
				remark: data.remark,
			});
		}
	}
	// use for handle data while leave page
	componentWillUnmount() {
		handleData.ChangeTire = {
			...handleData.ChangeTire,
			data: {
				date: Moment(this.state.date).format("YYYY-MM-DD"),
				vehicle: this.state.vehicle,
				vehicleTires: this.state.vehicleTires,
				vehicleTireReplaces: this.state.vehicleTireReplaces,
				remark: this.state.remark,
			}
		};
	}
	resetForm() {
		this.setState({
			id: null,
			date: new Date(),
			vehicle: "",
			remark: "",
			vehicleTires: [],
			vehicleTireReplaces: [],

			isSummited: false,
			isRequesting: false,
			isShowConfirmation: false,
		});
	}
	gatherData() {
		if (isEmptyOrWhiteSpace(this.state.date, this.state.vehicle)) {
			 let vehicleChangeTireDetails = [], totalChanged = 0;
			 let alertShown = false; // Track if alert has been shown
			 this.state.vehicleTires.forEach((tire) => {
				  vehicleChangeTireDetails.push({ oldTireNumber: tire.oldSerial, newTireNumber: tire.newSerial });
				  if (tire.oldSerial !== tire.newSerial) {
						totalChanged += 1;
				  }
				  if (tire.newSerial === '') {
						if (!alertShown) { // Check if alert has not been shown yet
							 alertShown = true; // Set alertShown to true to prevent further alerts
							 alert("លេខកូដកង់ថ្មីមិនអាចទទេ!");
						}
						return; // Stop execution of forEach loop
				  }
			 });
			 if (alertShown) return null; // If alert was shown, return null
  
			 if (totalChanged > 0) {
				  let dataCollection = {
						vehicleId: this.state.vehicle.value,
						date: Moment(this.state.date).format("YYYY-MM-DD"),
						note: this.state.remark,
						vehicleChangeTireDetails: this.state.vehicleTires.map((tire, i) => ({
							 oldTireNumber: tire.oldSerial,
							 newTireNumber: tire.newSerial
						})),
						vehicleChangeTireReplaceDetailRequests: this.state.vehicleTireReplaces.map((tire, i) => (
							 {
								  oldTireReplaceNumber: tire.oldSerial,
								  newTireReplaceNumber: tire.newSerial
							 }
						))
				  };
				  return dataCollection;
			 } else {
				  alert("No tired was changed!");
				  return null;
			 }
		} else return null;
  }
  
	handleBack = () => {
		this.setState({ isShowConfirmation: false });
		setTimeout(() => {
			this.props.history.push(`${this.state.currentUrl}`);
		}, 100);
	};
	handleSave = () => {
		let changeTire = this.gatherData();
		this.setState({ isSummited: true });

		if (changeTire) {
			this.setState({ isRequesting: true });
			if (this.state.id) {
				changeTire.id = this.state.id;
				this.props.update_change_tire(changeTire, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("ChangeTire")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			} else {
				this.setState({ isRequesting: true });
				this.props.add_change_tire(changeTire, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("ChangeTire")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			}
		}
	};
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		return (
			<div>
				<Confirmation handleClose={this.handleBack} isShow={this.state.isShowConfirmation} confirmType="SUCCESS" desc={successDesc} />
				<ActionBar title={`${this.state.titlePrefix} Change Tire`}>
					<Button name={translation["BACK"]} color="secondary" size="md" buttonStyle="filled" onClick={this.handleBack} />
				</ActionBar>
				<div className="d-flex flex-column my-3">
					<div className="d-flex">
						<div style={{ width: 400 }}>
							<Label title={translation["Date"]} required />
							<LabelDatePicker
								styles="ut-custom-label-datePicker-blocked"
								disabled={this.state.titlePrefix === "View"}
								placeholder={translation["Date"]}
								onChange={(date) => this.setState({ date })}
								selected={this.state.date}
							/>
							<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.date))}>Date is required</small>
						</div>
						<div style={{ width: 500 }}>
							<Label title={translation["Vehicles"]} required />
							<LabelSelect
								isDisabled={this.state.titlePrefix === "View"}
								options={this.props.vehicles}
								name="vehicle"
								placeholder={translation["Vehicles"]}
								onChange={(selected) =>
									this.setState({
										vehicle: selected,
										vehicleTires: selected.vehicleTires,
										vehicleTireReplaces: selected.vehicleTireReplaces
									})
								}
								value={this.state.vehicle}
							/>
							<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.vehicle))}>Vehicle name is required</small>
						</div>
					</div>
					{(this.state.vehicle !== "") &&
						<div className='d-flex flex-row my-3'>
							<div className="d-flex flex-column">
								{this.state.vehicleTires && this.state.vehicleTires.length > 0 ?
									this.state.vehicleTires.map((tire, idx) => (
										<VehicleTire
											key={idx} idx={idx} tire={tire}
											propState={this.state}
											onChange={(e) => {
												let newTires = [...this.state.vehicleTires];
												newTires[idx].newSerial = e.target.value;
												this.setState({ vehicleTires: newTires });
											}}
										/>
									)) : null}
							</div>
							<div className="d-flex flex-column">
								{this.state.vehicleTireReplaces && this.state.vehicleTireReplaces.length > 0 ?
									this.state.vehicleTireReplaces.map((tire, idx) => (
										<VehicleTireReplace key={idx}
											idx={idx} tire={tire}
											propState={this.state}
											onChange={(e) => {
												let newTire = [...this.state.vehicleTireReplaces];
												newTire[idx].newSerial = e.target.value;
												this.setState({ vehicleTireReplaces: newTire });
											}}
										/>
									)) : null}
							</div>
						</div>}
				</div>
				<div style={{ width: 400 }} className="mb-2">
					<Label title={translation["Remark"]} />
					<TextArea disabled={this.state.titlePrefix === "View"} value={this.state.remark} onChange={this.onChange} name="remark" size="md" blocked />
				</div>
				{this.state.titlePrefix !== "View" ? (
					<Button
						name={
							this.state.id
								? this.state.isRequesting
									? translation["UPDATING"]
									: translation["UPDATE"]
								: this.state.isRequesting
									? translation["SAVING"]
									: translation["SAVE"]
						}
						color={this.state.id ? "warning" : "success"}
						size="md"
						buttonStyle="filled"
						onClick={this.handleSave}
					/>
				) : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	changeTire: state.ChangeTire.changeTire,
	vehicles: state.Vehicle.vehicles,
});

export default connect(mapStateToProps, { add_change_tire, update_change_tire, find_change_tire })(index);

function VehicleTire({ idx, propState, tire, onChange }) {
	const index = toKhmerNum(idx + 1);
	return (<div className="d-flex flex-row" >
		<div style={{ width: 330 }}>
			<Label title={translation.CODE_OLD_TIRE + index} />
			<Input
				type="text" value={tire.oldSerial} name="oldSerial"
				disabled={true}
				placeholder={translation.CODE_OLD_TIRE + index}
			/>
		</div>
		<div style={{ width: 330 }}>
			<Label title={translation.CODE_NEW_TIRE + index} required/>
			<Input
				type="text" value={tire.newSerial} name="newSerial"
				disabled={propState.titlePrefix === "View"}
				placeholder={translation.CODE_NEW_TIRE + index}
				onChange={onChange}
			/>
		</div>
	</div>
	)
}
function VehicleTireReplace({ idx, propState, tire, onChange }) {
	const index = toKhmerNum(idx + 1)
	return (<div className="d-flex flex-row">
		<div style={{ width: 330 }}>
			<Label title={translation.CODE_OLD_TIRE_REPLACE + index} />
			<Input
				disabled placeholder={translation.CODE_OLD_TIRE_REPLACE + index}
				type="text" value={tire.oldSerial} name="oldSerial"
			/>
		</div>
		<div style={{ width: 330 }}>
			<Label title={translation.CODE_NEW_TIRE_REPLACE + index} />
			<Input
				isCopy placeholder={translation.CODE_NEW_TIRE_REPLACE + index}
				type="text" value={tire.newSerial} name="newSerial"
				disabled={propState.titlePrefix === "View"}
				onChange={onChange}
			/>
		</div>
	</div>)
}