import Moment from "moment";
import QRCode from "qrcode-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { ReactComponent as AddMore } from "../../../assets/icons/add-more.svg";
import { ReactComponent as Remove } from "../../../assets/icons/remove.svg";
import { Button, Input, Label, LabelDatePicker, LabelSelect } from "../../../components/UTControls";
import { successDesc } from "../../../constants/dictionary";
import { GetPath, ResetRecords, handleData } from "../../../constants/handleData";
import { translation } from "../../../constants/translate";
import ActionBar from "../../../containers/ActionBar";
import Confirmation from "../../../containers/modals/Confirmation";
import { isEmptyOrWhiteSpace, titlePrefix, toKhmerNum } from "../../../helpers";
import { add_vehicle, find_vehicle, update_vehicle } from "../../../redux/Vehicle/actions";

class index extends Component {
	state = {
		id: this.props.match.params?.id,
		vehicleType: "",
		vehicleName: "",
		vehicleYear: new Date(),
		type: "1",
		changeOilDate: new Date(),
		vehicleTires: [{ oldSerial: '' }],
		tireReplaces: [{ oldSerial: '' }],
		wheel: false,

		isWheel: false,
		isSummited: false,
		isRequesting: false,
		isShowConfirmation: false,
		titlePrefix: titlePrefix(this.props.match.url),
		currentUrl: "/app/vehicles/list",
	};

	componentDidMount() {
		GetPath("Vehicle", "vehicles/")
		if (this.state.id) {
			if (this.props.vehicle.value) {
				// If vehicle data is available in props, set state with props data
				const data = this.props.vehicle
				this.setState({
					vehicleName: data.label,
					vehicleType: data.vehicleType,
					vehicleYear: (data.yearMade || data.yearMade !== "") ? new Date(data.yearMade) : "",
					type: data.type?.toString(),
					changeOilDate: data.changeOilDate ? new Date(data.changeOilDate.split("-").reverse().join("-")) : "",
					vehicleTires: data.vehicleTires?.length ? data.vehicleTires : [{ oldSerial: "" }],
					tireReplaces: data.vehicleTireReplaces || null,
					wheel: data.vehicleTireReplaces?.length > 0, // Update wheel state based on tireReplaces length
				});
			} else {
				// If vehicle data is not available in props, fetch it
				this.props.find_vehicle({ id: this.state.id });
			}
		}
		// Check if there's saved data in handleData.Vehicle and set state accordingly
		if (Object.keys(handleData.Vehicle.data).length !== 0) {
			const data = handleData.Vehicle.data;
			this.setState({
				vehicleName: data.vehicleName,
				vehicleYear: data.vehicleYear ? new Date(data.vehicleYear.split('-')[0]) : null,
				changeOilDate: data.changeOilDate ? new Date(data.changeOilDate) : null,
				vehicleType: { label: data.vehicleType.label, value: data.vehicleType.value },
				vehicleTires: data.vehicleTires,
				tireReplaces: data.tireReplaces ? (
					this.setState({ wheel: true }),
					data.tireReplaces
				) : [{ tireReplace: "" }],
				type: data.type ? data.type.toString() : "0"
			});
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.vehicle !== this.props.vehicle) {
			const data = this.props.vehicle;
			this.setState({
				vehicleName: data.label,
				vehicleType: data.vehicleType,
				vehicleYear: new Date(data.yearMade),
				type: data.type.toString(),
				changeOilDate: data.changeOilDate ? new Date(data.changeOilDate.split("-").reverse().join("-")) : "",
				vehicleTires: data.vehicleTires.length ? data.vehicleTires : [{ oldSerial: '' }],
				tireReplaces: data.tireReplaces.length ? data.tireReplaces : [{ oldSerial: '' }],
				wheel: data.tireReplaces.length > 0, // Update wheel state based on tireReplaces length
			});
		}
		if (this.props.wheel !== prevProps.wheel) {
			this.setState(prevState => ({
				wheel: !prevState.wheel,
				tireReplaces: prevState.wheel ? [{ oldSerial: '' }] : prevState.tireReplaces
			}));
		}
	}

	componentWillUnmount() {
		// Save vehicle data to handleData.Vehicle before unmounting
		handleData.Vehicle = {
			...handleData.Vehicle,
			data: {
				vehicleName: this.state.vehicleName,
				vehicleType: this.state.vehicleType,
				vehicleYear: Moment(this.state.vehicleYear).format("YYYY-MM-DD"),
				changeOilDate: Moment(this.state.changeOilDate).format("YYYY-MM-DD"),
				vehicleTires: this.state.vehicleTires,
				tireReplaces: this.state.wheel ? this.state.tireReplaces : null,
				type: this.state.type,
			}
		};
	}
	resetForm() {
		this.setState({
			id: this.props.match.params?.id,
			vehicleType: "",
			vehicleName: "",
			vehicleYear: new Date(),
			type: "1",
			changeOilDate: new Date(),
			vehicleTires: [{ oldSerial: "" }],
			tireReplaces: [{ tireReplace: "" }],
			wheel: false,
			isSummited: false,
			isRequesting: false,
			isShowConfirmation: false,
		});
	}
	gatherData() {
		if (isEmptyOrWhiteSpace(this.state.vehicleName, this.state.vehicleType, this.state.vehicleYear, this.state.type)) {
			const data = {
				vehicleTypeId: this.state.vehicleType?.value,
				name: this.state.vehicleName,
				type: parseInt(this.state.type)
			};
			if (this.state.changeOilDate)
				data.dateOfChangeOil = Moment(this.state.changeOilDate).format("YYYY-MM-DD");
			if (this.state.vehicleYear)
				data.yearMade = Moment(this.state.vehicleYear).format("YYYY-MM-DD");
			// Handle data of vehicle tire
			const vehicleTire = this.state.vehicleTires.map(tire => tire.oldSerial);
			if (vehicleTire.length > 0)
				data.vehicleTire = vehicleTire;
			// Handle data of vehicle tire replace
			if (this.state.wheel) {
				const vehicleTireReplace = this.state.tireReplaces.map(tire => tire.oldSerial);
				if (vehicleTireReplace.length > 0)
					data.vehicleTireReplaces = vehicleTireReplace;
			}
			// Convert object to FormData
			const formData = new FormData();
			for (const key in data) {
				if (data[key] !== undefined && data[key] !== null) {
					formData.append(key, data[key]);
				}
			}
			return formData;
		} else {
			return null;
		}
	}
	handleSave = () => {
		const vehicleType = this.gatherData();
		this.setState({ isSummited: true });
		if (vehicleType) {
			this.setState({ isRequesting: true });
			if (this.state.id) {
				vehicleType.append("id", this.state.id);
				this.props.update_vehicle(vehicleType, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("Vehicle")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			} else {
				this.setState({ isRequesting: true });
				this.props.add_vehicle(vehicleType, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("Vehicle")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			}
		}
	};
	handleBack = () => {
		this.setState({ isShowConfirmation: false });
		setTimeout(() => { this.props.history.push(`${this.state.currentUrl}`); }, 100);
	};
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	onKeyPress = (e) => {
		if (e.key === "Enter") {
			this.setState({ isLoading: true });
			this.props.fetch_change_tire({
				page: 1,
				rowsPerPage: 1000,
				searchText: this.state.search
			});
		}
	};
	render() {
		return (
			<div>
				<Confirmation handleClose={this.handleBack} isShow={this.state.isShowConfirmation} confirmType="SUCCESS" desc={successDesc} />
				<ActionBar title={`${this.state.titlePrefix} Vehicle`}>
					<Button name={translation["BACK"]} color="secondary" size="md" buttonStyle="filled" onClick={this.handleBack} />
				</ActionBar>
				<div className="ut-d-flex">
					<div style={{ width: 400 }}>
						<div>
							<Label title={translation["Vehicle Type"]} required />
							<LabelSelect
								placeholder={translation["Vehicle Type"]}
								options={this.props.vehicleTypes}
								isDisabled={this.state.titlePrefix === "View"}
								onChange={(selected) => this.setState({ vehicleType: selected })}
								value={this.state.vehicleType}
							/>
							<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.vehicleType))}>Vehicle Type is required</small>
						</div>
						<div>
							<Label title={translation["Vehicles"]} required />
							<Input
								autoFocus
								disabled={this.state.titlePrefix === "View"}
								name="vehicleName"
								placeholder={translation["Vehicles"]}
								onChange={this.onChange}
								value={this.state.vehicleName}
							/>
							<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.vehicleName))}>Vehicle name is required</small>
						</div>
						<div>
							<Label title={translation["Year Vehicle"]} required />
							<LabelDatePicker
								isClearable
								showYearPicker
								value={this.state.vehicleYear}
								disabled={this.state.titlePrefix === "View"}
								styles="ut-custom-label-datePicker-blocked"
								selected={this.state.vehicleYear}
								placeholder={translation["Choose"]}
								onChange={(date) => this.setState({ vehicleYear: date })}
							/>
							<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.vehicleYear))}>Vehicle year is required</small>
						</div>
						<div>
							<Label title="ប្រភេទឡានថ្មី ឬមួយទឹក" required />
							<div className="d-flex align-items-center">
								<div className="ut-input ut-input-rounded ut-input-md ut-color-light"
									style={{ display: "flex", flex: 2, justifyContent: "space-evenly", width: 100 }}>
									<div className="ut-radio-option"
										onClick={() => {
											if (this.state.titlePrefix !== "View") {
												this.setState({ type: "1" });
											}
										}}
									>
										<input
											disabled={this.state.titlePrefix === "View"}
											onChange={(e) => !e.target.disabled && this.setState({ type: "1" })}
											value="1"
											type="radio"
											checked={this.state.type === "1"}
										/>
										<label disabled={this.state.titlePrefix === "View"}>ថ្មី</label>
									</div>
									<div className="ut-radio-option"
										onClick={() => {
											if (this.state.titlePrefix !== "View") {
												this.setState({ type: "2" });
											}
										}}
									>
										<input
											disabled={this.state.titlePrefix === "View"}
											onChange={(e) => !e.target.disabled && this.setState({ type: "2" })}
											value="2"
											type="radio"
											checked={this.state.type === "2"}
										/>
										<label disabled={this.state.titlePrefix === "View"}>មួយទឹក</label>
									</div>
								</div>
							</div>
							<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.type))}>Vehicle type is required</small>
						</div>
						<div>
							<Label title={translation["Change Oil Date"]} />
							<LabelDatePicker
								isClearable
								disabled={this.state.titlePrefix === "View"}
								styles="ut-custom-label-datePicker-blocked"
								selected={this.state.changeOilDate}
								placeholder={translation["Choose"]}
								onChange={(date) => this.setState({ changeOilDate: date })}
							/>
						</div>
						{this.state.titlePrefix !== "View" ? (
							<Button
								name={this.state.id
									? this.state.isRequesting
										? translation["UPDATING"]
										: translation["UPDATE"]
									: this.state.isRequesting
										? translation["SAVING"]
										: translation["SAVE"]
								}
								buttonStyle="filled"
								size="md"
								color={this.state.id ? "warning" : "success"}
								onClick={this.handleSave}
							/>
						) : null}
					</div>
					<div style={{ width: 400 }}>
						{this.state.vehicleTires.map((tire, i) => {
							return (
								<MultipleInput
									key={i}
									index={i}
									title="លេខកូដកង់ចាស់ទី "
									placeholder='លេខកូដកង់'
									disabled={this.state.titlePrefix === "View"}
									length={this.state.vehicleTires.length}
									serial={tire.oldSerial}
									onChange={(e) => {
										let newTires = this.state.vehicleTires;
										newTires[i].oldSerial = e.target.value;
										this.setState({ vehicleTires: [...newTires] });
									}}
									onAddMore={(e) => {
										let newTires = this.state.vehicleTires;
										newTires.push({ oldSerial: "" });
										this.setState({ tires: [...newTires] });
									}}
									onRemove={(e) => {
										let newTires = this.state.vehicleTires;
										if (newTires.length > 1) {
											newTires.splice(i, 1);
											this.setState({ tires: [...newTires] });
										}
									}}
								/>
							);
						})}
					</div>
					<div style={{ width: 400 }}>
						<div>
							<Label title='កង់សាគួរ' />
							<div
								className="ut-input ut-input-rounded ut-input-md ut-color-light"
								style={{ width: 60, paddingLeft: 15 }}
								onClick={() => {
									if (this.state.titlePrefix !== "View") {
										this.setState(prevState => ({
											wheel: !prevState.wheel,
											tireReplaces: !prevState.wheel ? [{ oldSerial: '' }] : prevState.tireReplaces
										}));
									}
								}}
							>
								<div className="ut-checkbox-option">
									<input
										disabled={this.state.titlePrefix === "View"}
										onChange={(e) => this.setState({
											wheel: e.target.checked,
											tireReplaces: [{ oldSerial: '' }]
										})}
										name="wheel"
										type="checkbox"
										checked={this.state.wheel}
									/>
									<label className="mx-2 col-9">{this.state.wheel ? "មាន" : "មិនមាន"}</label>
								</div>
							</div>
						</div>
						{this.state.wheel && this.state.tireReplaces.map((trReplace, i) => {
							return (
								<MultipleInput
									key={i}
									index={i}
									title="លេខកូដកង់សាគួរទី "
									placeholder='លេខកូដកង់សាគួរ'
									disabled={this.state.titlePrefix === "View"}
									length={this.state.tireReplaces.length}
									serial={trReplace.oldSerial}
									onChange={(e) => {
										let newTires = this.state.tireReplaces;
										newTires[i].oldSerial = e.target.value;
										this.setState({ tireReplaces: [...newTires] });
									}}
									onAddMore={(e) => {
										let newTires = this.state.tireReplaces;
										newTires.push({ oldSerial: "" });
										this.setState({ tires: [...newTires] });
									}}
									onRemove={(e) => {
										let newTires = this.state.tireReplaces;
										if (newTires.length > 1) {
											newTires.splice(i, 1);
											this.setState({ tires: [...newTires] });
										}
									}}
								/>
							)
						})}
					</div>
					<div style={{ width: 400 }}>
						<div id="ut-qr" style={{ padding: "10px" }}>
							{
								<QRCode
									value={`${this.state.id},VETVEHICLETRACKING`}
									size="1024"
									level="L"
									renderAs="svg"
									imageSettings={{ excavate: true }}
								/>
							}
						</div>
					</div>
				</div>
			</div >
		);
	}
}

const mapStateToProps = (state) => ({
	vehicleTypes: state.VehicleType.vehicleTypes,
	vehicle: state.Vehicle.vehicle,
});

export default connect(mapStateToProps, { add_vehicle, update_vehicle, find_vehicle })(index);

function MultipleInput(props) {
	return (
		<div>
			<Label title={`${props.title} ${toKhmerNum(props.index + 1)}`} />
			<div className="d-flex align-items-center">
				<div style={{ width: 300 }}>
					<Input disabled={props.disabled} name="serial" placeholder={props.placeholder} onChange={props.onChange} value={props.serial} />
				</div>
				{!props.disabled ? (
					<div style={{ flex: 1 }}>
						{props.index === 0 && props.length === 1 ? (
							<AddMore onClick={props.onAddMore} style={{ cursor: "pointer" }} />
						) : props.index === props.length - 1 ? (
							<>
								<AddMore onClick={props.onAddMore} style={{ cursor: "pointer" }} />
								<Remove onClick={props.onRemove} style={{ cursor: "pointer" }} />
							</>
						) : (
							<Remove onClick={props.onRemove} style={{ cursor: "pointer" }} />
						)}
					</div>
				) : null}
			</div>
		</div>
	);
}
