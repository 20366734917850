import React, { Component } from 'react';
import { connect } from 'react-redux';
import Profile from '../../../../assets/icons/userProfile.svg';
import { ReactComponent as Cross } from '../../../../assets/icons/x.svg';
import { baseUrl } from '../../../../auth/secret';
import { Button, Input, Label, LabelSelect } from '../../../../components/UTControls';
import { successDesc } from '../../../../constants/dictionary';
import { GetPath, ResetRecords, handleData } from '../../../../constants/handleData';
import { translation } from '../../../../constants/translate';
import ActionBar from '../../../../containers/ActionBar';
import Confirmation from '../../../../containers/modals/Confirmation';
import { isEmptyOrWhiteSpace, titlePrefix } from '../../../../helpers';
import { add_user, find_user, update_user } from '../../../../redux/User/actions';

const genders = [
  { value: 1, label: 'Male' },
  { value: 2, label: 'Female' },
];

class index extends Component {
  state = {
    id: this.props.match.params?.id,
    profileImage: '',
    previewImage: '',
    name: '',
    username: '',
    gender: { value: 1, label: 'Male' },
    telephone: '',
    systemRole: this.props.systemRoles[0],
    password: '',
    reTypePassword: '',
    branchList: [],

    originBranchList: [],

    isSummited: false,
    isRequesting: false,
    isShowConfirmation: false,

    titlePrefix: titlePrefix(this.props.match.url),
    currentUrl: '/app/setting/users/list',
  };

  componentDidMount() {
    GetPath("User", "users/")
    if (this.state.id) {
      if (this.props.user.label) {
        this.setState(
          {
            previewImage: isEmptyOrWhiteSpace(this.props.user.avatar) ? `${baseUrl}${this.props.user.avatar}` : '',
            name: this.props.user.label,
            username: this.props.user.username,
            gender: this.props.user.gender,
            telephone: this.props.user.telephone,
            systemRole: this.props.user.systemRole,
            branchList: this.props.user.branchList,
          },
          () => {
            let tempBranches = [];
            this.props.branches.forEach((branch) => {
              if (!this.state.branchList.find((ele) => ele.value === branch.value)) {
                tempBranches.push(branch);
              }
            });
            this.setState({ originBranchList: tempBranches });
          }
        );
      } else {
        this.props.find_user({ id: this.state.id });
      }
    }
    this.setState({ originBranchList: this.props.branches });
    // is data is already input
    if (Object.keys(handleData.User.data.addEdit).length !== 0) {
      const data = handleData.User.data.addEdit;
      this.setState({
        previewImage: data.previewImage,
        name: data.name,
        username: data.username,
        gender: data.gender,
        telephone: data.telephone,
        systemRole: data.systemRole,
        password: data.password,
        reTypePassword: data.reTypePassword,
        branchList: data.branchList,
      });
    }
  }
  componentWillUnmount() {
    handleData.User = {
      ...handleData.User,
      data: {
        ...handleData.User.data,
        addEdit: {
          previewImage: this.state.previewImage,
          name: this.state.name,
          username: this.state.username,
          gender: this.state.gender,
          telephone: this.state.telephone,
          systemRole: this.state.systemRole,
          password: this.state.password,
          reTypePassword: this.state.reTypePassword,
          branchList: this.state.branchList,
        }
      }
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.branches !== this.props.branches) {
      let tempBranches = [];
      this.props.branches.forEach((branch) => {
        if (!this.state.branchList.find((ele) => ele.value === branch.value)) {
          tempBranches.push(branch);
        }
      });
      this.setState({ originBranchList: tempBranches });
    }

    if (prevProps.user !== this.props.user) {
      this.setState(
        {
          previewImage: isEmptyOrWhiteSpace(this.props.user.avatar) ? `${baseUrl}${this.props.user.avatar}` : '',
          name: this.props.user.label,
          username: this.props.user.username,
          gender: this.props.user.gender,
          telephone: this.props.user.telephone,
          systemRole: this.props.user.systemRole,
          branchList: this.props.user.branchList,
        },
        () => {
          let tempBranches = [];
          this.props.branches.forEach((branch) => {
            if (!this.state.branchList.find((ele) => ele.value === branch.value)) {
              tempBranches.push(branch);
            }
          });
          this.setState({ originBranchList: tempBranches });
        }
      );
    }
  }

  resetForm() {
    this.setState({
      id: null,
      profileImage: '',
      previewImage: '',
      name: '',
      username: '',
      gender: { value: 1, label: 'Male' },
      telephone: '',
      systemRole: '',
      password: '',
      reTypePassword: '',
      branchList: [],

      originBranchList: [],

      isSummited: false,
      isRequesting: false,
      isShowConfirmation: false,
    });
  }

  gatherData() {
    if (isEmptyOrWhiteSpace(this.state.name, this.state.username)) {
      if (this.state.password === this.state.reTypePassword) {
        let dataCollection = new FormData();

        dataCollection.append('fullName', this.state.name);
        dataCollection.append('username', this.state.username);
        dataCollection.append('sex', this.state.gender.value);
        dataCollection.append('telephone', this.state.telephone);
        dataCollection.append('roleId', this.state.systemRole?.value);
        isEmptyOrWhiteSpace(this.state.password) && dataCollection.append('password', this.state.password);
        dataCollection.append('file', this.state.profileImage);
        this.state.branchList.forEach((branch) => {
          dataCollection.append('userBranch', branch.value);
        });

        return dataCollection;
      } else alert('Password does not match!');
      return null;
    } else return null;
  }

  handleBack = () => {
    this.props.history.push(`${this.state.currentUrl}`);
  };

  handleSave = () => {
    let user = this.gatherData();
    this.setState({ isSummited: true });
    if (user) {
      this.setState({ isRequesting: true });
      if (this.state.id) {
        user.append('id', this.state.id);
        this.props.update_user(user, (acknowledge, message) => {
          this.setState({ isRequesting: false });
          if (acknowledge) {
            this.resetForm();
            ResetRecords("User")
            this.setState({ isShowConfirmation: true });
          } else alert(message);
        });
      } else {
        this.props.add_user(user, (acknowledge, message) => {
          this.setState({ isRequesting: false });
          if (acknowledge) {
            this.resetForm();
            ResetRecords("User")
            this.setState({ isShowConfirmation: true });
          } else alert(message);
        });
      }
    }
  };

  handleRemoveBranch = (branch, index) => {
    let tempBranchList = this.state.branchList;
    tempBranchList.splice(index, 1);

    this.setState({ vehicleTypeList: tempBranchList, originBranchList: [...this.state.originBranchList, branch] });
  };

  onChange = (e) => {
    if (e.target.name !== 'file') {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      if (e.target.files[0] && e.target.files[0].size <= 4000000) {
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        this.setState({ profileImage: e.target.files[0], previewImage: URL.createObjectURL(e.target.files[0]) });
      }
    }
  };

  render() {
    return (
      <div>
        <Confirmation handleClose={this.handleBack} isShow={this.state.isShowConfirmation} confirmType="SUCCESS" desc={successDesc} />
        <ActionBar title={`${this.state.titlePrefix} User`}>
          <Button name={translation['BACK']} color="secondary" size="md" buttonStyle="filled" onClick={this.handleBack} />
        </ActionBar>
        <div className="ut-setting-scrollable">
          <div className="ut-d-flex">
            <div style={{ width: 400 }}>
              <div>
                <Label title={translation['Profile']} />
                <div className="ut-d-flex ut-flex-rows" style={{ paddingLeft: 10, alignItems: 'center' }}>
                  <img className="ut-user-profile" src={isEmptyOrWhiteSpace(this.state.previewImage) ? this.state.previewImage : Profile} alt="profile" />
                  <Input disabled={this.state.titlePrefix === 'View'} accept="image/png, image/jpeg" name="file" onChange={this.onChange} type="file" />
                </div>
              </div>
              <div>
                <Label title={translation['Full Name']} required />
                <Input autoFocus disabled={this.state.titlePrefix === 'View'} name="name" placeholder={translation['Full Name']} onChange={this.onChange} value={this.state.name} />
                <small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.name))}>Full name is required</small>
              </div>
              <div>
                <Label title={translation['Username']} required />
                <Input disabled={this.state.titlePrefix === 'View'} name="username" placeholder={translation['Username']} onChange={this.onChange} value={this.state.username} />
                <small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.username))}>Username is required</small>
              </div>
              <div>
                <Label title={translation['Gender']} />
                <LabelSelect
                  placeholder={translation['Gender']}
                  options={genders}
                  isDisabled={this.state.titlePrefix === 'View'}
                  onChange={(selected) => this.setState({ gender: selected })}
                  value={this.state.gender}
                />
              </div>
              <div>
                <Label title={translation['Telephone']} />
                <Input disabled={this.state.titlePrefix === 'View'} name="telephone" placeholder={translation['Telephone']} onChange={this.onChange} value={this.state.telephone} />
              </div>
              {this.state.titlePrefix !== 'Edit' && (
                <>
                  <div>
                    <Label title={translation['System Role']} required />
                    <LabelSelect
                      placeholder={translation['System Role']}
                      options={this.props.systemRoles}
                      isDisabled={this.state.titlePrefix === 'View'}
                      onChange={(selected) => this.setState({ systemRole: selected })}
                      value={this.state.systemRole}
                    />
                    <small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.systemRole))}>System Role is required</small>
                  </div>
                  <div>
                    <Label title={translation['Password']} required />
                    <Input disabled={this.state.titlePrefix === 'View'} name="password" placeholder={translation['Password']} onChange={this.onChange} type="password" value={this.state.password} />
                    <small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.password))}>Password is required</small>
                  </div>
                  <div>
                    <Label title={translation['Re-Type Password']} required />
                    <Input
                      disabled={this.state.titlePrefix === 'View'}
                      name="reTypePassword"
                      placeholder={translation['Re-Type Password']}
                      onChange={this.onChange}
                      type="password"
                      value={this.state.reTypePassword}
                    />
                    <small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.reTypePassword))}>Re-Type Password is required</small>
                  </div>
                </>
              )}
              <br />
              {this.state.titlePrefix !== 'View' && (
                <Button
                  name={this.state.id ? (this.state.isRequesting ? translation['UPDATING'] : translation['UPDATE']) : this.state.isRequesting ? translation['SAVING'] : translation['SAVE']}
                  color={this.state.id ? 'warning' : 'success'}
                  size="md"
                  buttonStyle="filled"
                  onClick={this.handleSave}
                />
              )}
            </div>
            <div style={{ width: 400 }}>
              <Label title="" />
              <LabelSelect
                placeholder="Select branch"
                options={[...this.state.originBranchList]}
                isDisabled={this.state.titlePrefix === 'View'}
                onChange={(selected) => {
                  if (this.state.originBranchList.includes(selected)) {
                    let tempBranchList = this.state.originBranchList;
                    let index = tempBranchList.indexOf(selected);
                    tempBranchList.splice(index, 1);
                    this.setState({ branchList: [...this.state.branchList, selected], originBranchList: [...tempBranchList] });
                  } else if (selected.value === 'all') {
                    this.setState({ branchList: [...this.state.branchList, ...this.state.originBranchList], originBranchList: [] });
                  }
                }}
                value={''}
              />
              <div className="ut-select-list">
                {this.state.branchList.map((branch, i) => (
                  <div key={i}>
                    <span style={{ userSelect: 'none' }}>{branch.label}</span>
                    {this.state.titlePrefix !== 'View' && <Cross onClick={() => this.handleRemoveBranch(branch, i)} />}
                  </div>
                ))}
              </div>
              <p style={{ fontSize: 12, textAlign: 'end', marginRight: '10px', userSelect: 'none' }}>{`Select branch: ${this.state.branchList.length}/${this.props.branches.length}`}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  branches: state.Branch.branches,
  systemRoles: state.SystemRole.systemRoles,
  user: state.User.user,
});

export default connect(mapStateToProps, { add_user, update_user, find_user })(index);
