import React from "react";
import PropTypes from "prop-types";
function TextArea(props: any) {
	return (
		<div className={`ut-textarea ${props.disabled ? "ut-input-disabled" : ""}`}>
			<textarea
				className={`${props.disabled ? "ut-input-disabled" : ""}`}
				name={props.name}
				value={props.value}
				placeholder={props.placeholder}
				disabled={props.disabled}
				rows={props.rows ? props.rows : 4}
				onChange={props.onChange}
				onKeyDown={props.onKeyDown}
				onKeyUp={props.onKeyUp}
			/>
		</div>
	);
}

TextArea.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.string,
	rows: PropTypes.number,
	disabled: PropTypes.bool,

	onChange: PropTypes.func,
};

export default TextArea;
