import Moment from 'moment';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as AddMore } from '../../../assets/icons/add-more.svg';
import { ReactComponent as Remove } from '../../../assets/icons/remove.svg';
import { BulletList, Button, Input, Label, LabelDatePicker, LabelSelect } from '../../../components/UTControls';
import { successDesc } from '../../../constants/dictionary';
import { GetPath, ResetRecords, handleData } from "../../../constants/handleData";
import { translation } from '../../../constants/translate';
import ActionBar from '../../../containers/ActionBar';
import Confirmation from '../../../containers/modals/Confirmation';
import { formatbulletlist, isEmptyOrWhiteSpace, populateList, titlePrefix } from '../../../helpers';
import { add_vehicle_repair, find_vehicle_repair, update_vehicle_repair } from '../../../redux/VehicleRepair/actions';

class index extends Component {
   state = {
      id: this.props.match.params?.id,
      date: new Date(),
      vehicle: '',
      totalAmount: 0,
      description: [''],
      spareparts: [{ label: '', situation: 1 }],
      descRef: createRef(),
      isSummited: false,
      isRequesting: false,
      isShowConfirmation: false,
      titlePrefix: titlePrefix(this.props.match.url),
      currentUrl: '/app/vehicle-repair/list',
   };
   // use for set value to fill while page is load
   componentDidMount() {
      GetPath("VehicleRepair", "vehicle-repair/")
      const { vehicleRepair } = this.props;
      // set value from db
      if (this.state.id) {
         if (vehicleRepair.no) {
            this.setState({
               vehicle: vehicleRepair.vehicle,
               totalAmount: vehicleRepair.totalAmount,
               date: vehicleRepair.date ? new Date(vehicleRepair.date.split('-').reverse().join('-')) : '',
               description: vehicleRepair.description,
               spareparts: vehicleRepair.spareparts,
            }, () => {
               let { current } = this.state.descRef;
               current.innerText = '';
               populateList(this.state.description, current);
            });
         } else {
            this.props.find_vehicle_repair({ id: this.state.id });
         }
      }
      // Check if VehicleRepair data has values
      if (Object.keys(handleData.VehicleRepair.data).length !== 0) {
         const { data } = handleData.VehicleRepair;
         const spareparts = data.vehicleBrokeAccessories.map(sparepart => ({
            label: sparepart.name,
            situation: sparepart.status
         }));

         // extract desc data
         const description = data.note ? data.note.split('\n') : [''];
         const formattedDescription = description.map(item => item);

         this.setState({
            vehicle: data.vehicleId,
            totalAmount: data.amount,
            date: data.date ? new Date(data.date) : '',
            description: formattedDescription,
            spareparts: spareparts,
         }, () => {
            let { current } = this.state.descRef;
            current.innerText = '';
            populateList(this.state.description, current);
         });
      }
   }

   componentDidUpdate(prevProps) {
      if (prevProps.vehicleRepair !== this.props.vehicleRepair) {
         this.setState({
            vehicle: this.props.vehicleRepair.vehicle,
            totalAmount: this.props.vehicleRepair.totalAmount,
            date: this.props.vehicleRepair.date ? new Date(this.props.vehicleRepair.date.split('-').reverse().join('-')) : '',
            description: this.props.vehicleRepair.description,
            spareparts: this.props.vehicleRepair.spareparts,
         }, () => {
            let { current } = this.state.descRef;
            current.innerText = '';
            populateList(this.state.description, current);
         });
      }
   }
   // use for handle data while leave page
   componentWillUnmount() {
      let vehicleBrokeAccessories = this.state.spareparts.map(sparepart => ({
         name: sparepart.label,
         status: sparepart.situation
      }));

      handleData.VehicleRepair = {
         ...handleData.VehicleRepair,
         data: {
            vehicleId: this.state.vehicle ? this.state.vehicle : '',
            date: this.state.date ? Moment(this.state.date).format('YYYY-MM-DD') : '',
            amount: this.state.totalAmount ? this.state.totalAmount : 0,
            note: formatbulletlist(this.state.description),
            vehicleBrokeAccessories,
         }
      };
   }

   gatherData() {
      if (isEmptyOrWhiteSpace(this.state.date, this.state.vehicle, this.state.totalAmount, this.state.description, ...this.state.spareparts.map((sparepart) => sparepart.label))) {
         let vehicleBrokeAccessories = [];
         this.state.spareparts.forEach((sparepart) => {
            vehicleBrokeAccessories.push({
               name: sparepart.label,
               status: sparepart.situation
            });
         });

         return {
            date: Moment(this.state.date).format('YYYY-MM-DD'),
            vehicleId: this.state.vehicle.value,
            amount: this.state.totalAmount,
            note: formatbulletlist(this.state.description),
            vehicleBrokeAccessories,
         };
      } else return null;
   }
   resetForm() {
      this.setState({
         id: null,
         date: new Date(),
         vehicle: '',
         totalAmount: 0,
         description: [''],
         spareparts: [{ label: '', situation: 1 }],
         isSummited: false,
         isRequesting: false,
         isShowConfirmation: false,
      });
   }
   handleBack = () => {
      this.setState({ isShowConfirmation: false });
      setTimeout(() => {
         this.props.history.push(`${this.state.currentUrl}`);
      }, 100);
   };
   handleSave = () => {
      // check if field is empty
      let vehicleRepair = this.gatherData();
      this.setState({ isSummited: true });

      if (vehicleRepair) {
         this.setState({ isRequesting: true });
         if (this.state.id) {
            vehicleRepair.id = this.state.id;
            this.props.update_vehicle_repair(vehicleRepair, (acknowledge, message) => {
               this.setState({ isRequesting: false });
               if (acknowledge) {
                  this.resetForm();
                  ResetRecords("VehicleRepair")
                  this.setState({ isShowConfirmation: true });
               } else alert(message);
            });
         } else {
            this.setState({ isRequesting: true });
            this.props.add_vehicle_repair(vehicleRepair, (acknowledge, message) => {
               this.setState({ isRequesting: false });
               if (acknowledge) {
                  this.resetForm();
                  ResetRecords("VehicleRepair")
                  this.setState({ isShowConfirmation: true });
               } else alert(message);
            });
         }
      }
   };
   onChange = (e) => {
      if (e.target.value.length < 2) {
         this.setState({ [e.target.name]: '• ' });
      } else this.setState({ [e.target.name]: e.target.value });
   };

   render() {
      return (<div>
         <Confirmation handleClose={this.handleBack} isShow={this.state.isShowConfirmation} confirmType="SUCCESS"
            desc={successDesc} />
         <ActionBar title={`${this.state.titlePrefix} Vehicle Repair`}>
            <Button name={translation['BACK']} color="secondary" size="md" buttonStyle="filled"
               onClick={this.handleBack} />
         </ActionBar>
         <div className="ut-d-flex">
            <div style={{ width: 400 }}>
               <div className='mb-2'>
                  <Label title={translation['Date']} required />
                  <LabelDatePicker
                     disabled={this.state.titlePrefix === 'View'}
                     styles="ut-custom-label-datePicker-blocked"
                     placeholder={translation['Date']}
                     onChange={(date) => this.setState({ date })}
                     selected={this.state.date}
                  />
                  <small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.date))}>Date is required</small>
               </div>
               <div className='mb-3'>
                  <Label title={translation['Vehicles']} required />
                  <LabelSelect
                     placeholder={translation['Vehicles']}
                     options={this.props.vehicles}
                     isDisabled={this.state.titlePrefix === 'View'}
                     onChange={(selected) => this.setState({ vehicle: selected })}
                     value={this.state.vehicle}
                  />
                  <small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.vehicle))}>Vehicle name is required</small>
               </div>
               <div>
                  <Label title={translation['TOTAL AMOUNT']} required />
                  <Input
                     disabled={this.state.titlePrefix === 'View'}
                     type="number"
                     name="totalAmount"
                     placeholder={translation['TOTAL AMOUNT']}
                     onBlur={(e) => this.setState({ totalAmount: e.target.value ? e.target.value : 0 })}
                     onFocus={(e) => {
                        if (this.state.totalAmount === 0) {
                           this.setState({ [e.target.name]: '' });
                        }
                     }}
                     onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                     value={this.state.totalAmount}
                  />
                  <small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.totalAmount))}>Total
                     Amount is required</small>
               </div>
               <br />
               {this.state.titlePrefix !== 'View' ? (<Button
                  name={this.state.id ? (this.state.isRequesting ? translation['UPDATING'] : translation['UPDATE']) : this.state.isRequesting ? translation['SAVING'] : translation['SAVE']}
                  color={this.state.id ? 'warning' : 'success'}
                  size="md"
                  buttonStyle="filled"
                  onClick={this.handleSave}
               />) : null}
            </div>
            <div style={{ width: 400 }}>
               {this.state.spareparts.map((sparepart, i) => {
                  return (<SparepartItem
                     key={i}
                     index={i}
                     disabled={this.state.titlePrefix === 'View'}
                     isSummited={this.state.isSummited}
                     length={this.state.spareparts.length}
                     sparepart={sparepart}
                     onChange={(e) => {
                        let newSpareparts = this.state.spareparts;
                        if (e.target.type === 'text') {
                           newSpareparts[i].label = e.target.value;
                        } else newSpareparts[i].situation = parseInt(e.target.value);
                        this.setState({ spareparts: [...newSpareparts] });
                     }}
                     onAddMore={(e) => {
                        this.setState({ spareparts: [...this.state.spareparts, { label: '', situation: 1 }] });
                     }}
                     onRemove={(e) => {
                        let newSpareparts = this.state.spareparts;
                        if (newSpareparts.length > 1) {
                           newSpareparts.splice(i, 1);
                           this.setState({ spareparts: [...newSpareparts] });
                        }
                     }}
                  />);
               })}
               <BulletList
                  placeholder={['ការខូចខាត']}
                  name={translation['DESCRIPTION']}
                  disabled={this.state.titlePrefix === 'View'}
                  required
                  bulletRef={this.state.descRef}
                  value={this.state.description}
                  isSummited={this.state.isSummited}
                  onInput={(list) => this.setState({ description: [...list] })}
               />
            </div>
         </div>
      </div>);
   }
}

const mapStateToProps = (state) => ({
   vehicleRepair: state.VehicleRepair.vehicleRepair,
   vehicles: state.Vehicle.vehicles,
});

export default connect(mapStateToProps, { add_vehicle_repair, update_vehicle_repair, find_vehicle_repair })(index);

// component multiple input
function SparepartItem(props) {
   return (<div className="d-flex flex-column">
      <div style={{ flex: 1 }} className='mb-2'>
         <Label title="ស្ថានភាព" required />
         <div className="d-flex align-items-center">
            <div className="px-5 ut-input ut-input-rounded ut-input-md ut-color-light"
               style={{ display: 'flex', flex: 2, justifyContent: 'space-between' }}>
               <div className="ut-radio-option">
                  <input
                     disabled={props.disabled}
                     onChange={props.onChange}
                     value={1} name={`sparepart-${props.index}`}
                     type="radio" id="repair"
                     checked={props.sparepart.situation === 1}
                  />
                  <label disabled={props.disabled} htmlFor="repair">ជួសជុល</label>
               </div>
               <div className="ut-radio-option">
                  <input disabled={props.disabled} onChange={props.onChange} value={2}
                     name={`sparepart-${props.index}`} type="radio" id="replace"
                     checked={props.sparepart.situation === 2} />
                  <label htmlFor="replace">ផ្លាស់ប្ដូរ</label>
               </div>
            </div>
            {!props.disabled ? (<div style={{ flex: 1 }}>
               {props.index === 0 && props.length === 1 ? (<AddMore onClick={props.onAddMore}
                  style={{ cursor: 'pointer' }} />) : props.index === props.length - 1 ? (<>
                     <AddMore onClick={props.onAddMore} style={{ cursor: 'pointer' }} />
                     <Remove onClick={props.onRemove} style={{ cursor: 'pointer' }} />
                  </>) : (<Remove onClick={props.onRemove} style={{ cursor: 'pointer' }} />)}
            </div>) : null}
         </div>
      </div>
      <div style={{ flex: 2 }} className='mb-2'>
         <Label title={translation['CHANGE SPAREPARTS']} required />
         <Input disabled={props.disabled} placeholder={translation['CHANGE SPAREPARTS']} onChange={props.onChange}
            isDisabled={props.disabled} value={props.sparepart.label} />
         <small hidden={!(props.isSummited && !isEmptyOrWhiteSpace(props.sparepart.label))}>Sparepart is
            required</small>
      </div>
   </div>);
}
