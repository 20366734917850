import React from "react";

import { APP_NAME, APP_VERSION } from "../../constants/app";
import { enableContext } from "../../auth/secret";
import { isEmptyOrWhiteSpace } from "../../helpers";
import { login } from "../../auth";

import { Input, Button } from "../../components/UTControls";
import { faLock, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import Thumbnail from "../../assets/images/thumbnail.svg";
import Logo from "../../assets/images/logo.png";
import { connect } from "react-redux";

class index extends React.Component {
	state = {
		username: "",
		password: "",
		isLogging: false,
		message: "",
	};

	_onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	_onLogin = () => {
		if (isEmptyOrWhiteSpace(this.state.username, this.state.password)) {
			this.setState({ isLogging: true });
			login(this.state.username, this.state.password)
				.then((result) => {
					if (result.status) {
						window.location.pathname = enableContext ? `/${APP_NAME}` : "/";
					} else {
						this.setState({ isLogging: false, message: result.msg });
					}
				})
				.catch((err) => {
					console.log(err);
					this.setState({ isLogging: false, message: "Opp! Something went wrong. Please try again!" });
				});
		} else
			this.setState({
				message: "Please input username and password!",
			});
	};
	_onKeyDown = (e) => {
		if (e.key === "Enter") this._onLogin();
	};
	render() {
		return (
			<div
				className="ut-login-container"
				style={{ background: "linear-gradient(182.93deg, #834585 -27.95%, rgba(255, 255, 255, 0) 193.48%), #DE315B" }}
			>
				<div className="ut-login-thumbnail">
					<h3 className="ut-login-title text-nowrap">Vehicle Tracking</h3>
					<span />
					<img src={Thumbnail} alt="" />
				</div>
				<div className="ut-login-input">
					<div className="ut-login-form">
						<center>
							<img src={Logo} alt="" width="35%" />
						</center>
						<h3 style={{ color: "white" }}>Login</h3>
						<Input
							autoFocus
							rightIcon={faUserCircle}
							inputStyle="underline"
							color="white"
							name="username"
							placeholder="username"
							onChange={this._onChange}
							value={this.state.username}
							onKeyDown={this._onKeyDown}
						/>
						<Input
							rightIcon={faLock}
							inputStyle="underline"
							color="white"
							name="password"
							placeholder="password"
							type="password"
							onChange={this._onChange}
							value={this.state.password}
							onKeyDown={this._onKeyDown}
						/>
						<span className="ut-color-danger ut-danger-message">{this.state.message}</span>
						<br />
						<div className="ut-w-100">
							<Button
								name={this.state.isLogging ? "Login..." : "Login"}
								block
								color="white"
								buttonStyle="filled"
								onClick={this._onLogin}
							/>
						</div>
					</div>
					<div className="ut-credit">
						<span>
							Powered by: <b>UDAYA TECHNOLOGY Co., Ltd.<span style={{fontSize: "10px"}}> V{APP_VERSION}</span></b>
						</span>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(index);
