import Moment from "moment";
import { authfetch } from "../../auth";
import { baseUrl, headersJsonToken, headersToken } from "../../auth/secret";
import { dateOption } from "../../constants/variables";
import { durationFormat, toKhmerNum } from "../../helpers";
import { GET_REPORT, GET_SUMMARYREPORT, VOID_REPORT } from "./types";

export const fetch_report = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/report/list`, { method: "POST", headers: headersJsonToken(), body: JSON.stringify(payload) })
		.then((data) => {
			let reports = [];
			reports = data.body.data.map((report, i) => {
				let duration = report.stopDate ? parseInt(new Moment(report.stopDate).diff(Moment(report.created)) / (1000 * 60)) : "-";
				let passing = [];
				passing = report.vehicleDestinationResponses.map((pass) => ({
					value: pass.branchToId,
					label: pass.branchToName,
					startDate: pass.startDate ? Moment(pass.startDate).format("DD-MM-YYYY hh:mm A") : "-",
					stopDate: pass.stopDate ? Moment(pass.stopDate).format("DD-MM-YYYY hh:mm A") : "-",
					state: pass.startDate && pass.stopDate ? 2 : pass.stopDate ? 1 : 0,
				}));
				return {
					no: i + 1,
					value: report.id,
					vehicle: { value: report.vehicleId, label: report.vehicleName },
					vehicleType: { value: report.vehicleTypeId, label: report.vehicleTypeName },
					origin: { value: report.branchId, label: report.branchName },
					destination: {
						value: report.branchToBId ? report.branchToBId : report.branchToAId,
						label: report.branchToBName ? report.branchToBName : report.branchToAName,
					},
					passing,
					passingProvince: `${passing.map((pass) => pass.label)}`,
					start: `${Moment(report.created).format("DD-MM-YYYY hh:mm A")} ${report.createdByName}`,
					end: report.stopDate ? `${Moment(report.stopDate).format("DD-MM-YYYY hh:mm A")} ${report.stopByName}` : "មិនទាន់មកដល់",
					duration: durationFormat(duration),
					status: parseInt(report.status),
					remark: report.remarkStart,
				};
			});
			dispatch({ type: GET_REPORT, payload: { reports } });
		})
		.catch((error) => {
			console.error(error);
			dispatch({ type: GET_REPORT, payload: { reports: [] } });
		});
};

export const fetch_summaryReport = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/report/Report-summary`, { method: "POST", headers: headersJsonToken(), body: JSON.stringify(payload) })
		.then((data) => {
			let summaryReports = [];
			summaryReports = data.body.data.map((report, i) => {
				return {
					no: i + 1,
					value: report.id,
					vehicleType: { value: report.vehicleTypeId, label: report.vehicleTypeName },
					vehicle: { value: report.vehicleId, label: report.vehicleName },
					numberOfTrip: parseInt(report.numberOfTriplist[0] ? report.numberOfTriplist[0].numberOfTrips : 0),
					status: report.numberOfTriplist[0] ? (report.numberOfTriplist[0].departure !== 0 ? 1 : 2) : 2,
				};
			});
			dispatch({ type: GET_SUMMARYREPORT, payload: { summaryReports } });
		})
		.catch((error) => {
			console.error(error);
			dispatch({ type: GET_SUMMARYREPORT, payload: { summaryReports: [] } });
		});
};

export const void_report = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/report/delete/${payload}`, { method: "POST", headers: headersToken() })
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({ type: VOID_REPORT });
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const fetch_vehicleRepairReport = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-broke/report`, { method: "POST", headers: headersJsonToken(), body: JSON.stringify(payload) })
		.then((data) => {
			let reports = data.body.data.map((report, i) => {
				let status = report.status;
				let reportData = report.vehicleBrokeResponses.map((data, j) => {
					let spareparts = [];
					let sparepartsNote = "";
					let description = [];
					let descriptionNote = "";

					data.vehicleBrokeAccessoriesResponses.forEach((sparepart, i) => {
						if (i === 0) {
							sparepartsNote = `${sparepart.name} `;
						} else sparepartsNote = `${sparepartsNote}, ${sparepart.name} `;
						spareparts.push({
							label: sparepart.name,
							situation: sparepart.status,
						});
						return null;
					});

					data.note.split("\n").forEach((no) => {
						descriptionNote += (descriptionNote ? ", " : "") + no;
						description.push(no);
					});

					return {
						no: j + 1,
						value: data.id,
						date: data.date ? Moment(data.date).format(dateOption) : "-",
						vehicleTypeName: data.vehicleTypeName || "-",
						vehicleName: data.vehicleName || "-",
						yearMade: data.yearMade?.split("-")[0] || "-",
						type: data.type ? (data.type === 1 ? "ថ្មី" : "មួយទឹក") : "-",
						amount: data.amount,
						description: description,
						descriptionNote: descriptionNote,
						spareparts: spareparts,
						sparepartsNote: sparepartsNote,
					};
				});
				return {
					status: status,
					reportData: reportData,
				};
			});
			dispatch({ type: GET_REPORT, payload: { reports } });
		})
		.catch((error) => {
			console.error(error);
			dispatch({ type: GET_REPORT, payload: { reports: [] } });
		});
};

export const fetch_changeTireReport = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-change-tire/report`, { method: "POST", headers: headersJsonToken(), body: JSON.stringify(payload) })
		.then((data) => {
			let reports = [];
			reports = data.body.data.map((report, i) => {
				let vehicleTires = [], oldTiresSerialNote, newTiresSerialNote, totalTireChanged = 0,
					vehicleTireReplace = [], oldTireReplaceNote, newTireReplaceNote, totalTireReplaceChanged = 0;

				report.vehicleChangeTireDetailList.map((tire, i) => {
					if (i === 0) {
						oldTiresSerialNote = `${tire.oldTireNumber} (ទី ${toKhmerNum(i + 1)})`;
						newTiresSerialNote = `${tire.newTireNumber}`;
					} else {
						oldTiresSerialNote = `${oldTiresSerialNote}, ${tire.oldTireNumber} (ទី ${toKhmerNum(i + 1)})`;
						newTiresSerialNote = `${newTiresSerialNote}, ${tire.newTireNumber}`;
					}
					vehicleTires.push({ oldSerial: tire.oldTireNumber, newSerial: tire.newTireNumber });
					if (tire.oldTireNumber !== tire.newTireNumber) {
						totalTireChanged += 1;
					}
					return null;
				});

				report.vehicleChangeTireReplaceDetailRequests.map((tire, i) => {
					if (i === 0) {
						oldTireReplaceNote = `${tire.oldTireReplaceNumber} (ទី ${toKhmerNum(i + 1)})`;
						newTireReplaceNote = `${tire.newTireReplaceNumber}`;
					} else {
						oldTireReplaceNote = `${oldTireReplaceNote}, ${tire.oldTireReplaceNumber} (ទី ${toKhmerNum(i + 1)})`;
						newTireReplaceNote = `${newTireReplaceNote}, ${tire.newTireReplaceNumber}`;
					}
					vehicleTireReplace.push({ oldSerial: tire.oldTireReplaceNumber, newSerial: tire.newTireReplaceNumber });
					if (tire.oldTireReplaceNumber !== tire.newTireReplaceNumber) {
						totalTireReplaceChanged += 1;
					}
					return null;
				});

				return {
					no: i + 1,
					value: report.id,
					date: report.date ? Moment(report.date).format(dateOption) : "",
					vehicle: { value: report.vehicleId, label: report.vehicleName },
					vehicleType: { value: report.vehicleTypeId, label: report.vehicleTypeName },
					vehicleTires: vehicleTires || "-",
					oldTiresSerialNote: oldTiresSerialNote || "-",
					newTiresSerialNote: newTireReplaceNote || "-",
					totalTireChanged: `ប្ដូរ ${totalTireChanged} គ្រាប់`,
					vehicleTireReplace: vehicleTireReplace || "-",
					oldTireReplaceNote: oldTireReplaceNote || "-",
					newTireReplaceNote: newTireReplaceNote || "-",
					totalTireReplaceChanged: `ប្ដូរ ${totalTireReplaceChanged} គ្រាប់`,
					remark: report.note,
					createdDate: Moment(report.created).format(dateOption),
				};
			});

			dispatch({ type: GET_REPORT, payload: { reports } });
		})
		.catch((error) => {
			console.error(error);
			dispatch({ type: GET_REPORT, payload: { reports: [] } });
		});
};

export const fetch_changeOilReport = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-engine-oil/report`, { method: "POST", headers: headersJsonToken(), body: JSON.stringify(payload) })
		.then((data) => {
			let reports = [];
			reports = data.body.data.map((report, i) => {
				return {
					no: i + 1,
					value: report.id,
					vehicle: { value: report.vehicleId, label: report.vehicleName },
					vehicleType: { value: report.vehicleTypeId, label: report.vehicleTypeName },
					oilAmount: report.amount,
					situation: { value: report.status, label: report.status === 1 ? "ប្ដូរ" : "ថែម" },
					changeOilCycle: report.numberOfChange,
					changeOilDate: report.date ? Moment(report.date).format(dateOption) : "",
					changeOilDurationEn: report.date ? Math.abs(Moment(new Date()).diff(Moment(report.date), "days")) : "",
					changeOilDurationKh: report.date ? `${toKhmerNum(Math.abs(Moment(new Date()).diff(Moment(report.date), "days")))} ថ្ងៃ` : "",
					createdDate: Moment(report.created).format(dateOption),
				};
			});

			dispatch({ type: GET_REPORT, payload: { reports } });
		})
		.catch((error) => {
			console.error(error);
			dispatch({ type: GET_REPORT, payload: { reports: [] } });
		});
};

export const fetch_accidentReport = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-accident-info/report`, { method: "POST", headers: headersJsonToken(), body: JSON.stringify(payload) })
		.then((data) => {
			let reports = [];
			reports = data.body.data.map((report, i) => {
				let direction = [],
					directionNote = "",
					injuryStatus = [],
					injuryStatusNote = "",
					accidentLocation = [],
					accidentLocationNote = "",
					compensationDate = [],
					compensationDateNote = "";

				report.destination &&
					report.destination.split("\n").map((des, i) => {
						if (i === 0) {
							directionNote = des;
						} else directionNote = `${directionNote}, ${des}`;
						direction.push(des);

						return null;
					});

				report.injuryStatus &&
					report.injuryStatus.split("\n").map((inju, i) => {
						if (i === 0) {
							injuryStatusNote = inju;
						} else injuryStatusNote = `${injuryStatusNote}, ${inju}`;
						injuryStatus.push(inju);

						return null;
					});

				report.accidentLocation &&
					report.accidentLocation.split("\n").map((acc, i) => {
						if (i === 0) {
							accidentLocationNote = acc;
						} else accidentLocationNote = `${accidentLocationNote}, ${acc}`;
						accidentLocation.push(acc);

						return null;
					});

				report.dateOfCompensation &&
					report.dateOfCompensation.split("\n").map((compen, i) => {
						if (i === 0) {
							compensationDateNote = compen;
						} else compensationDateNote = `${compensationDateNote}, ${compen}`;
						compensationDate.push(compen);

						return null;
					});

				return {
					no: i + 1,
					value: report.id,
					vehicle: { value: report.vehicleId, label: report.vehicleName },
					vehicleType: { value: report.vehicleTypeId, label: report.vehicleTypeName },
					driver: report.driver,
					direction,
					directionNote,

					injuryStatus,
					injuryStatusNote,

					note: report.note,
					accidentLocation,
					accidentLocationNote,

					compensationDate,
					compensationDateNote,

					date: report.date ? Moment(report.date).format(dateOption) : "",
					createdDate: Moment(report.created).format(dateOption),
				};
			});

			dispatch({ type: GET_REPORT, payload: { reports } });
		})
		.catch((error) => {
			console.error(error);
			dispatch({ type: GET_REPORT, payload: { reports: [] } });
		});
};
