import { GET_USER, FIND_USER } from "./types";

const initialState = {
	users: [],
	user: {},
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case GET_USER:
			state.users = actions.payload.users;
			return { ...state };
		case FIND_USER:
			state.user = actions.payload.user;
			return { ...state };
		default:
			return { ...state };
	}
};
