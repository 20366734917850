import { GET_REPORT, GET_SUMMARYREPORT } from "./types";

const initialState = {
	reports: [],
	summaryReports: [],
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case GET_REPORT:
			state.reports = actions.payload.reports;
			return { ...state };
		case GET_SUMMARYREPORT:
			state.summaryReports = actions.payload.summaryReports;
			return { ...state };
		default:
			return { ...state };
	}
};
