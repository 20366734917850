import { combineReducers } from "redux";

import Layout from "./Layouts/reducers";
import Navs from "./Navs/reducers";

import Dashboard from "./Dashboard/reducers";
import Tracking from "./Tracking/reducers";
import VehicleRepair from "./VehicleRepair/reducers";
import ChangeTire from "./ChangeTire/reducers";
import ChangeOil from "./ChangeOil/reducers";
import Accident from "./Accident/reducers";
import VehicleType from "./VehicleType/reducers";
import Vehicle from "./Vehicle/reducers";
import Branch from "./Branch/reducers";
import Report from "./Report/reducers";

import User from "./User/reducers";
import SystemRole from "./SystemRole/reducers";
import ModuleType from "./ModuleType/reducers";

export default combineReducers({
	Layout,
	Navs,

	Dashboard,
	Tracking,
	VehicleRepair,
	ChangeTire,
	ChangeOil,
	Accident,
	VehicleType,
	Vehicle,
	Branch,
	Report,

	User,
	SystemRole,
	ModuleType,
});
