import { authfetch } from "../../auth";
import { baseUrl, headersJsonToken, headersToken } from "../../auth/secret";
import { ADD_SYSTEM_ROLE, DELETE_SYSTEM_ROLE, FIND_SYSTEM_ROLE, GET_SYSTEM_ROLE, UPDATE_SYSTEM_ROLE } from "./types";

import Moment from "moment";
import { dateOption } from "../../constants/variables";

export const fetch_systemRole = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/role/list`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			let systemRoles = [];
			systemRoles = data.body.data.map((systemRole, i) => {
				let userList = [];
				systemRole.userList.forEach((user) => (user.checked ? userList.push({ value: user.id, label: user.username }) : null));

				return {
					no: i + 1,
					value: systemRole.id,
					label: systemRole.name,
					createdDate: Moment(systemRole.created).format(dateOption),
					userList,
					moduleTypeList: systemRole.moduleTypeList,
					description: systemRole.description,
				};
			});

			dispatch({
				type: GET_SYSTEM_ROLE,
				payload: { systemRoles },
			});
		})
		.catch((error) => {
			console.error(error);
			dispatch({
				type: GET_SYSTEM_ROLE,
				payload: { systemRoles: [] },
			});
		});
};

export const add_systemRole = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/role/add`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: ADD_SYSTEM_ROLE,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const update_systemRole = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/role/update`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: UPDATE_SYSTEM_ROLE,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const del_systemRole = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/role/delete/${payload}`, {
		method: "POST",
		headers: headersToken(),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: DELETE_SYSTEM_ROLE,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const find_systemRole = (payload) => (dispatch) => {
	if (payload.systemRole) {
		dispatch({
			type: FIND_SYSTEM_ROLE,
			payload: { systemRole: payload.systemRole },
		});
	} else {
		authfetch(`${baseUrl}/role/find/${payload.id}`, {
			method: "POST",
			headers: headersToken(),
		})
			.then((data) => {
				let systemRole = [];
				systemRole = data.body.data.map((role, i) => {
					let userList = [];
					role.userList.forEach((user) => (
						user.checked ?
							userList.push({ value: user.id, label: user.username }) : null)
					);

					return {
						no: i + 1,
						value: role.id,
						label: role.name,
						createdDate: Moment(role.created).format(dateOption),
						userList,
						moduleTypeList: role.moduleTypeList,
						description: role.description,
					};
				});
				dispatch({
					type: FIND_SYSTEM_ROLE,
					payload: { systemRole: systemRole.length > 0 ? systemRole[0] : {} },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: FIND_SYSTEM_ROLE,
					payload: { systemRole: {} },
				});
			});
	}
};
