import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "../../layouts/AdminLayout";
import TVView from "./tvView";

import { isEmptyOrWhiteSpace } from "../../helpers";

class index extends React.Component {
	getRedirectUrl = () => {
		let url = "";
		this.props.Menus.forEach((menu) => {
			if (menu.status && !isEmptyOrWhiteSpace(url)) {
				url = menu.id;
			}
		});

		return url;
	};

	render() {
		return (
			<AdminLayout>
				<div>
					<Switch>
						<Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/${this.getRedirectUrl()}`} />
						{this.props.Menus.map((menu, i) => (menu.status ? <Route key={i} path={menu.to} component={menu.component} /> : null))}
						<Route exact path={`${this.props.match.url}/tv-view`} component={TVView} />
					</Switch>
				</div>
			</AdminLayout>
		);
	}
}

const mapStateToProps = (state) => ({
	Menus: state.Layout.Menus,
});

export default connect(mapStateToProps, {})(index);
