import React from "react";
import { translation } from "../constants/translate";

export default function index(props) {
	return (
		<div className={props.centered ? "ut-actionbar-wrapper-center" : "ut-actionbar-wrapper"}>
			<h3 style={{ fontFamily: "Koh_Santepheap Bold" }}>{translation[props.title]}</h3>
			<div style={{ zIndex: 2 }}>{props.children}</div>
		</div>
	);
}
