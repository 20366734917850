import Moment from "moment";
import { authfetch } from "../../auth";
import { baseUrl, headersJsonToken, headersToken } from "../../auth/secret";
import { dateOption } from "../../constants/variables";
import { toKhmerNum } from "../../helpers";
import { ADD_CHANGE_TIRE, DEL_CHANGE_TIRE, FIND_CHANGE_TIRE, GET_CHANGE_TIRE, UPDATE_CHANGE_TIRE } from "./types";

export const add_change_tire = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-change-tire/add`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: ADD_CHANGE_TIRE,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const update_change_tire = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-change-tire/update`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: UPDATE_CHANGE_TIRE,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const del_change_tire = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-change-tire/delete/${payload}`, {
		method: "POST",
		headers: headersToken(),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: DEL_CHANGE_TIRE,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const fetch_change_tire = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-change-tire/list`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			let changeTires = data.body.data?.map((changeTire, i) => {
				let vehicleTires = [], vehicleTireReplaces = [],
					oldTiresSerialNote, newTiresSerialNote,
					oldTireReplaceNote, newTireReplaceNote;

				// Mapping over vehicleChangeTireDetailList
				changeTire.vehicleChangeTireDetailList.forEach((tire, i) => {
					if (i === 0) {
						oldTiresSerialNote = `${tire.oldTireNumber} (ទី ${toKhmerNum(i + 1)})`;
						newTiresSerialNote = `${tire.newTireNumber}`;
					} else {
						oldTiresSerialNote = `${oldTiresSerialNote}, ${tire.oldTireNumber} (ទី ${toKhmerNum(i + 1)})`;
						newTiresSerialNote = `${newTiresSerialNote}, ${tire.newTireNumber}`;
					}
					vehicleTires.push({ oldSerial: tire.oldTireNumber, newSerial: tire.newTireNumber });
				});

				// Mapping over vehicleChangeTireReplaceDetailRequests if it exists
				if (changeTire.vehicleChangeTireReplaceDetailRequests && changeTire.vehicleChangeTireReplaceDetailRequests.length > 0) {
					changeTire.vehicleChangeTireReplaceDetailRequests.forEach((tire, i) => {
						if (i === 0) {
							oldTireReplaceNote = `${tire.oldTireReplaceNumber} (ទី ${toKhmerNum(i + 1)})`;
							newTireReplaceNote = `${tire.newTireReplaceNumber}`;
						} else {
							oldTireReplaceNote = `${oldTireReplaceNote}, ${tire.oldTireReplaceNumber} (ទី ${toKhmerNum(i + 1)})`;
							newTireReplaceNote = `${newTireReplaceNote}, ${tire.newTireReplaceNumber}`;
						}
						vehicleTireReplaces.push({ oldSerial: tire.oldTireReplaceNumber, newSerial: tire.newTireReplaceNumber });
					});
				}

				return {
					no: i + 1,
					value: changeTire.id,
					date: changeTire.date ? Moment(changeTire.date).format(dateOption) : "",
					vehicle: { value: changeTire.vehicleId, label: changeTire.vehicleName },
					vehicleTires,
					oldTiresSerialNote,
					newTiresSerialNote,
					vehicleTireReplaces,
					oldTireReplaceNote,
					newTireReplaceNote,
					remark: changeTire.note,
					createdDate: Moment(changeTire.created).format(dateOption),
				};
			});
			dispatch({
				type: GET_CHANGE_TIRE,
				payload: { changeTires },
			});
		})
		.catch((error) => {
			console.log(error);
		})
};

export const find_change_tire = (payload) => (dispatch) => {
	if (payload.changeTire) {
		dispatch({
			type: FIND_CHANGE_TIRE,
			payload: { changeTire: payload.changeTire },
		});
	} else {
		authfetch(`${baseUrl}/vehicle-change-tire/find/${payload.id}`, {
			method: "POST",
			headers: headersToken(),
		})
			.then((data) => {
				let changeTire = [];
				changeTire = data.body.data.map((tire, i) => {
					let vehicleTires = [], vehicleTireReplaces = [];
					tire.vehicleChangeTireDetailList.map((tire, i) => {
						vehicleTires.push({ oldSerial: tire.oldTireNumber, newSerial: tire.newTireNumber });
						return null;
					});
					tire.vehicleChangeTireReplaceDetailRequests.map((tire, i) => {
						vehicleTireReplaces.push({ oldSerial: tire.oldTireReplaceNumber, newSerial: tire.newTireReplaceNumber })
						return null;
					})
					return {
						no: i + 1,
						value: tire.id,
						date: tire.date ? Moment(tire.date).format(dateOption) : "",
						vehicle: { value: tire.vehicleId, label: tire.vehicleName },
						vehicleTires,
						vehicleTireReplaces,
						remark: tire.note,
						createdDate: Moment(tire.created).format(dateOption),
					};
				});
				dispatch({
					type: FIND_CHANGE_TIRE,
					payload: { changeTire: changeTire.length > 0 ? changeTire[0] : {} },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: FIND_CHANGE_TIRE,
					payload: { changeTire: {} },
				});
			});
	}
};
