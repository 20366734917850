import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import Moment from 'moment';
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import * as XLSX from "xlsx";
import { ReactComponent as ExportExcelIco } from '../../assets/icons/export-excel.svg';
import Loading from '../../components/Loading';
import { Button, LabelDatePicker, LabelSelect } from '../../components/UTControls';
import { reportAccident, reportChangeOil, reportChangeTire, reportHeaders, reportVehicleRepair, summaryReportHeaders } from '../../constants/dictionary';
import { SaveData, handleData } from '../../constants/handleData';
import { translation } from '../../constants/translate';
import ActionBar from '../../containers/ActionBar';
import ConfirmVoid from '../../containers/modals/ConfirmVoid';
import ReportLayout from '../../containers/reports/ReportLayout';
import { SortIcon, _generateTableHeader, customSort, reportTableStyle } from '../../helpers/tableHelper';
import { fetch_allbranch } from '../../redux/Branch/actions';
import { ChangeSidebar } from '../../redux/Navs/actions';
import { fetch_accidentReport, fetch_changeOilReport, fetch_changeTireReport, fetch_report, fetch_summaryReport, fetch_vehicleRepairReport, void_report } from '../../redux/Report/actions';
import { fetch_vehicle } from '../../redux/Vehicle/actions';
import { fetch_vehicle_type } from '../../redux/VehicleType/actions';

const report_types = [
  { value: 0, key: 'Report (View)', label: 'របាយការណ៍រថយន្តលម្អិត', type: 'រថយន្តលម្អិត', report: reportHeaders },
  { value: 1, key: 'Report Summary (View)', label: 'របាយការណ៍រថយន្តសង្ខេប', type: 'រថយន្តសង្ខេប', report: summaryReportHeaders },
  { value: 2, key: 'Report Vehicle Broke (View)', label: 'របាយការណ៍រថយន្ដខូច', type: 'រថយន្ដខូច', report: reportVehicleRepair },
  { value: 3, key: 'Report Vehicle Change Tire (View)', label: 'របាយការណ៍ប្ដូរកង់រថយន្ត', type: 'រថយន្ដប្ដូរកង់ឡាន', report: reportChangeTire },
  { value: 4, key: 'Report Vehicle Engine Oil (View)', label: 'របាយការណ៍ប្ដូរប្រេងរថយន្ដ', type: 'រថយន្តប្តូរប្រេងម៉ាស៊ីន', report: reportChangeOil },
  { value: 5, key: 'Report Vehicle Accident Information (View)', label: 'របាយការណ៍គ្រោះថ្នាក់', type: 'រថយន្ដគ្រោះថ្នាក់ចរាចរណ៍', report: reportAccident },
];

class index extends Component {
  state = {
    report_types: [],
    report_type: '',
    showReport: false,
    records: [],
    selectedRecord: {},
    dateOnly: new Date(),
    dateFrom: new Date(),
    dateTo: new Date(),
    vehicle: '',
    vehicleType: '',
    origin: '',
    destination: '',
    reportType: '',
    idxTable: 0,
    reportTypeList: [],
    isLoading: false,
    isRequesting: false,
    dataTableColumns: [],
    isShowConfirmation: false,
    allowDelete: this.props.permissionModuleList[this.props.actionName]?.moduleList['Report (Void)'],
  };

  componentDidMount = () => {
    let temReportType = [];
    this.props.ChangeSidebar({ currentPage: this.props.match.url, actionName: 'Report' });
    report_types.forEach((type) => {
      if (this.props.permissionModuleList['Report']?.moduleList[type.key]) {
        temReportType.push(type);
      }
    });
    // set date while page is load
    const report = handleData.Report;
    if (Object.keys(report.filters).length !== 0) {
      // set filter
      let stateToUpdate = {
        report_type: report.filters.report_type,
        origin: report.filters.origin,
        dateFrom: report.filters.dateFrom,
        dateTo: report.filters.dateTo,
        vehicleType: report.filters.vehicleType,
        vehicle: report.filters.vehicle,

        idxTable: report.filters.report_type?.value
      };
      // check for report_type
      if (report.filters.report_type !== '') {
        this.setState(stateToUpdate);
      }
    }
    // set data to records
    if (report.records.length > 0) {
      this.setState({ isLoading: true, isRequesting: true });
      this.setState({ records: report.records }, () => {
        this.setState({ isLoading: false, isRequesting: false });
      });
    }

    this.setState({ allowDelete: this.props.permissionModuleList[this.props.actionName]?.moduleList['Report (Void)'] }, () => {
      let permission = this.handleLayoutPermission();
      this.setState({
        report_types: [...temReportType],
        dataTableColumns: _generateTableHeader(temReportType[this.state.idxTable].report, permission.actions, permission.events),
      });
    });
    this.props.fetch_vehicle_type({ page: 1, rowsPerPage: 1000 });
    this.props.fetch_vehicle({ page: 1, rowsPerPage: 1000 });
    this.props.fetch_allbranch({ page: 1, rowsPerPage: 1000 });
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.reports !== this.props.reports) {
      this.setState({ records: this.props.reports, isLoading: false, isRequesting: false });
      this.setState({ allowDelete: this.props.permissionModuleList[this.props.actionName]?.moduleList['Report (Void)'] }, () => {
        let permission = this.handleLayoutPermission();
        this.setState({ dataTableColumns: _generateTableHeader(this.state.report_type.report, permission.actions, permission.events) });
      });
    }
    if (prevProps.summaryReports !== this.props.summaryReports) {
      this.setState({ records: this.props.summaryReports, isLoading: false, isRequesting: false });
    }

    // searching when state is changed
    // const { report_type, origin, dateFrom, dateTo, vehicleType, vehicle } = this.state;
    // const stateChange = report_type !== prevState.report_type || origin !== prevState.origin || dateFrom !== prevState.dateFrom || dateTo !== prevState.dateTo || vehicleType !== prevState.vehicleType || vehicle !== prevState.vehicle
    // if (stateChange) this.handleSearch();
  }
  componentWillUnmount() {
    // handle data while leave page
    const filters = {
      report_type: this.state.report_type,
      origin: this.state.origin,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      vehicleType: this.state.vehicleType,
      vehicle: this.state.vehicle,
    }
    SaveData("Report", filters, this.state.records)
  }
  gatherData() {
    let dataCollection = {
      page: 1,
      rowsPerPage: 10000000,
      vehicleTypeId: this.state.vehicleType?.value,
      vehicleId: this.state.vehicle?.value,
      branchId: this.state.origin?.value,
    };
    // Helper function to format dates with hour component
    const formatDateWithHour = (date) => date ? Moment(date).format('YYYY-MM-DD HH:mm:ss') : '';
    // Check report type to determine date formatting
    if (this.state.report_type.value < 2) {
      // For reports with date ranges
      dataCollection.dateFrom = formatDateWithHour(this.state.dateFrom);
      dataCollection.dateTo = formatDateWithHour(this.state.dateTo);
    } else {
      // For reports with single date
      dataCollection.dateFrom = formatDateWithHour(this.state.dateFrom);
      dataCollection.dateTo = formatDateWithHour(this.state.dateTo);
    }
    return dataCollection;
  }
  handleLayoutPermission = () => {
    let actions = [],
      events = [];
    if (this.state.allowDelete) {
      actions.push('void');
      events.push(this.handleTrigger);
    }
    return { actions, events };
  };
  handleTrigger = (record = {}) => {
    this.setState({ isShowConfirmation: !this.state.isShowConfirmation }, () => {
      setTimeout(() => {
        this.setState({ selectedRecord: record });
      }, 200);
    });
  };
  handleDelete = () => {
    this.setState({ isShowConfirmation: false });
    this.props.void_report(this.state.selectedRecord?.value, (acknowledge, message) => {
      if (acknowledge) {
        this.handleSearch();
      } else alert(message);
    });
  };
  handleSearch = () => {
    this.setState({ isLoading: true, isRequesting: true }, () => {
      let filter = this.gatherData();
      switch (this.state.report_type.value) {
        case 0:
          this.props.fetch_report(filter);
          break;
        case 1:
          this.props.fetch_summaryReport(filter);
          break;
        case 2:
          this.props.fetch_vehicleRepairReport(filter);
          break;
        case 3:
          this.props.fetch_changeTireReport(filter);
          break;
        case 4:
          this.props.fetch_changeOilReport(filter);
          break;
        case 5:
          this.props.fetch_accidentReport(filter);
          break;
        default:
          console.warn('Invalid report option');
      }
    });
  };
  ExcelExportVehicleBroke = () => {
    // Generate data for Sheet1
    const dataSheet1 = [
      ["N°", "DATE", "VEHICLE TYPE", "VEHICLE", "VEHICLE YEAR", "STATUS", "SPAREPARTS(REPAIR)", "DESCRIPTION", "TOTAL AMOUNT"],
    ];

    this.state.records.length > 0 && this.state.records[0].reportData.forEach((item) => {
      const data = [item.no, item.date, item.vehicleTypeName, item.vehicleName, item.yearMade, item.type, item.sparepartsNote, item.descriptionNote, item.amount];
      dataSheet1.push(data);
    });

    // Generate data for Sheet2
    const dataSheet2 = [
      ["N°", "DATE", "VEHICLE TYPE", "VEHICLE", "VEHICLE YEAR", "STATUS", "SPAREPARTS(REPAIR)", "DESCRIPTION", "TOTAL AMOUNT"],
    ];

    this.state.records.length > 0 && this.state.records[1].reportData.forEach((item) => {
      const data = [item.no, item.date, item.vehicleTypeName, item.vehicleName, item.yearMade, item.type, item.sparepartsNote, item.descriptionNote, item.amount];
      dataSheet2.push(data);
    });

    // Define column widths
    const columnWidths = [
      { wpx: 50 },
      { wpx: 80 },
      { wpx: 80 },
      { wpx: 100 },
      { wpx: 80 },
      { wpx: 80 },
      { wpx: 160 },
      { wpx: 200 },
      { wpx: 100 },
    ];

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Add Sheet1 to the workbook
    const wsSheet1 = XLSX.utils.aoa_to_sheet(dataSheet1);
    wsSheet1["!cols"] = columnWidths;
    XLSX.utils.book_append_sheet(wb, wsSheet1, "ជួសជុល");

    // Add Sheet2 to the workbook
    const wsSheet2 = XLSX.utils.aoa_to_sheet(dataSheet2);
    wsSheet2["!cols"] = columnWidths;
    XLSX.utils.book_append_sheet(wb, wsSheet2, "ផ្លាស់ប្ដូរ");

    // Save the workbook as an Excel file
    XLSX.writeFile(wb, `Report_${Moment().format('DD-MMM-YYYY')}.xlsx`);
  }

  render() {
    return (
      <div className="ut-container">
        <ConfirmVoid handleConfirm={this.handleDelete} handleReject={this.handleTrigger} vehicleId={this.state.selectedRecord?.vehicle?.label} isShow={this.state.isShowConfirmation} />
        <ActionBar title={this.props.actionName}>
          {this.state.report_type !== '' && <Button color='success' onClick={this.handleSearch} name={this.state.isRequesting ? translation['searching'] : translation['search']} buttonStyle="filled" block />}
          <ReactToPrint trigger={() => <Button name="Print" buttonStyle="filled" block />} content={() => this.componentRef} />
          <ExcelExport
            data={this.state.records}
            ref={(excel) => {
              this._export = excel;
            }}
            fileName={`Report ${Moment().format('DD-MMM-YYYY')}.xlsx`}
            filterable={true}>
            {/* button export as excel */}
            <div
              className="ut-export-button-wrapper"
              onClick={() => {
                if (this.state.report_type.value === 2) {
                  this.ExcelExportVehicleBroke()
                } else this._export.save();
              }}>
              <div>
                <ExportExcelIco />
              </div>
              <span className="ut-export-button">EXPORT AS EXCEL</span>
            </div>
            {this.state.report_type?.report?.map((header, i) => {
              if (header.label === 'PASSING') {
                return <ExcelExportColumn key={i} field="passingProvince" title={header.label} />;
              } else return <ExcelExportColumn key={i} field={header.value} title={header.label} />;
            })}
          </ExcelExport>
        </ActionBar>
        <div style={{ width: "100%" }}>
          <div className="ut-d-flex" style={{ position: 'relative', zIndex: 1 }}>
            {/* main menu bar */}
            <LabelSelect
              options={this.state.report_types}
              value={this.state.report_type}
              placeholder="ជ្រើសរើស"
              floater="របាយការណ៍"
              onChange={(selected) =>
                this.setState({
                  records: [],
                  report_type: selected,
                  dataTableColumns: _generateTableHeader(selected.report),
                })
              }
            />
            {/* sub menu bar */}
            <div style={{ display: this.state.report_type === '' ? 'none' : 'flex' }} className="flex-row">
              {this.state.report_type.value < 2 && (
                <LabelSelect
                  isClearable
                  onChange={(selected) => this.setState({ origin: selected })}
                  options={this.props.allbranches}
                  value={this.state.origin}
                  placeholder="ជ្រើសរើស"
                  floater={translation['Branches']}
                />
              )}
              <LabelDatePicker
                isClearable
                floater={translation['DATE_FROM']}
                selected={this.state.dateFrom}
                onChange={(date) => {
                  if (date !== null) {
                    if (this.state.dateTo === '') this.setState({ dateTo: date });
                    this.setState({ dateFrom: date });
                  } else this.setState({ dateFrom: '', dateTo: '' });
                }}
              />
              <LabelDatePicker
                isClearable
                floater={translation['DATE_TO']}
                selected={this.state.dateTo}
                onChange={(date) => {
                  if (date !== null) {
                    if (this.state.dateFrom === '') this.setState({ dateFrom: date });
                    this.setState({ dateTo: date });
                  } else this.setState({ dateFrom: '', dateTo: '' });
                }}
              />
              <LabelSelect
                isClearable
                options={this.props.vehicleTypes}
                value={this.state.vehicleType}
                placeholder="ជ្រើសរើស"
                floater="ប្រភេទយានយន្ដ"
                onChange={(selected) => {
                  this.setState({ vehicleType: selected, vehicle: '' });
                  this.props.fetch_vehicle({ page: 1, rowsPerPage: 1000, vehicleTypeId: selected?.value ? selected.value : 0 });
                }}
              />
              <LabelSelect
                isClearable
                options={this.props.vehicles}
                value={this.state.vehicle}
                placeholder="ជ្រើសរើស"
                floater="លេខយានយន្ដ"
                onChange={(selected) => {
                  this.setState({ vehicle: selected, vehicleType: selected?.vehicleType });
                  this.props.fetch_vehicle({
                    page: 1,
                    rowsPerPage: 1000,
                    vehicleTypeId: selected?.vehicleType?.value ? selected?.vehicleType.value : 0,
                  });
                }}
              />
            </div>
          </div>
        </div>
        {/* data table */}
        {this.state.report_type === '' ? <p className='p-3'>សូមជ្រើសរើសរបាយការណ៍</p> : (
          this.state.report_type.value < 2 ? (
            <DataTable
              fixedHeader
              noDataComponent={<span style={{ margin: '20px 0px' }}>គ្មានទិន្នន័យ</span>}
              fixedHeaderScrollHeight="calc(100vh - 300px)"
              persistTableHead
              noHeader
              pointerOnHover
              data={this.state.records ? this.state.records : []}
              columns={this.state.dataTableColumns}
              sortIcon={<SortIcon />}
              customStyles={reportTableStyle}
              conditionalRowStyles={conditionalRowStyles}
              progressPending={this.state.isRequesting}
              progressComponent={<Loading />}
              sortFunction={customSort}
            />
          ) : (
            <ReportLayout
              dateFrom={this.state.dateFrom ? Moment(this.state.dateFrom).format('DD-MM-YYYY').split('-') : []}
              dateTo={this.state.dateTo ? Moment(this.state.dateTo).format('DD-MM-YYYY').split('-') : []}
              vehicle={this.state.vehicle?.label}
              vehicleType={this.state.vehicleType?.label}
              reportType={this.state.report_type}
              reports={this.state.records}
              show={this.state.showReport}
              onCancel={(e) => this.setState({ showReport: false })}
              ref={(el) => (this.componentRef = el)}
            />
          )
        )}
        <div style={{ display: 'none' }}>
          {this.state.report_type.value === 0 ? (
            <PrintReport ref={(el) => (this.componentRef = el)} data={this.state.records} columns={this.state.dataTableColumns} />
          ) : this.state.report_type.value === 1 ? (
            <PrintSummaryReport ref={(el) => (this.componentRef = el)} data={this.state.records} columns={this.state.dataTableColumns} />
          ) : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  vehicleTypes: state.VehicleType.vehicleTypes,
  vehicles: state.Vehicle.vehicles,
  allbranches: state.Branch.allbranches,
  actionName: state.Navs.actionName,
  permissionModuleList: state.Layout.permissionModuleList,
  summaryReports: state.Report.summaryReports,
  reports: state.Report.reports,
});

export default connect(
  mapStateToProps, {
  ChangeSidebar, fetch_vehicle_type, fetch_vehicle, fetch_allbranch, fetch_report,
  fetch_summaryReport, void_report, fetch_vehicleRepairReport, fetch_changeTireReport,
  fetch_changeOilReport, fetch_accidentReport
})(index);

const conditionalRowStyles = [
  {
    when: (row) => row.status === 1,
    style: {
      backgroundColor: 'RGBA(235,154,86, 0.75)',
    },
  },
];

export class PrintReport extends Component {
  render() {
    let { columns, data } = this.props;
    return (
      <Table className="ut-print-report">
        <thead>
          <tr>
            {columns.map(({ name }, i) => (
              <th key={i}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((report, i) => (
            <tr key={i}>
              <td>{report.no}</td>
              <td>{report.vehicle.label}</td>
              <td>{report.vehicleType.label}</td>
              <td>{report.origin.label}</td>
              <td>{report.passingProvince}</td>
              <td>{report.destination.label}</td>
              <td>{report.start}</td>
              <td>{report.end}</td>
              <td>{report.duration}</td>
              <td>{report.remark}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export class PrintSummaryReport extends Component {
  render() {
    let { columns, data } = this.props;
    return (
      <Table className="ut-print-report">
        <thead>
          <tr>
            {columns.map(({ name }) => (
              <th key={name}>{name}</th>
            ))}
            <th>ហត្ថលេខា</th>
          </tr>
        </thead>
        <tbody>
          {data.map((report) => (
            <tr>
              <td>{report.no}</td>
              <td>{report.vehicle.label}</td>
              <td>{report.vehicleType.label}</td>
              <td>{report.numberOfTrip}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}
