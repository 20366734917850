import React from "react";
export default function LogoutDropDown(props) {
	return (
		<div className="ut-option-overlay" hidden={props.hidden} onClick={props.ToggleOverlay}>
			<div className="ut-dropdown-wrapper" onClick={props.onLogout}>
				Logout
			</div>
		</div>
	);
}
