import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Label, Select, TextArea } from "../../../components/UTControls";
import { successDesc } from "../../../constants/dictionary";
import { GetPath, ResetRecords, handleData } from "../../../constants/handleData";
import { translation } from "../../../constants/translate";
import ActionBar from "../../../containers/ActionBar";
import Confirmation from "../../../containers/modals/Confirmation";
import { isEmptyOrWhiteSpace, titlePrefix } from "../../../helpers";
import { fetch_allbranch, fetch_branch } from "../../../redux/Branch/actions";
import { add_departure } from "../../../redux/Tracking/actions";
import { fetch_vehicle } from "../../../redux/Vehicle/actions";
import { fetch_vehicle_type } from "../../../redux/VehicleType/actions";

class index extends Component {
	state = {
		origin: "",
		passing: "",
		destination: "",
		vehicleType: "",
		vehicle: "",
		remark: "",
		vehicleList: [],
		isSummited: false,
		isRequesting: false,
		isShowConfirmation: false,
		titlePrefix: titlePrefix(this.props.match.url),
		currentUrl: "/app/tracking",
	};

	componentDidMount() {
		GetPath("Tracking", "tracking/")
		this.props.fetch_branch({ page: 1, rowsPerPage: 1000 });
		this.props.fetch_allbranch({ page: 1, rowsPerPage: 1000 });
		this.props.fetch_vehicle({ page: 1, rowsPerPage: 1000 });
		this.props.fetch_vehicle_type({ page: 1, rowsPerPage: 1000 });
		// is data is already input
		if (Object.keys(handleData.Tracking.data).length !== 0) {
			const data = handleData.Tracking.data;
			this.setState({
				origin: data.origin,
				passing: data.passing,
				destination: data.destination,
				vehicleType: data.vehicleType,
				vehicle: data.vehicle,
				remark: data.remark,
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.vehicles !== prevProps.vehicles) {
			this.setState({ vehicleList: this.props.vehicles });
		}
		if (this.state.vehicleType !== prevState.vehicleType) {
			if (isEmptyOrWhiteSpace(this.state.vehicleType)) {
				let tempVehicles = this.props.vehicles.filter((vehicle) => vehicle.vehicleType.value === this.state.vehicleType.value);
				this.setState({ vehicleList: tempVehicles });
			} else this.setState({ vehicleList: this.props.vehicles });
		}
	}
	// use for handle data while leave page
	componentWillUnmount() {
		handleData.Tracking = {
			...handleData.Tracking,
			data: {
				origin: this.state.origin,
				passing: this.state.passing,
				destination: this.state.destination,
				vehicleType: this.state.vehicleType,
				vehicle: this.state.vehicle,
				remark: this.state.remark,
			}
		};
	}
	resetForm() {
		this.setState({
			origin: "",
			passing: "",
			destination: "",
			vehicleType: "",
			vehicle: "",
			remark: "",
			vehicleList: [],
			isSummited: false,
			isRequesting: false,
			isShowConfirmation: false,
		});
	}

	gatherData() {
		if (isEmptyOrWhiteSpace(this.state.origin, this.state.destination, this.state.vehicleType, this.state.vehicle)) {
			let vehicleDestinations = [];
			if (this.state.passing.length) vehicleDestinations = this.state.passing.map((branch) => ({ branchToId: branch.value }));
			let dataCollection = {
				branchId: this.state.origin.value,
				vehicleTypeId: this.state.vehicleType.value,
				vehicleId: this.state.vehicle.value,
				vehicleDestinations,
				branchToBId: this.state.destination.value,
				remarkStart: this.state.remark,
			};
			return dataCollection;
		} else return null;
	}

	handleBack = () => {
		handleData.Tracking = {
			...handleData.Tracking, path: "list"
		}
		this.setState({ isShowConfirmation: false });
		setTimeout(() => {
			this.props.history.push(`${this.state.currentUrl}`);
		}, 100);
	};

	handleSave = () => {
		let tracking = this.gatherData();
		this.setState({ isSummited: true });
		if (tracking) {
			this.setState({ isRequesting: true });
			this.setState({ isRequesting: true });
			this.props.add_departure(tracking, (acknowledge, message) => {
				this.setState({ isRequesting: false });
				if (acknowledge) {
					this.resetForm();
					ResetRecords("Tracking")
					this.setState({ isShowConfirmation: true });
				} else alert(message);
			});
		}
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		return (
			<div>
				<Confirmation handleClose={this.handleBack} isShow={this.state.isShowConfirmation} confirmType="SUCCESS" desc={successDesc} />
				<ActionBar title={`${this.state.titlePrefix} Tracking`}>
					<Button name={translation["BACK"]} color="secondary" size="md" buttonStyle="filled" onClick={this.handleBack} />
				</ActionBar>
				<div className="d-flex w-100">
					<div style={{ width: 400 }}>
						<div>
							<Label title={translation["Origin"]} required />
							<Select
								onChange={(selected) => this.setState({ origin: selected })}
								value={this.state.origin}
								options={this.props.branches}
								placeholder={translation["Origin"]}
							/>
							<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.origin))}>Origin is required</small>
						</div>
						<div>
							<Label title={translation["Passing"]} />
							<Select
								onChange={(selected) => this.setState({ passing: selected })}
								isMulti
								value={this.state.passing}
								options={this.props.allbranches}
								placeholder={translation["Passing"]}
							/>
						</div>
						<div>
							<Label title={translation["Destination"]} required />
							<Select
								onChange={(selected) => this.setState({ destination: selected })}
								value={this.state.destination}
								options={this.props.allbranches}
								placeholder={translation["Destination"]}
							/>
							<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.destination))}>Destination is required</small>
						</div>
						<div>
							<Label title={translation["Vehicle Type"]} required />
							<Select
								isClearable
								onChange={(selected) =>
									this.state.vehicle.vehicleType !== selected &&
									this.setState({
										vehicleType: selected,
										vehicleList: selected
											? this.props.vehicles.filter((vehicle) => vehicle.vehicleType === selected)
											: this.props.vehicles,
										vehicle: "",
									})
								}
								value={this.state.vehicleType}
								options={this.props.vehicleTypes}
								placeholder={translation["Vehicle Type"]}
							/>
							<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.vehicleType))}>Vehicle Type is required</small>
						</div>
						<div>
							<Label title={translation["Vehicles"]} required />
							<Select
								onChange={(selected) => this.setState({ vehicle: selected, vehicleType: selected.vehicleType })}
								value={this.state.vehicle}
								options={this.state.vehicleList}
								placeholder={translation["Vehicles"]}
							/>
							<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.vehicle))}>Vehicle is required</small>
							<br />
						</div>
						{this.state.titlePrefix !== "View" && (
							<Button
								name={
									this.state.id
										? this.state.isRequesting
											? translation["UPDATING"]
											: translation["UPDATE"]
										: this.state.isRequesting
											? translation["SAVING"]
											: translation["SAVE"]
								}
								color={this.state.id ? "warning" : "success"}
								size="md"
								buttonStyle="filled"
								onClick={this.handleSave}
							/>
						)}
					</div>
					<div style={{ width: 400 }}>
						<div>
							<Label title={translation["Remark"]} />
							<TextArea value={this.state.remark} onChange={this.onChange} name="remark" size="md" blocked />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	branches: state.Branch.branches,
	allbranches: state.Branch.allbranches,
	vehicles: state.Vehicle.vehicles,
	vehicleTypes: state.VehicleType.vehicleTypes,
});

export default connect(mapStateToProps, { add_departure, fetch_branch, fetch_allbranch, fetch_vehicle, fetch_vehicle_type })(index);
