import Moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input, Label, LabelDatePicker, LabelSelect } from "../../../components/UTControls";
import { successDesc } from "../../../constants/dictionary";
import { GetPath, ResetRecords, handleData } from "../../../constants/handleData";
import { translation } from "../../../constants/translate";
import ActionBar from "../../../containers/ActionBar";
import Confirmation from "../../../containers/modals/Confirmation";
import { isEmptyOrWhiteSpace, titlePrefix } from "../../../helpers";
import { add_change_oil, find_change_oil, update_change_oil } from "../../../redux/ChangeOil/actions";

class index extends Component {
	state = {
		id: this.props.match.params?.id,
		date: new Date(),
		vehicle: "",
		oilAmount: 0,
		situation: { value: 1, situation: "ប្ដូរ" },
		isSummited: false,
		isRequesting: false,
		isShowConfirmation: false,
		titlePrefix: titlePrefix(this.props.match.url),
		currentUrl: "/app/change-oil/list",
	};
	componentDidMount() {
		GetPath("ChangeOil", "change-oil/")
		if (this.state.id) {
			if (this.props.changeOil.value) {
				this.setState({
					date: this.props.changeOil.date ? new Date(this.props.changeOil.date.split("-").reverse().join("-")) : "",
					vehicle: this.props.changeOil.vehicle,
					oilAmount: this.props.changeOil.oilAmount,
					situation: this.props.changeOil.situation,
				});
			} else {
				this.props.find_change_oil({ id: this.state.id });
			}
		}
		// is data is already input
		if (Object.keys(handleData.ChangeOil.data).length !== 0) {
			const data = handleData.ChangeOil.data;
			this.setState({
				date: data.date ? new Date(data.date) : '',
				oilAmount: data.amount,
				vehicle: data.vehicle,
				situation: { ...this.state.situation, value: data.status }
			});
		}
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.changeOil !== this.props.changeOil) {
			this.setState({
				date: this.props.changeOil.date ? new Date(this.props.changeOil.date.split("-").reverse().join("-")) : "",
				vehicle: this.props.changeOil.vehicle,
				oilAmount: this.props.changeOil.oilAmount,
				situation: this.props.changeOil.situation,
			});
		}
	}
	// use for handle data while leave page
	componentWillUnmount() {
		handleData.ChangeOil = {
			...handleData.ChangeOil,
			data: {
				date: Moment(this.state.date).format("YYYY-MM-DD"),
				vehicle: { value: this.state.vehicle.value, label: this.state.vehicle.label },
				amount: this.state.oilAmount,
				status: parseInt(this.state.situation.value),
			}
		};
	}

	resetForm() {
		this.setState({
			id: null,
			date: new Date(),
			vehicleName: "",
			oilAmount: 0,
			situation: { value: 1, situation: "ប្ដូរ" },
			isSummited: false,
			isRequesting: false,
			isShowConfirmation: false,
		});
	}
	gatherData() {
		if (isEmptyOrWhiteSpace(this.state.date, this.state.vehicle, this.state.oilAmount, this.state.situation)) {
			let dataCollection = {
				date: Moment(this.state.date).format("YYYY-MM-DD"),
				vehicleId: this.state.vehicle.value,
				amount: this.state.oilAmount,
				status: parseInt(this.state.situation.value),
			};
			return dataCollection;
		} else return null;
	}
	handleBack = () => {
		this.setState({ isShowConfirmation: false });
		setTimeout(() => {
			this.props.history.push(`${this.state.currentUrl}`);
		}, 100);
	};

	handleSave = () => {
		let changeOil = this.gatherData();
		this.setState({ isSummited: true });

		if (changeOil) {
			this.setState({ isRequesting: true });
			if (this.state.id) {
				changeOil.id = this.state.id;
				this.props.update_change_oil(changeOil, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("ChangeOil")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			} else {
				this.setState({ isRequesting: true });
				this.props.add_change_oil(changeOil, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("ChangeOil")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			}
		}
	};

	onChange = (e) => { this.setState({ [e.target.name]: e.target.value }) };

	render() {
		return (
			<div>
				<Confirmation handleClose={this.handleBack} isShow={this.state.isShowConfirmation} confirmType="SUCCESS" desc={successDesc} />
				<ActionBar title={`${this.state.titlePrefix} Change Oil`}>
					<Button name={translation["BACK"]} color="secondary" size="md" buttonStyle="filled" onClick={this.handleBack} />
				</ActionBar>
				<div className="ut-d-flex">
					<div style={{ width: 600 }}>
						<div className="d-flex">
							<div style={{ flex: 2 }}>
								<Label title={translation["Date"]} required />
								<LabelDatePicker
									styles="ut-custom-label-datePicker-blocked"
									disabled={this.state.titlePrefix === "View"}
									placeholder={translation["Date"]}
									onChange={(date) => this.setState({ date })}
									selected={this.state.date}
								/>
								<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.date))}>Date is required</small>
							</div>
							<div style={{ flex: 1 }} />
						</div>
						<div className="d-flex">
							<div style={{ flex: 2 }}>
								<Label title={translation["Vehicles"]} required />
								<LabelSelect
									disabled
									options={this.props.vehicles}
									name="vehicle"
									placeholder={translation["Vehicles"]}
									onChange={(selected) => this.setState({ vehicle: selected })}
									value={this.state.vehicle}
								/>
								<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.vehicle))}>Vehicle name is required</small>
							</div>
							<div style={{ flex: 1 }} />
						</div>
						<div className="d-flex">
							<div style={{ flex: 2 }}>
								<Label title={translation["Oil Amount"]} required />
								<Input
									type="number"
									disabled={this.state.titlePrefix === "View"}
									name="oilAmount"
									placeholder={translation["Oil Amount"]}
									onBlur={(e) => this.setState({ oilAmount: e.target.value ? e.target.value : 0 })}
									onChange={this.onChange}
									value={this.state.oilAmount}
								/>
								<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.oilAmount))}>Oil Amount is required</small>
							</div>
							<div style={{ flex: 1 }}>
								<Label title="ស្ថានភាព" required />
								<div className="d-flex align-items-center">
									<div
										className="ut-input ut-input-rounded ut-input-md ut-color-light"
										style={{ display: "flex", flex: 2, justifyContent: "space-between", width: 100 }}
									>
										<div className="ut-radio-option"
											onClick={() => {
												if (this.state.titlePrefix !== "View") {
													this.setState({ situation: { ...this.state.situation, value: "1" } });
												}
											}}
										>
											<input
												disabled={this.state.titlePrefix === "View"}
												onChange={(e) => this.setState({ situation: { value: e.target.value, label: "ប្ដូរ" } })}
												value={1}
												name="situation"
												type="radio"
												id="change"
												// eslint-disable-next-line
												checked={this.state.situation.value == 1}
											/>
											<label disabled={this.state.titlePrefix === "View"} htmlFor="repair">ប្ដូរ</label>
										</div>
										<div className="ut-radio-option"
											onClick={() => {
												if (this.state.titlePrefix !== "View") {
													this.setState({ situation: { ...this.state.situation, value: "2" } });
												}
											}}
										>
											<input
												disabled={this.state.titlePrefix === "View"}
												onChange={(e) => this.setState({ situation: { value: e.target.value, label: "ថែម" } })}
												value={2}
												name="situation"
												type="radio"
												id="addup"
												// eslint-disable-next-line
												checked={this.state.situation.value == 2}
											/>
											<label disabled={this.state.titlePrefix === "View"} htmlFor="replace">ថែម</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{this.state.titlePrefix !== "View" ? (
					<Button
						name={
							this.state.id
								? this.state.isRequesting
									? translation["UPDATING"]
									: translation["UPDATE"]
								: this.state.isRequesting
									? translation["SAVING"]
									: translation["SAVE"]
						}
						color={this.state.id ? "warning" : "success"}
						size="md"
						buttonStyle="filled"
						onClick={this.handleSave}
					/>
				) : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	changeOil: state.ChangeOil.changeOil,
	vehicles: state.Vehicle.vehicles,
});

export default connect(mapStateToProps, { add_change_oil, update_change_oil, find_change_oil })(index);
