import { baseUrl, headersToken, headers, enableContext } from "./secret";
import { UPDATE_USER_INFO } from "../redux/Layouts/types";
import { generateCookiesName, getDeviceInfo } from "./generator";
import { APP_NAME } from "../constants/app";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import store from "../store";

// const web_secure = process.env.REACT_APP_NODE_ENV === "Production";
const web_secure = false;
const token_expired = 90;

export const cookiesKey = generateCookiesName();

export const login = (username: string, password: string) => {
	return new Promise(function (resolve, reject) {
		let deviceInfo = getDeviceInfo();
		fetch(`${baseUrl}/auth/login?username=${username}&password=${password}&deviceName=${deviceInfo.deviceName}&deviceId=${deviceInfo.deviceId}`, {
			method: "POST",
			headers: headers(),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.header.statusCode === 200 && data.header.result) {
					Cookies.set(cookiesKey.deviceId, CryptoJS.AES.encrypt(deviceInfo.deviceId, APP_NAME).toString(), { path: enableContext ? `/${APP_NAME}` : "/", expires: token_expired, secure: web_secure });
					Cookies.set(cookiesKey.deviceName, CryptoJS.AES.encrypt(deviceInfo.deviceName, APP_NAME).toString(), { path: enableContext ? `/${APP_NAME}` : "/", expires: token_expired, secure: web_secure });
					Cookies.set(cookiesKey.accessToken, CryptoJS.AES.encrypt(data.body.accessToken, APP_NAME).toString(), { path: enableContext ? `/${APP_NAME}` : "/", expires: token_expired, secure: web_secure });
					Cookies.set(cookiesKey.refreshToken, CryptoJS.AES.encrypt(data.body.refreshToken, APP_NAME).toString(), { path: enableContext ? `/${APP_NAME}` : "/", expires: token_expired, secure: web_secure });
				}
				resolve({ status: data.header.result, msg: data.header.errorText });
			})
			.catch((err) => {
				console.error("[Login Helper]", err);
				reject(err);
			});
	});
};

export const logout = () => {
	let deviceId = CryptoJS.AES.decrypt(Cookies.get(cookiesKey.deviceId)!, APP_NAME).toString(CryptoJS.enc.Utf8);

	fetch(`${baseUrl}/auth/logout?deviceId=${deviceId}`, {
		method: "POST",
		headers: headersToken(),
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.header.statusCode === 200 || data.header.statusCode === 401 || data.header.statusCode === 403) {
				Cookies.remove(cookiesKey.deviceId, { path: enableContext ? `/${APP_NAME}` : "/" });
				Cookies.remove(cookiesKey.deviceName, { path: enableContext ? `/${APP_NAME}` : "/" });
				Cookies.remove(cookiesKey.accessToken, { path: enableContext ? `/${APP_NAME}` : "/" });
				Cookies.remove(cookiesKey.refreshToken, { path: enableContext ? `/${APP_NAME}` : "/" });
				window.location.pathname = enableContext ? `/${APP_NAME}` : "/";
			}
		})
		.catch((err) => {
			console.error("[Logout Helper]", err);
		});
};

export function authfetch(url: string, options: any) {
	return new Promise(function (resolve, reject) {
		fetch(url, options)
			.then((response) => response.json())
			.then((data) => {
				if (data.header.statusCode === 200 && data.header.result) {
					resolve(data);
				} else if (data.header.statusCode === 401 || data.header.statusCode === 403) {
					refreshToken().then((data: any) => {
						let newOptions = {
							...options,
							headers: {
								...options.headers,
								Authorization: `bearer ${data.accessToken}`,
							},
						};
						fetch(url, newOptions)
							.then((response) => response.json())
							.then((data) => {
								if (data.header.statusCode === 200) {
									resolve(data);
								} else if (data.header.statusCode === 401 || data.header.statusCode === 403) {
									alert("Session has been expired. Please login again");
									logout();
								}
							});
					});
				} else {
					reject("unknow error code.");
				}
			})
			.catch((err) => {
				console.error("[AuthFetch Helper]", err);
				reject(err);
			});
	});
}

export const getUserInfo = () => {
	authfetch(`${baseUrl}/user/me`, {
		method: "POST",
		headers: headersToken(),
	})
		.then((data: any) => {
			store.dispatch({
				type: UPDATE_USER_INFO,
				payload: {
					userId: data.body?.data[0]?.id,
					username: data.body?.data[0]?.fullName,
					avatar: data.body?.data[0]?.photo,
				},
			});
		})
		.catch((err) => {
			console.error("[GetInfo Helper]", err);
		});
};

export const checkToken = () => {
	return new Promise(function (resolve, reject) {
		fetch(`${baseUrl}/auth/checkToken`, {
			method: "POST",
			headers: headersToken(),
		})
			.then((response) => response.json())
			.then((data) => {
				resolve(data.header.result);
			})
			.catch((err) => {
				console.error("[CheckToken Helper]", err);
				reject(err);
			});
	});
};

export const refreshToken = () => {
	return new Promise(function (resolve, reject) {
		let refreshToken = CryptoJS.AES.decrypt(Cookies.get(cookiesKey.refreshToken)!, APP_NAME).toString(CryptoJS.enc.Utf8),
			deviceId = CryptoJS.AES.decrypt(Cookies.get(cookiesKey.deviceId)!, APP_NAME).toString(CryptoJS.enc.Utf8);

		fetch(`${baseUrl}/auth/login-with-refresh-token?refreshToken=${refreshToken}&deviceId=${deviceId}`, {
			method: "POST",
			headers: headers(),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.header.statusCode === 200) {
					let { accessToken, refreshToken } = data.body;

					Cookies.set(cookiesKey.accessToken, CryptoJS.AES.encrypt(data.body.accessToken, APP_NAME).toString(), { expires: token_expired, secure: web_secure });
					Cookies.set(cookiesKey.refreshToken, CryptoJS.AES.encrypt(data.body.refreshToken, APP_NAME).toString(), { expires: token_expired, secure: web_secure });
					resolve({ accessToken, refreshToken });
				} else if (data.header.statusCode === 401 || data.header.statusCode === 403) {
					alert("Session has been expired. Please login again");
					logout();
				}
			})
			.catch((err) => {
				console.error("[RefreshToken Helper]", err);
				reject(err);
			});
	});
};
