import { ADD_CHANGE_OIL, UPDATE_CHANGE_OIL, DEL_CHANGE_OIL, FIND_CHANGE_OIL, GET_CHANGE_OIL } from "./types";
import { baseUrl, headersJsonToken, headersToken } from "../../auth/secret";
import { authfetch } from "../../auth";

import Moment from "moment";
import { dateOption } from "../../constants/variables";

export const add_change_oil = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-engine-oil/add`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: ADD_CHANGE_OIL,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const update_change_oil = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-engine-oil/update`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: UPDATE_CHANGE_OIL,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const del_change_oil = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-engine-oil/delete/${payload}`, {
		method: "POST",
		headers: headersToken(),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: DEL_CHANGE_OIL,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const fetch_change_oil = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-engine-oil/list`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			let changeOils = [];
			changeOils = data.body.data.map((changeOil, i) => {
				return {
					no: i + 1,
					value: changeOil.id,
					vehicle: { value: changeOil.vehicleId, label: changeOil.vehicleName },
					oilAmount: changeOil.amount,
					situation: { value: changeOil.status, label: changeOil.status === 1 ? "ប្ដូរ" : "ថែម" },
					date: changeOil.date ? Moment(changeOil.date).format(dateOption) : "",
					createdDate: Moment(changeOil.created).format(dateOption),
				};
			});

			dispatch({
				type: GET_CHANGE_OIL,
				payload: { changeOils },
			});
		})
		.catch((error) => {
			console.error(error);

			dispatch({
				type: GET_CHANGE_OIL,
				payload: { changeOils: [] },
			});
		});
};

export const find_change_oil = (payload) => (dispatch) => {
	if (payload.changeOil) {
		dispatch({
			type: FIND_CHANGE_OIL,
			payload: { changeOil: payload.changeOil },
		});
	} else {
		authfetch(`${baseUrl}/vehicle-engine-oil/find/${payload.id}`, {
			method: "POST",
			headers: headersToken(),
		})
			.then((data) => {
				let changeOil = [];
				changeOil = data.body.data.map((changeOil, i) => {
					return {
						no: i + 1,
						value: changeOil.id,
						vehicle: { value: changeOil.vehicleId, label: changeOil.vehicleName },
						oilAmount: changeOil.amount,
						situation: { value: changeOil.status, label: changeOil.status === 0 ? "ប្ដូរ" : "ថែម" },
						date: changeOil.date ? Moment(changeOil.date).format(dateOption) : "",
						createdDate: Moment(changeOil.created).format(dateOption),
					};
				});

				dispatch({
					type: FIND_CHANGE_OIL,
					payload: { changeOil: changeOil.length > 0 ? changeOil[0] : {} },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: FIND_CHANGE_OIL,
					payload: { changeOil: {} },
				});
			});
	}
};
