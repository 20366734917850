import { ADD_DEPARTURE, GET_TRACKING, UPDATE_ARRIVAL, UPDATE_PASSING_STOP, UPDATE_PASSING_START, UPDATE_DESTINATION, CANCEL_TRACKING } from "./types";
import { baseUrl, headersJsonToken, headersToken } from "../../auth/secret";
import { authfetch } from "../../auth";
import { durationFormat } from "../../helpers";

import Moment from "moment";

export const add_departure = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/new-departure/new-parture-add`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: ADD_DEPARTURE,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const fetch_tracking = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/tracking/listDepartureForWeb`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			let trackings = [];
			trackings = data.body.data.map((track, i) => {
				let duration = track.stopDate ? parseInt(new Moment(track.stopDate).diff(Moment(track.created)) / (1000 * 60)) : "-";
				let passing = [],
					passingText;

				passing = track.vehicleDestinationResponses.map((pass, i) => {
					if (i === 0) {
						passingText = pass.branchToName;
					} else passingText = `${passingText}, ${pass.branchToName}`;

					return {
						branchId: pass.branchToId,
						value: pass.id,
						label: pass.branchToName,
						startDate: pass.startDate ? Moment(pass.startDate).format("DD-MM-YYYY hh:mm A") : "-",
						stopDate: pass.stopDate ? Moment(pass.stopDate).format("DD-MM-YYYY hh:mm A") : "-",
						state: pass.startDate && pass.stopDate ? 2 : pass.stopDate ? 1 : 0,
					};
				});

				return {
					no: i + 1,
					value: track.id,
					vehicle: { value: track.vehicleId, label: track.vehicleName },
					vehicleType: { value: track.vehicleTypeId, label: track.vehicleTypeName },
					origin: { value: track.branchId, label: track.branchName },
					destination: { value: track.branchToBId, label: track.branchToBName },
					passingText,
					passing,
					start: `${Moment(track.created).format("DD-MM-YYYY hh:mm A")}`,
					end: track.stopDate ? `${Moment(track.stopDate).format("DD-MM-YYYY hh:mm A")}` : "-",
					duration: durationFormat(duration),
					remark: track.remarkStart,
				};
			});

			dispatch({
				type: GET_TRACKING,
				payload: { trackings },
			});
		})
		.catch((error) => {
			console.error(error);
			dispatch({
				type: GET_TRACKING,
				payload: { trackings: [] },
			});
		});
};

export const update_arrival = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/arrival/arrival-add`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: UPDATE_ARRIVAL,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const update_passing_stop = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/arrival/arrival-destination-stop`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: UPDATE_PASSING_STOP,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const update_passing_start = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/arrival/arrival-destination-start`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: UPDATE_PASSING_START,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const update_passing_destination = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/tracking/destination-update`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: UPDATE_DESTINATION,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const cancel_tracking = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/tracking/cancel/${payload.id}`, {
		method: "POST",
		headers: headersToken(),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: CANCEL_TRACKING,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};
