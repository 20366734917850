import React from "react";

export default function ControlHeader(props) {
	return (
		<label className="ut-label" hidden={props.hidden}>
			{props.title}
			{props.required ? <code> *</code> : ""}
		</label>
	);
}
