import { GET_MODULETYPE } from "./types";

const initialState = {
	moduleTypes: [],
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case GET_MODULETYPE: {
			state.moduleTypes = actions.payload.moduleTypes;
			return { ...state };
		}
		default:
			return { ...state };
	}
};
