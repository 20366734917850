import React, { useEffect, useState } from "react";

export function VehicleRepair({ reports }) {
	const [changeReport, setChangeReport] = useState([]);
	const [repairReport, setRepairReport] = useState([]);
	const [totalChange, setTotalChange] = useState(0);
	const [totalRepair, setTotalRepair] = useState(0);
	const [vehicleCount, setVehicleCount] = useState(0);
	useEffect(() => {
		// reset
		setChangeReport([]);
		setRepairReport([]);
		setTotalChange(0);
		setTotalRepair(0);
		// Process each report
		reports.forEach((data, i) => {
			if (data.status === 2) {
				setChangeReport((prevChangeReport) => [...prevChangeReport, ...data.reportData]);
			} else if (data.status === 1) {
				setRepairReport((prevRepairReport) => [...prevRepairReport, ...data.reportData]);
			}
		});
	}, [reports]);

	useEffect(() => {
		let changeTotal = 0;
		let fixTotal = 0;
		let vehicleNames = [];

		changeReport.forEach((report) => {
			changeTotal += report.amount || 0;
			vehicleNames.push(report.vehicleName)
		});
		repairReport.forEach((report) => {
			fixTotal += report.amount || 0;
			vehicleNames.push(report.vehicleName)
		});
		const totalVehicle = (arr) => {
			const uniqueValues = new Set(arr);
			return uniqueValues.size;
		};
		setTotalChange(changeTotal);
		setTotalRepair(fixTotal);
		setVehicleCount(totalVehicle(vehicleNames));
	}, [changeReport, repairReport]);

	return (
		<div>
			<div className="d-flex justify-content-center align-items-center">
				<p className="mr-5">ចំនួនឡាន៖ <span style={{ fontFamily: "Koh_Santepheap Bold" }}>{vehicleCount}</span>គ្រឿង</p>
				<p className="mr-5">ទឹកប្រាក់ជួសជុល៖ <span style={{ fontFamily: "Koh_Santepheap Bold" }}>{totalRepair.toFixed(2)}</span>$</p>
				<p className="mr-5">ទឹកប្រាក់ផ្លាស់ប្ដូរ៖ <span style={{ fontFamily: "Koh_Santepheap Bold" }}>{totalChange.toFixed(2)}</span>$</p>
				<p>សរុបទឹកប្រាក់៖ <span style={{ fontFamily: "Koh_Santepheap Bold" }}>{(totalChange + totalRepair).toFixed(2)}</span>$</p>
			</div>
			<p className="text-center" style={{ fontFamily: "Koh_Santepheap Bold" }}>របាយការណ៍ការជួសជុល</p>
			<table>
				<thead>
					<tr>
						<td>កាលបរិច្ឆេទ</td>
						<td>ប្រភេទឡាន</td>
						<td>លេខឡាន</td>
						<td>ឆ្នាំផលិត</td>
						<td>ឡានថ្មី ឬមួយទឹក</td>
						<td>គ្រឿងបន្លាស់សំខាន់ៗដែលបានប្ដូរ(ជួសជុល)</td>
						<td>បរិយាយអំពីឡានខូច</td>
						<td>សរុបទឹកប្រាក់</td>
					</tr>
				</thead>
				<tbody>
					{repairReport?.map((response, j) => (
						<tr key={j}>
							<td style={{ textAlign: "center" }}>{response.date}</td>
							<td style={{ textAlign: "center" }}>{response.vehicleTypeName}</td>
							<td style={{ textAlign: "center" }}>{response.vehicleName}</td>
							<td style={{ textAlign: "center" }}>{response.yearMade}</td>
							<td style={{ textAlign: "center" }}>{response.type}</td>
							<td>
								<ul>
									{response.sparepartsNote?.split(", ").map((sparepart) => (
										<li>{sparepart}</li>
									))}
								</ul>
							</td>
							<td>
								<ul>
									{response.description?.map((desc, k) => (
										<li key={k}>{desc}</li>
									))}
								</ul>
							</td>
							<td style={{ textAlign: "center" }}>{response.amount}$</td>
						</tr>
					))}
					<tr>
						<td style={{ border: "0px" }} colSpan={6}></td>
						<td style={{ textAlign: "center", fontFamily: "Koh_Santepheap Bold" }}>TOTAL</td>
						<td style={{ textAlign: "center", fontFamily: "Koh_Santepheap Bold" }}>{reports.length ? `${totalRepair.toFixed(2)} $` : "0 $"}</td>
					</tr>
				</tbody>
			</table>
			<p className="text-center" style={{ fontFamily: "Koh_Santepheap Bold" }}>របាយការណ៍ការផ្លាស់ប្ដូរ</p>
			<table>
				<thead>
					<tr>
						<td>កាលបរិច្ឆេទ</td>
						<td>ប្រភេទឡាន</td>
						<td>លេខឡាន</td>
						<td>ឆ្នាំផលិត</td>
						<td>ឡានថ្មី ឬមួយទឹក</td>
						<td>គ្រឿងបន្លាស់សំខាន់ៗដែលបានប្ដូរ(ផ្លាស់ប្ដូរ)</td>
						<td>បរិយាយអំពីឡានខូច</td>
						<td>សរុបទឹកប្រាក់</td>
					</tr>
				</thead>
				<tbody>
					{changeReport?.map((response, j) => (
						<tr key={j}>
							<td style={{ textAlign: "center" }}>{response.date}</td>
							<td style={{ textAlign: "center" }}>{response.vehicleTypeName}</td>
							<td style={{ textAlign: "center" }}>{response.vehicleName}</td>
							<td style={{ textAlign: "center" }}>{response.yearMade}</td>
							<td style={{ textAlign: "center" }}>{response.type}</td>
							<td>
								<ul>
									{response.sparepartsNote?.split(", ").map((sparepart) => (
										<li>{sparepart}</li>
									))}
								</ul>
							</td>
							<td>
								<ul>
									{response.description?.map((desc, k) => (
										<li key={k}>{desc}</li>
									))}
								</ul>
							</td>
							<td style={{ textAlign: "center" }}>{response.amount}$</td>
						</tr>
					))}
					<tr>
						<td style={{ border: "0px" }} colSpan={6}></td>
						<td style={{ textAlign: "center", fontFamily: "Koh_Santepheap Bold" }}>TOTAL</td>
						<td style={{ textAlign: "center", fontFamily: "Koh_Santepheap Bold" }}>{reports.length ? `${totalChange.toFixed(2)} $` : "0 $"}</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export function ChangeTire({ reports }) {
	return (
		<table>
			<thead>
				<tr>
					<td>កាលបរិច្ឆេទ</td>
					<td>ប្រភេទឡាន</td>
					<td>លេខឡាន</td>
					<td>លេខកូដកង់ចាស់</td>
					<td>លេខកូដកង់បានប្ដូរហើយ</td>
					<td>លេខកូដកង់សាគួរចាស់</td>
					<td>លេខកូដកង់សាគួរថ្មី</td>
					<td>ចំនួនកង់បានប្ដូរ</td>
					<td>កំណត់សំគាល់</td>
				</tr>
			</thead>
			<tbody>
				{reports.map((report, i) => (
					<tr key={i}>
						<td style={{ textAlign: "center" }}>{report.date}</td>
						<td style={{ textAlign: "center" }}>{report.vehicleType?.label}</td>
						<td style={{ textAlign: "center" }}>{report.vehicle?.label}</td>
						<td>
							<ol>
								{report.vehicleTires?.map((tire, i) => (
									<li key={i} style={tire.oldSerial !== tire.newSerial ? { fontWeight: "bold", color: "#342E7E" } : {}}>
										{tire.oldSerial}
									</li>
								))}
							</ol>
						</td>
						<td>
							<ol>
								{report.vehicleTires?.map((tire, i) => (
									<li key={i} style={tire.oldSerial !== tire.newSerial ? { fontWeight: "bold", color: "#342E7E" } : {}}>
										{tire.newSerial}
									</li>
								))}
							</ol>
						</td>
						<td>
							<ol>
								{report.vehicleTireReplace?.map((tire, i) => (
									<li key={i} style={tire.oldSerial !== tire.newSerial ? { fontWeight: "bold", color: "#342E7E" } : {}}>
										{tire.oldSerial}
									</li>
								))}
							</ol>
						</td>
						<td>
							<ol>
								{report.vehicleTireReplace?.map((tire, i) => (
									<li key={i} style={tire.oldSerial !== tire.newSerial ? { fontWeight: "bold", color: "#342E7E" } : {}}>
										{tire.newSerial}
									</li>
								))}
							</ol>
						</td>
						<td>{report.totalTireChanged}</td>
						<td>{report.remark}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

export function ChangeOil({ reports }) {
	return (
		<table>
			<thead>
				<tr>
					<td>កាលបរិច្ឆេទ</td>
					<td>ប្រភេទឡាន</td>
					<td>លេខឡាន</td>
					<td>ប្ដូរប្រេងម៉ាស៊ីន</td>
					<td>ថែមប្រេងម៉ាស៊ីន</td>
					<td>ចំនួនដងបានប្ដូរ</td>
					<td>រយៈពេលប្ដូរ</td>
				</tr>
			</thead>
			<tbody>
				{reports.map((report, i) => (
					<tr key={i}>
						<td style={{ textAlign: "center" }}>{report.changeOilDate}</td>
						<td style={{ textAlign: "center" }}>{report.vehicleType?.label}</td>
						<td style={{ textAlign: "center" }}>{report.vehicle?.label}</td>
						<td style={{ textAlign: "center" }}>{report.situation?.value === 1 ? `${report.oilAmount} L` : ""}</td>
						<td style={{ textAlign: "center" }}>{report.situation?.value === 2 ? `${report.oilAmount} L` : ""}</td>
						<td style={{ textAlign: "center" }}>{report.changeOilCycle}</td>
						<td style={{ textAlign: "center" }}>{report.changeOilDurationKh}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

export function Accident({ reports }) {
	return (
		<table>
			<thead>
				<tr>
					<td>កាលបរិច្ឆេទ</td>
					<td>រថយន្ដ និងតៃកុង</td>
					<td>ទិសដៅ និងទីតាំងគ្រោះថ្នាក់</td>
					<td>មូលហេតុនៃគ្រោះថ្នាក់ និងការខូចខាតរថយន្ត</td>
					<td>ជនរងគ្រោះ និងស្ថានភាពរបួស</td>
					<td>កាលបរិច្ឆេទបញ្ចប់រឿង និងសំណង</td>
				</tr>
			</thead>
			<tbody>
				{reports.map((report, i) => (
					<tr key={i}>
						<td style={{ textAlign: "center" }}>{report.date}</td>
						<td>
							<ul>
								<li>{report.vehicle?.label}</li>
								<li>{report.driver}</li>
							</ul>
						</td>
						<td>
							<ul>
								{report.direction?.map((dir) => (
									<li>{dir}</li>
								))}
							</ul>
						</td>
						<td>
							<ul>
								{report.accidentLocation?.map((loc) => (
									<li>{loc}</li>
								))}
							</ul>
						</td>
						<td>
							<ul>
								{report.injuryStatus?.map((inju) => (
									<li>{inju}</li>
								))}
							</ul>
						</td>
						<td>
							<ul>
								{report.compensationDate?.map((compen) => (
									<li>{compen}</li>
								))}
							</ul>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}
