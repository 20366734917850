import React from "react";
import { connect } from "react-redux";

class index extends React.Component {
	render() {
		return <div className="ut-w-100 ut-h-100 ut-all-center">{this.props.children}</div>;
	}
}

const mapStateToProps = (state) => ({
	//
});

export default connect(mapStateToProps, {})(index);
