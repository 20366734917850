import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input, Label } from "../../../components/UTControls";
import { successDesc } from "../../../constants/dictionary";
import { GetPath, ResetRecords, handleData } from "../../../constants/handleData";
import { translation } from "../../../constants/translate";
import ActionBar from "../../../containers/ActionBar";
import Confirmation from "../../../containers/modals/Confirmation";
import { isEmptyOrWhiteSpace, titlePrefix } from "../../../helpers";
import { add_vehicle_type, find_vehicle_type, update_vehicle_type } from "../../../redux/VehicleType/actions";

class index extends Component {
	state = {
		id: this.props.match.params?.id,
		vehicleName: "",
		isSummited: false,
		isRequesting: false,
		isShowConfirmation: false,
		titlePrefix: titlePrefix(this.props.match.url),
		currentUrl: "/app/vehicle-type/list",
	};
	componentDidMount() {
		GetPath("VehicleType", "vehicle-type/")
		if (this.state.id) {
			if (this.props.vehicleType.label) {
				this.setState({ vehicleName: this.props.vehicleType.label });
			} else {
				this.props.find_vehicle_type({ id: this.state.id });
			}
		}
		// is data is already input
		if (Object.keys(handleData.VehicleType.data).length !== 0) {
			this.setState({
				vehicleName: handleData.VehicleType.data.vehicleName
			});
		}
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.vehicleType !== this.props.vehicleType) {
			this.setState({ vehicleName: this.props.vehicleType.label });
		}
	}
	componentWillUnmount() {
		handleData.VehicleType = {
			...handleData.VehicleType,
			data: {
				vehicleName: this.state.vehicleName
			}
		};
	}
	resetForm() {
		this.setState({
			id: null,
			vehicleName: "",
			isSummited: false,
			isRequesting: false,
			isShowConfirmation: false,
		});
	}
	gatherData() {
		if (isEmptyOrWhiteSpace(this.state.vehicleName)) {
			let dataCollection = new FormData();

			dataCollection.append("name", this.state.vehicleName);

			return dataCollection;
		} else return null;
	}
	handleBack = () => {
		handleData.VehicleType = {
			...handleData.VehicleType, path: "list"
		}
		this.setState({ isShowConfirmation: false });
		setTimeout(() => {
			this.props.history.push(`${this.state.currentUrl}`);
		}, 100);
	};
	handleSave = () => {
		let vehicleType = this.gatherData();
		this.setState({ isSummited: true });

		if (vehicleType) {
			this.setState({ isRequesting: true });
			if (this.state.id) {
				vehicleType.append("id", this.state.id);
				this.props.update_vehicle_type(vehicleType, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("VehicleType")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			} else {
				this.setState({ isRequesting: true });
				this.props.add_vehicle_type(vehicleType, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("VehicleType")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			}
		}
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		return (
			<div>
				<Confirmation handleClose={this.handleBack} isShow={this.state.isShowConfirmation} confirmType="SUCCESS" desc={successDesc} />
				<ActionBar title={`${this.state.titlePrefix} Vehicle Type`}>
					<Button name={translation["BACK"]} color="secondary" size="md" buttonStyle="filled" onClick={this.handleBack} />
				</ActionBar>
				<div style={{ maxWidth: 400 }}>
					<div>
						<Label title={translation["Vehicles"]} required />
						<Input
							disabled={this.state.titlePrefix === "View"}
							autoFocus={true}
							name="vehicleName"
							placeholder={translation["Vehicles"]}
							onChange={this.onChange}
							value={this.state.vehicleName}
						/>
						<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.vehicleName))}>Vehicle name is required</small>
						<br />
					</div>
					{this.state.titlePrefix !== "View" && (
						<Button
							name={
								this.state.id
									? this.state.isRequesting
										? translation["UPDATING..."]
										: translation["UPDATE"]
									: this.state.isRequesting
										? translation["SAVING..."]
										: translation["SAVE"]
							}
							color={this.state.id ? "warning" : "success"}
							size="md"
							buttonStyle="filled"
							onClick={this.handleSave}
						/>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	vehicleType: state.VehicleType.vehicleType,
});

export default connect(mapStateToProps, { add_vehicle_type, update_vehicle_type, find_vehicle_type })(index);
