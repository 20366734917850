import React from "react";
import Label from "./Label";
import { isEmptyOrWhiteSpace } from "../../helpers";

export default function BulletList({ name, required, bulletRef, onInput, isSummited, value, disabled, placeholder }) {
	return (
		<React.Fragment>
			<div className="d-flex align-items-start">
				<Label title={name} required={required} />
				{/* {disabled ? null : (
					<div
						className="ut-bulletlist"
						onClick={(e) => {
							let { current } = bulletRef;
							if (current.children.length === 0) {
								let li = document.createElement("li");
								current.appendChild(li);
							}
						}}
					>
						<FontAwesomeIcon icon={faListUl} />
					</div>
				)} */}
			</div>
			<div style={{ display: "flex", alignItems: "start", minHeight: 100 }} className="ut-input ut-input-primary ut-input-rounded ut-input-md">
				<ul
					className="ut-bullet-input"
					ref={bulletRef}
					onInput={(e) => {
						let { children } = e.target,
							bulletList = [];
						if (children.length > 0) {
							for (let i = 0; i < children.length; i++) {
								bulletList[i] = children[i].innerText;
							}
						} else {
							let { current } = bulletRef;
							let li = document.createElement("li");
							current.appendChild(li);
						}
						onInput(bulletList);
					}}
					contentEditable={!disabled}
				>
					{placeholder ? (
						placeholder.map((list, i) => {
							return <li key={i} className="ut-bullet-input" placeholder={list}></li>;
						})
					) : (
						<li className="ut-bullet-input"></li>
					)}
				</ul>
			</div>
			{required ? <small hidden={!(isSummited && !isEmptyOrWhiteSpace(value))}>{name} is required</small> : null}
		</React.Fragment>
	);
}
