import { cookiesKey } from ".";
import CryptoJS from "crypto-js";
import { APP_NAME } from "../constants/app";
import Cookies from "js-cookie";

export const baseUrl = process.env.REACT_APP_NODE_ENV === "Production" ? process.env.REACT_APP_PRODUCTION_URL : process.env.REACT_APP_DEVELOPMENT_URL;
export const enableContext = process.env.REACT_APP_ENABLE_CONTEXT === "true";

export const headers = () => ({
	Accept: "*/*",
});

export const headersJson = () => {
	return {
		Accept: "*/*",
		"Content-Type": "application/json",
	};
};

export const headersToken = () => {
	let accessToken = CryptoJS.AES.decrypt(Cookies.get(cookiesKey.accessToken), APP_NAME).toString(CryptoJS.enc.Utf8);

	return {
		Accept: "*/*",
		Authorization: `bearer ${accessToken}`,
	};
};

export const headersJsonToken = () => {
	let accessToken = CryptoJS.AES.decrypt(Cookies.get(cookiesKey.accessToken), APP_NAME).toString(CryptoJS.enc.Utf8);

	return {
		Accept: "*/*",
		"Content-Type": "application/json",
		Authorization: `bearer ${accessToken}`,
	};
};

export const headersFormDataToken = () => {
	let accessToken = CryptoJS.AES.decrypt(Cookies.get(cookiesKey.accessToken), APP_NAME).toString(CryptoJS.enc.Utf8);
	return {
		Accept: "*/*",
		// "Content-Type": "multipart/form-data",
		Authorization: `bearer ${accessToken}`,
	};
};
