import React from "react";
import DatePicker from "react-datepicker";

export default function LabelDatePicker(props) {
	return (
		<div>
			<span className="ut-float-label">
				{props.floater}
				{props.required ? <code> *</code> : ""}
			</span>
			<DatePicker
				dateFormat={props.showYearPicker ? "yyyy" : (props.showMonthYearPicker ? "MMM/yyyy" : "dd/MMM/yyyy")}
				showMonthDropdown
				showYearDropdown
				disabled={props.disabled}
				isClearable={!props.disabled && props.isClearable !== undefined ? props.isClearable : false}
				className={props.styles ? props.styles : "ut-custom-label-datePicker"}
				placeholderText={props.placeholder}
				selected={props.selected}
				onChange={props.onChange}
				maxDate={props.maxDate}
				minDate={props.minDate}
				showMonthYearPicker={props.showMonthYearPicker}
				showYearPicker={props.showYearPicker}
			/>
		</div>
	);
}
