import SortDown from "@material-ui/icons/ArrowDownwardOutlined";
import SortUp from "@material-ui/icons/ArrowUpwardOutlined";
import React from "react";
import Cancel from "../assets/icons/cancel.svg";
import Detail from "../assets/icons/detail.svg";
import Edit from "../assets/icons/edit.svg";
import EditTracking from "../assets/icons/editTracking.svg";
import Eye from "../assets/icons/eye.svg";
import Key from "../assets/icons/key.svg";
import Trash from "../assets/icons/trash.svg";
import Void from "../assets/icons/void.svg";
import { Button } from "../components/UTControls";
import { translation } from "../constants/translate";
import { isNA } from "../helpers";

export function _generateTableHeader(headersArr, actions = [], events = []) {
  let tempHeader = headersArr.map((column, i) => {
    if (column.value !== "action" && column.value !== "detail") {
      return {
        name: translation[column.label],
        width: column.width,
        selector: column.value,
        center: column.center,
        sortable: true,
        cell: (row) => (
          <span
            key={i}
            data-toggle="tooltip"
            data-placement="top"
            style={
              column.value === "changeOilDurationKh" ||
                (column.value === "vehicle.label" &&
                  row.compensationDate?.length === 0)
                ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color:
                    column.value === "vehicle.label" ||
                      row.changeOilDurationEn > 30
                      ? "white"
                      : "black",
                  backgroundColor:
                    column.value === "vehicle.label" ||
                      row.changeOilDurationEn > 30
                      ? "orange"
                      : "transparent",
                  padding: 5,
                  width: "auto",
                  borderRadius: 5,
                }
                : {}
            }
            className={`${column.value === "remark" ? "ut-ellipsis" : ""}`}
            title={
              column.value === "passing"
                ? `${row.passing?.map((pass) => ` ${pass.label}`)}`
                : column.note
                  ? Object.byString(row, column.note)
                  : Object.byString(row, column.value)
            }
          >
            {column.time
              ? isNA(Object.byString(row, column.value)) !== "-"
                ? `${isNA(Object.byString(row, column.value)).split(" ")[0]}`
                : "-"
              : `${column.note
                ? isNA(Object.byString(row, column.note)) !== "-"
                  ? "📒 "
                  : ""
                : ""
              }`}

            {!column.time && column.value.includes("Note")
              ? isNA(Object.byString(row, column.value))
                .split(", ")
                .map((r, j) => [
                  <span
                    key={j}
                    style={
                      (column.value === "oldTiresSerialNote" &&
                        !r.includes(
                          Object.byString(row, "newTiresSerialNote")?.split(
                            ", "
                          )[j]
                        )) ||
                        (column.value === "newTiresSerialNote" &&
                          !r.includes(
                            Object.byString(row, "oldTiresSerialNote")?.split(
                              ", "
                            )[j]
                          ))
                        ? { fontWeight: "bold", color: "#342E7E" }
                        : {}
                    }
                  >
                    • {r}
                  </span>,
                  <br />,
                ])
              : !column.time
                ? isNA(Object.byString(row, column.value))
                : ""}
            {column.time &&
              isNA(Object.byString(row, column.value)) !== "-" && (
                <h6 style={{ fontWeight: "bold" }}>{`${isNA(Object.byString(row, column.value)).split(" ")[1]
                  } ${isNA(Object.byString(row, column.value)).split(" ")[2]
                  }`}</h6>
              )}
          </span>
        ),
      };
    } else {
      return {
        name: translation[column.label],
        width: column.width,
        selector: column.value,
        center: false,
        cell: (row) =>
          row.noAction ? undefined : (
            <div className="d-flex">
              {actions.map((action, i) => {
                let icon = null,
                  customButton = null;
                switch (action) {
                  case "reset":
                    icon = Key;
                    break;
                  case "view":
                    icon = Eye;
                    break;
                  case "edit":
                    icon = Edit;
                    break;
                  case "delete":
                    icon = Trash;
                    break;
                  case "void":
                    icon = Void;
                    break;
                  case "detail":
                    icon = Detail;
                    break;
                  case "cancel":
                    icon = Cancel;
                    break;
                  case "tracking":
                    let passingAction = row.passing.filter(
                      (pass) => pass.state < 2
                    );
                    customButton = (
                      <div style={{ width: 200 }}>
                        <Button
                          block
                          onClick={() => events[i](row, passingAction)}
                          size="xs"
                          buttonStyle="filled"
                          color={
                            passingAction.length === 0
                              ? "secondary"
                              : passingAction[0].state === 1
                                ? "primary"
                                : "secondary"
                          }
                          name={
                            passingAction.length === 0
                              ? `មកដល់ ${row.destination.label}`
                              : `${passingAction[0].state === 1
                                ? "ចេញពី"
                                : "មកដល់"
                              } ${passingAction[0].label}`
                          }
                        />
                      </div>
                    );
                    break;
                  case "edit tracking":
                    icon =
                      row.passing.filter((pass) => pass.state < 1).length > 0
                        ? EditTracking
                        : null;
                    break;
                  default:
                    icon = null;
                }
                return customButton ? (
                  customButton
                ) : icon ? (
                  <img
                    key={i}
                    onClick={() => events[i](row)}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={action}
                    style={{ margin: "0 3px" }}
                    src={icon}
                    alt=""
                  />
                ) : null;
              })}
            </div>
          ),
      };
    }
  });

  return tempHeader;
}

export const customSort = (rows, selector, direction) => {
  if (selector) {
    if (selector === "start" || selector === "end") {
      return rows.sort((rowA, rowB) => {
        let aField = Object.byString(rowA, selector);
        let bField = Object.byString(rowB, selector);

        let comparison = 0;
        if (aField !== "មិនទាន់មកដល់" || bField !== "មិនទាន់មកដល់") {
          let tempAField = aField.split(" ");
          let tempBField = bField.split(" ");

          aField = new Date(
            `${tempAField[0].split("-").reverse().join("-")} ${tempAField[1]} ${tempAField[2]
            }`
          );
          bField = new Date(
            `${tempBField[0].split("-").reverse().join("-")} ${tempBField[1]} ${tempBField[2]
            }`
          );

          if (aField > bField) comparison = 1;
          else if (aField < bField) comparison = -1;
        } else if (aField !== "មិនទាន់មកដល់") comparison = -1;
        return direction === "desc" ? comparison * -1 : comparison;
      });
    } else
      return rows.sort((rowA, rowB) => {
        let aField = Object.byString(rowA, selector);
        let bField = Object.byString(rowB, selector);

        let comparison = 0;
        if (aField > bField) comparison = 1;
        else if (aField < bField) comparison = -1;

        return direction === "desc" ? comparison * -1 : comparison;
      });
  }
  return rows;
};

export const tableStyle = {
  header: {
    style: { fontFamily: "Koh_Santepheap Bold", color: "#312783" },
  },
  headRow: {
    style: { height: "55px" },
  },
  headCells: {
    style: {
      fontFamily: "Koh_Santepheap Bold",
      fontSize: "0.8rem",
      fontWeight: 800,
    },
  },
  rows: {
    style: { fontSize: "0.75rem", minHeight: "auto", padding: "8px 0px" },
  },
};

export const reportTableStyle = {
  header: {
    style: { fontFamily: "Koh_Santepheap Bold", color: "#312783" },
  },
  headRow: {
    style: { height: "55px" },
  },
  headCells: {
    style: {
      fontFamily: "Koh_Santepheap Bold",
      fontSize: "0.9rem",
      fontWeight: 800,
    },
  },
  rows: {
    style: { fontSize: "0.85rem", minHeight: "auto", padding: "8px 0px" },
  },
};

export const tvTableStyle = {
  header: {
    style: { fontFamily: "Koh_Santepheap Bold", color: "#312783" },
  },
  headRow: {
    style: { backgroundColor: "#F2994A", height: "40px" },
  },
  headCells: {
    style: {
      fontFamily: "Koh_Santepheap Bold",
      fontSize: "0.8rem",
      fontWeight: 800,
      color: "white",
    },
  },
  rows: {
    style: { fontSize: "0.75rem" },
  },
};

export function SortIcon() {
  return (
    <div className="ut-custom-sort-icon">
      <SortUp />
      <SortDown />
    </div>
  );
}
