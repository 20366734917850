import { GET_BRANCH, GET_ALL_BRANCH, FIND_BRANCH } from "./types";

const initialState = {
	allbranches: [],
	branches: [],
	branch: {},
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case GET_BRANCH:
			state.branches = actions.payload.branches;
			return { ...state };
		case GET_ALL_BRANCH:
			state.allbranches = actions.payload.allbranches;
			return { ...state };
		case FIND_BRANCH:
			state.branch = actions.payload.branch;
			return { ...state };
		default:
			return { ...state };
	}
};
