import Moment from "moment";
import { authfetch } from "../../auth";
import { baseUrl, headersJsonToken, headersToken } from "../../auth/secret";
import { dateOption } from "../../constants/variables";
import { toKhmerNum } from "../../helpers";
import { ADD_VEHICLE, DEL_VEHICLE, FIND_VEHICLE, GET_VEHICLE, UPDATE_VEHICLE } from "./types";

export const add_vehicle = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle/vehicle-add`, {
		method: "POST",
		headers: headersToken(),
		body: payload,
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: ADD_VEHICLE,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const update_vehicle = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle/vehicle-update`, {
		method: "POST",
		headers: headersToken(),
		body: payload,
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: UPDATE_VEHICLE,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const del_vehicle = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle/delete/${payload}`, {
		method: "POST",
		headers: headersToken(),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: DEL_VEHICLE,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const fetch_vehicle = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle/list`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			const vehicles = data.body.data.map((vehicle, i) => ({
				no: i + 1,
				value: vehicle.id,
				label: vehicle.name,
				vehicleType: { value: vehicle.vehicleTypeId, label: vehicle.vehicleTypeName },
				changeOilDate: vehicle.dateOfChangeOil ? Moment(vehicle.dateOfChangeOil).format(dateOption) : "",
				changeOilDurationEn: vehicle.dateOfChangeOil ? Math.abs(Moment(new Date()).diff(Moment(vehicle.dateOfChangeOil), "days")) : "",
				changeOilDurationKh: vehicle.dateOfChangeOil ? `${toKhmerNum(Math.abs(Moment(new Date()).diff(Moment(vehicle.dateOfChangeOil), "days")))} ថ្ងៃ` : "",
				vehicleTires: vehicle.vehicleTireList.map(tire => ({ oldSerial: tire.tireNumber, newSerial: tire.tireNumber })),
				vehicleTireReplaces: vehicle.vehicleTireReplaceResponses.map(tire => ({ oldSerial: tire.tireReplaceNumber, newSerial: tire.tireReplaceNumber })),
				createdDate: Moment(vehicle.created).format(dateOption),
				yearMade: vehicle.yearMade ? vehicle.yearMade.split('-')[0] : "",
				situationVehicle: vehicle.type === 1 ? "ថ្មី" : vehicle.type === 2 ? "មួយទឹក" : "-",
				type: vehicle.type
			}));
			dispatch({
				type: GET_VEHICLE,
				payload: { vehicles },
			});
		})
		.catch((error) => {
			console.error("Error fetching vehicles:", error);
			dispatch({
				type: GET_VEHICLE,
				payload: { vehicles: [] },
			});
		});
};

export const find_vehicle = (payload) => (dispatch) => {
	if (payload.vehicle) {
		dispatch({
			type: FIND_VEHICLE,
			payload: { vehicle: payload.vehicle },
		});
	} else {
		authfetch(`${baseUrl}/vehicle/find/${payload.id}`, {
			method: "POST",
			headers: headersToken(),
		})
			.then((data) => {
				let vehicle = data.body.data.map((vehicle, i) => {
					let vehicleTires = [],
						tireReplaces = [];
					if (vehicle.vehicleTireList) {
						vehicle.vehicleTireList.forEach((tire) => {
							vehicleTires.push({ oldSerial: tire.tireNumber, newSerial: tire.tireNumber });
						});
					}
					if (vehicle.vehicleTireReplaceResponses) {
						vehicle.vehicleTireReplaceResponses.forEach((tire) => {
							tireReplaces.push({ oldSerial: tire.tireReplaceNumber });
						});
					}
					return {
						no: i + 1,
						value: vehicle.id,
						label: vehicle.name,
						vehicleType: { value: vehicle.vehicleTypeId, label: vehicle.vehicleTypeName },
						changeOilDate: vehicle.dateOfChangeOil ? vehicle.dateOfChangeOil.split("-").reverse().join("-") : "",
						changeOilDurationEn: vehicle.dateOfChangeOil
							? Math.abs(Moment(new Date()).diff(Moment(vehicle.dateOfChangeOil), "days"))
							: "",
						changeOilDurationKh: vehicle.dateOfChangeOil
							? `${toKhmerNum(Math.abs(Moment(new Date()).diff(Moment(vehicle.dateOfChangeOil), "days")))} ថ្ងៃ`
							: "",
						vehicleTires: vehicleTires,
						tireReplaces: tireReplaces,
						createdDate: Moment(vehicle.created).format(dateOption),
						yearMade: vehicle.yearMade ? vehicle.yearMade : '',
						type: vehicle.type ? vehicle.type : 0
					};
				});
				dispatch({
					type: FIND_VEHICLE,
					payload: { vehicle: vehicle.length > 0 ? vehicle[0] : {} },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: FIND_VEHICLE,
					payload: { vehicle: {} },
				});
			});
	}
};
