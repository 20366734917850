import React from "react";
import PropTypes from "prop-types";

function ContextMenu(props: any) {
	return (
		<div onContextMenu={(e) => e.preventDefault()} onClick={props.trigger} hidden={props.hidden} className="ut-context-menu-overlay ut-card">
			<div
				className="ut-context-menu"
				style={
					props.left
						? props.bottom !== undefined
							? { bottom: props.bottom, left: props.left }
							: { top: props.top, left: props.left }
						: props.bottom !== undefined
						? { bottom: props.bottom, right: props.right }
						: { top: props.top, right: props.right }
				}
			>
				{props.children}
			</div>
		</div>
	);
}

ContextMenu.propTypes = {
	hidden: PropTypes.bool.isRequired,
	top: PropTypes.number,
	bottom: PropTypes.number,
	left: PropTypes.number,
	right: PropTypes.number,
	children: PropTypes.element.isRequired,

	trigger: PropTypes.func.isRequired,
};

export default ContextMenu;
