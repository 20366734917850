import React from "react";
import ReactSelect from "react-select";

import { NormalSelectStyle } from "../../assets/styles/constant";

export default function Select(props) {
	return (
		<div>
			<ReactSelect
				styles={NormalSelectStyle}
				placeholder={props.placeholder}
				onChange={props.onChange}
				options={props.options}
				defaultValue={props.defaultValue}
				isClearable={props.isClearable}
				isDisabled={props.isDisabled}
				isMulti={props.isMulti}
				value={props.value}
				onMenuOpen={props.onFocus}
			/>
		</div>
	);
}
