import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { GetPath, handleData } from "../../../constants/handleData";
import { ChangeSidebar } from "../../../redux/Navs/actions";
import AddEdit from "./addEdit";
import List from "./list";

function Tracking({ match, ChangeSidebar, permissionModuleList }) {
   GetPath("Tracking", "tracking/")
   ChangeSidebar({ currentPage: match.url, actionName: "Tracking" });
   return (
      <div className="ut-container">
         <Switch>
            <Redirect exact from={`${match.url}`} to={`${match.url}/${handleData.Tracking.path}`} />
            <Route path={`${match.url}/list`} component={List} />
            <Route path={`${match.url}/add`} component={AddEdit} />

            <Redirect to={`${match.url}/list`} />
         </Switch>
      </div>
   );
}

const mapStateToProps = (state) => ({
   permissionModuleList: state.Layout.permissionModuleList,
});

export default connect(mapStateToProps, { ChangeSidebar })(Tracking);
