import { GET_ACCIDENT, FIND_ACCIDENT } from "./types";

const initialState = {
	accidents: [],
	accident: {},
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case GET_ACCIDENT:
			state.accidents = actions.payload.accidents;
			return { ...state };
		case FIND_ACCIDENT:
			state.accident = actions.payload.accident;
			return { ...state };
		default:
			return { ...state };
	}
};
