import { FONT_SM } from "./variables.scss";

export const NormalSelectStyle = {
	input: (styles) => ({
		...styles,
		fontSize: FONT_SM,
		padding: "5px 0",
		marginLeft: "6px",
	}),
	placeholder: (styles) => ({ ...styles, fontSize: "13px", padding: "5px 0", marginLeft: "6px" }),
	container: (styles) => ({
		...styles,
		margin: "5px 10px",
	}),
	singleValue: (styles) => ({
		...styles,
		fontSize: FONT_SM,
		marginLeft: "6px",
	}),
	multiValue: (styles) => ({
		...styles,
		fontSize: FONT_SM,
		borderRadius: "5px",
		padding: "2px 2px 2px 4px",
	}),
	control: (styles, state) => ({
		...styles,
		position: "relative",
		zIndex: 0,
		borderRadius: 5,
		fontColor: "#000",
		backgroundColor: "#fff",
		borderColor: state.isFocused ? "#312783" : "#ecf0f1",
		"&:hover": {
			borderColor: state.isFocused ? "#312783" : "#312783",
		},
	}),
	option: (styles, { isSelected }) => ({
		...styles,
		zIndex: 0,
		backgroundColor: isSelected ? "#312783" : "#fff",
		fontSize: FONT_SM,
		":hover": {
			...styles[":hover"],
			backgroundColor: "#312783",
			color: "#fff",
		},
	}),
};
