import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input, Label } from "../../../../components/UTControls";
import { successDesc } from "../../../../constants/dictionary";
import { handleData } from "../../../../constants/handleData";
import { translation } from "../../../../constants/translate";
import ActionBar from "../../../../containers/ActionBar";
import Confirmation from "../../../../containers/modals/Confirmation";
import { isEmptyOrWhiteSpace } from "../../../../helpers";
import { ChangeSettingSidebar } from "../../../../redux/Navs/actions";
import { user_change_password } from "../../../../redux/User/actions";

class index extends Component {
	state = {
		oldPassword: "",
		newPassword: "",
		retypePassword: "",
	};

	componentDidMount() {
		handleData.Setting = {
			path: "change-password"
		};
		this.props.ChangeSettingSidebar({ currentPage: this.props.match.url, actionName: "Change Password" });
		// is data is already input
		if (Object.keys(handleData.ChangePassword.data).length !== 0) {
			const data = handleData.ChangePassword.data;
			this.setState({
				oldPassword: data.oldPassword,
				newPassword: data.newPassword,
				retypePassword: data.retypePassword,
			});
		}
	}
	componentWillUnmount() {
		handleData.ChangePassword = {
			data: {
				oldPassword: this.state.oldPassword,
				newPassword: this.state.newPassword,
				retypePassword: this.state.retypePassword,
			}
		};
	}
	resetForm() {
		this.setState({
			oldPassword: "",
			newPassword: "",
			retypePassword: "",

			isSummited: false,
			isShowConfirmation: false,

			currentUrl: "/app",
		});
	}

	gatherData() {
		if (isEmptyOrWhiteSpace(this.state.oldPassword, this.state.newPassword, this.state.retypePassword)) {
			if (this.state.newPassword === this.state.retypePassword) {
				let dataCollection = new FormData();

				dataCollection.append("oldPassword", this.state.oldPassword);
				dataCollection.append("newPassword", this.state.newPassword);

				return dataCollection;
			} else alert("Password does not match!");
			return null;
		} else return null;
	}

	handleBack = () => {
		this.setState({ isShowConfirmation: false });
		setTimeout(() => {
			this.props.history.push(`${this.state.currentUrl}`);
		}, 100);
	};

	handleChangePassword = () => {
		let changePassword = this.gatherData();
		this.setState({ isSummited: true });
		if (changePassword) {
			this.props.user_change_password(changePassword, (acknowledge, message) => {
				if (acknowledge) {
					this.resetForm();
					this.setState({ isShowConfirmation: true });
				} else alert(message);
			});
		}
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		return (
			<div>
				<Confirmation handleClose={this.handleBack} isShow={this.state.isShowConfirmation} confirmType="SUCCESS" desc={successDesc} />
				<ActionBar title="Change Pasword" underlined />
				<div style={{ width: 400 }}>
					<div>
						<Label title={translation["Old Password"]} required />
						<Input
							name="oldPassword"
							placeholder={translation["Old Password"]}
							onChange={this.onChange}
							value={this.state.oldPassword}
							type="password"
						/>
						<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.oldPassword))}>Old Password is required</small>
					</div>
					<div>
						<Label title={translation["New Password"]} required />
						<Input
							name="newPassword"
							placeholder={translation["New Password"]}
							onChange={this.onChange}
							value={this.state.newPassword}
							type="password"
						/>
						<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.newPassword))}>New Password is required</small>
					</div>
					<div>
						<Label title={translation["Re-Type Password"]} required />
						<Input
							name="retypePassword"
							placeholder={translation["Re-Type Password"]}
							onChange={this.onChange}
							value={this.state.retypePassword}
							type="password"
						/>
						<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.retypePassword))}>
							Re-Type Password is required
						</small>
					</div>
					<br />
				</div>
				<Button name={translation["UPDATE"]} color="warning" size="md" buttonStyle="filled" onClick={this.handleChangePassword} />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { ChangeSettingSidebar, user_change_password })(index);
