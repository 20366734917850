import { ADD_USER, UPDATE_USER, DEL_USER, FIND_USER, GET_USER } from "./types";
import { baseUrl, headersJsonToken, headersToken } from "../../auth/secret";
import { authfetch } from "../../auth";

import Moment from "moment";
import { dateOption } from "../../constants/variables";

export const add_user = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/user/add`, { method: "POST", headers: headersToken(), body: payload })
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({ type: ADD_USER });
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const update_user = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/user/update`, { method: "POST", headers: headersToken(), body: payload })
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({ type: UPDATE_USER });
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const user_change_password = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/user/change-password`, { method: "POST", headers: headersToken(), body: payload })
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({ type: UPDATE_USER });
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const del_user = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/user/delete/${payload}`, { method: "POST", headers: headersToken() })
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({ type: DEL_USER });
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const fetch_user = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/user/list`, { method: "POST", headers: headersJsonToken(), body: JSON.stringify(payload) })
		.then((data) => {
			let users = [];
			users = data.body.data.map((user, i) => {
				let branchList = user.userBranchListList.map((branch) => {
					return {
						value: branch.branchId,
						label: branch.branchName,
					};
				});
				return {
					no: i + 1,
					noAction: parseInt(user.id) === 1,
					value: parseInt(user.id),
					label: user.fullName,
					username: user.username,
					gender: { value: parseInt(user.sex), label: parseInt(user.sex) === 1 ? "Male" : "Female" },
					email: user.email,
					telephone: user.telephone,
					avatar: user.photo,
					systemRole: { value: user.roleId, label: user.roleName },
					branchList,
					createdDate: Moment(user.created).format(dateOption),
				};
			});

			dispatch({ type: GET_USER, payload: { users } });
		})
		.catch((error) => {
			console.error(error);
			dispatch({
				type: GET_USER,
				payload: { users: [] },
			});
		});
};

export const find_user = (payload) => (dispatch) => {
	if (payload.user) {
		dispatch({ type: FIND_USER, payload: { user: payload.user } });
	} else {
		authfetch(`${baseUrl}/user/find/${payload.id}`, { method: "POST", headers: headersToken() })
			.then((data) => {
				let user = [];
				user = data.body.data.map((user, i) => {
					let branchList = user.userBranchListList.map((branch, i) => {
						return { value: branch.branchId, label: branch.branchName };
					});
					return {
						no: i + 1,
						value: user.id,
						label: user.fullName,
						username: user.username,
						gender: { value: parseInt(user.sex), label: parseInt(user.sex) === 1 ? "Male" : "Female" },
						email: user.email,
						telephone: user.telephone,
						avatar: user.photo,
						systemRole: { value: user.roleId, label: user.roleName },
						branchList,
						createdDate: Moment(user.created).format(dateOption),
					};
				});

				dispatch({ type: FIND_USER, payload: { user: user.length > 0 ? user[0] : {} } });
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: FIND_USER,
					payload: { user: {} },
				});
			});
	}
};
