import { ADD_VEHICLE_TYPE, UPDATE_VEHICLE_TYPE, DEL_VEHICLE_TYPE, FIND_VEHICLE_TYPE, GET_VEHICLE_TYPE } from "./types";
import { baseUrl, headersJsonToken, headersToken } from "../../auth/secret";
import { authfetch } from "../../auth";

import Moment from "moment";
import { dateOption } from "../../constants/variables";

export const add_vehicle_type = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-type/vehicle-type-add`, {
		method: "POST",
		headers: headersToken(),
		body: payload,
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: ADD_VEHICLE_TYPE,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const update_vehicle_type = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-type/vehicle-type-update`, {
		method: "POST",
		headers: headersToken(),
		body: payload,
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: UPDATE_VEHICLE_TYPE,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const del_vehicle_type = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-type/delete/${payload}`, {
		method: "POST",
		headers: headersToken(),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: DEL_VEHICLE_TYPE,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const fetch_vehicle_type = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-type/list`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			let vehicleTypes = [];
			vehicleTypes = data.body.data.map((vehicleType, i) => {
				return {
					no: i + 1,
					value: vehicleType.id,
					label: vehicleType.name,
					createdDate: Moment(vehicleType.created).format(dateOption),
				};
			});

			dispatch({
				type: GET_VEHICLE_TYPE,
				payload: { vehicleTypes },
			});
		})
		.catch((error) => {
			console.error(error);

			dispatch({
				type: GET_VEHICLE_TYPE,
				payload: { vehicleTypes: [] },
			});
		});
};

export const find_vehicle_type = (payload) => (dispatch) => {
	if (payload.vehicleType) {
		dispatch({
			type: FIND_VEHICLE_TYPE,
			payload: { vehicleType: payload.vehicleType },
		});
	} else {
		authfetch(`${baseUrl}/vehicle-type/find/${payload.id}`, {
			method: "POST",
			headers: headersToken(),
		})
			.then((data) => {
				let vehicleType = [];
				vehicleType = data.body.data.map((vehicleType, i) => {
					return {
						no: i + 1,
						value: vehicleType.id,
						label: vehicleType.name,
						createdDate: Moment(vehicleType.created).format(dateOption),
					};
				});

				dispatch({
					type: FIND_VEHICLE_TYPE,
					payload: { vehicleType: vehicleType.length > 0 ? vehicleType[0] : {} },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: FIND_VEHICLE_TYPE,
					payload: { vehicleType: {} },
				});
			});
	}
};
