import ComponentDashboard from "../views/app/dashboard";
import ComponentTracking from "../views/app/tracking";
import ComponentVehicleRepair from "../views/app/vehicleRepair";
import ComponentChangeTire from "../views/app/changeTire";
import ComponentChangeOil from "../views/app/changeOil";
import ComponentAccident from "../views/app/accident";
import ComponentVehicleType from "../views/app/vehicleType";
import ComponentVehicle from "../views/app/vehicles";
import ComponentBranches from "../views/app/branches";
import ComponentReport from "../views/app/report";
import ComponentSetting from "../views/app/setting";

import Dashboard from "../assets/icons/dashboard.svg";
import Tracking from "../assets/icons/AddTracking.svg";
import VehicleRepair from "../assets/icons/vehicle-repair.svg";
import ChangeTire from "../assets/icons/change-tire.svg";
import ChangeOil from "../assets/icons/change-oil.svg";
import Accident from "../assets/icons/accident.svg";
import VehicleType from "../assets/icons/Vehicle-Group.svg";
import Vehicle from "../assets/icons/Vehicle.svg";
import Branches from "../assets/icons/Branches.svg";
import Report from "../assets/icons/Report.svg";
import Settings from "../assets/icons/settings.svg";

export const data = [
	{
		id: "Dashboard",
		label: "Dashboard",
		to: "/app/dashboard",
		icon: Dashboard,
		component: ComponentDashboard,
		status: false,
	},
	{
		id: "Tracking",
		label: "Tracking",
		to: "/app/tracking",
		icon: Tracking,
		component: ComponentTracking,
		status: true,
	},
	{
		id: "Vehicle Brokes",
		label: "Vehicle Repair",
		to: "/app/vehicle-repair",
		icon: VehicleRepair,
		component: ComponentVehicleRepair,
		status: false,
	},
	{
		id: "Vehicle Change Tires",
		label: "Change Tire",
		to: "/app/change-tire",
		icon: ChangeTire,
		component: ComponentChangeTire,
		status: false,
	},
	{
		id: "Vehicle Engine Oil",
		label: "Change Oil",
		to: "/app/change-oil",
		icon: ChangeOil,
		component: ComponentChangeOil,
		status: false,
	},
	{
		id: "Vehicle Accident Information",
		label: "Accident",
		to: "/app/accident",
		icon: Accident,
		component: ComponentAccident,
		status: false,
	},
	{
		id: "Vehicle Type",
		label: "Vehicle Type",
		to: "/app/vehicle-type",
		icon: VehicleType,
		component: ComponentVehicleType,
		status: false,
	},
	{
		id: "Vehicles",
		label: "Vehicles",
		to: "/app/vehicles",
		icon: Vehicle,
		component: ComponentVehicle,
		status: false,
	},
	{
		id: "Branches",
		label: "Branches",
		to: "/app/branches",
		icon: Branches,
		component: ComponentBranches,
		status: false,
	},
	{
		id: "Report",
		label: "Report",
		to: "/app/report",
		icon: Report,
		component: ComponentReport,
		status: false,
	},
	{
		id: "Setting",
		label: "Setting",
		to: "/app/setting",
		icon: Settings,
		component: ComponentSetting,
		status: true,
		subs: [
			{
				id: "Users",
				label: "Users",
				to: "/app/setting/users",
				status: false,
			},
			{
				id: "System Roles",
				label: "System Roles",
				to: "/app/setting/system-roles",
				status: false,
			},
			{
				id: "Change Password",
				label: "Change Password",
				to: "/app/setting/change-password",
				status: true,
			},
		],
	},
];
