import { GET_CHANGE_OIL, FIND_CHANGE_OIL } from "./types";

const initialState = {
	changeOils: [],
	changeOil: {},
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case GET_CHANGE_OIL:
			state.changeOils = actions.payload.changeOils;
			return { ...state };
		case FIND_CHANGE_OIL:
			state.changeOil = actions.payload.changeOil;
			return { ...state };
		default:
			return { ...state };
	}
};
