import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./redux";

const initialState = {};
const middleware = [thunk];

const store =
	typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__
		? createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), window.__REDUX_DEVTOOLS_EXTENSION__()))
		: createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)));

export default store;
