import { faSearch } from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import Loading from "../../../components/Loading";
import { Button, Input } from "../../../components/UTControls";
import { deleteDesc, successDesc, vehicleTypeHeaders } from "../../../constants/dictionary";
import { SaveData, handleData } from "../../../constants/handleData";
import { translation } from "../../../constants/translate";
import ActionBar from "../../../containers/ActionBar";
import Confirmation from "../../../containers/modals/Confirmation";
import { SortIcon, _generateTableHeader, tableStyle } from "../../../helpers/tableHelper";
import { del_vehicle_type, fetch_vehicle_type, find_vehicle_type } from "../../../redux/VehicleType/actions";

class VehicleTypeList extends Component {
	state = {
		records: [],
		selectedRecord: {},
		isLoading: true,
		isShowConfirmation: false,
		confirmationDesc: deleteDesc,
		confirmationType: "DELETE",
		currentUrl: "/app/vehicle-type",
		search: '',
		dataTableColumns: [],
		allowAdd: false,
		allowEdit: false,
		allowDelete: false,
	};

	componentDidMount = () => {
		handleData.VehicleType = { ...handleData.VehicleType, path: "list", data: {} };
		// check if data already exists set2State but not fetch it
		const vehicleType = handleData.VehicleType
		if (vehicleType.records.length > 0) {
			this.setState({ records: vehicleType.records, search: vehicleType.filters.search, isLoading: false });
		} else {
			this.props.fetch_vehicle_type({ page: 1, rowsPerPage: 1000, searchText: this.state.search });
		}
		this.updatePermission();
	};
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.permissionModuleList !== this.props.permissionModuleList) {
			this.updatePermission();
		}
		if (prevProps.vehicleTypes !== this.props.vehicleTypes) {
			this.setState({ records: this.props.vehicleTypes, isLoading: false }, () => {
				const filters = { search: this.state.search }
				SaveData("VehicleType", filters, this.state.records)
			});
		}
	}

	updatePermission = () => {
		const { actionName, permissionModuleList } = this.props;
		const { moduleList } = permissionModuleList[actionName] || {};
		const { ADD, EDIT, DELETE } = moduleList || {};

		this.setState({ allowAdd: ADD, allowEdit: EDIT, allowDelete: DELETE }, () => {
			const permission = this.handleLayoutPermission();
			this.setState({ dataTableColumns: _generateTableHeader(vehicleTypeHeaders, permission.actions, permission.events) });
		});
	};

	handleLayoutPermission = () => {
		const { allowEdit, allowDelete } = this.state;
		let actions = ["view"],
			events = [this.handleView];

		if (allowEdit) {
			actions.push("edit");
			events.push(this.handleEdit);
		}
		if (allowDelete) {
			actions.push("delete");
			events.push(this.handleTrigger);
		}
		return { actions, events };
	};

	handleAdd = () => {
		handleData.VehicleType = { ...handleData.VehicleType, path: 'add' };
		this.props.history.push(`${this.state.currentUrl}/add`);
	};

	handleView = (record) => {
		handleData.VehicleType = { ...handleData.VehicleType, path: `view/${record.value}` };
		this.props.find_vehicle_type({ vehicleType: record });
		this.props.history.push(`${this.state.currentUrl}/view/${record.value}`);
	};

	handleEdit = (record) => {
		handleData.VehicleType = { ...handleData.VehicleType, path: `edit/${record.value}` };
		this.props.find_vehicle_type({ vehicleType: record });
		this.props.history.push(`${this.state.currentUrl}/edit/${record.value}`);
	};

	handleDelete = () => {
		this.setState({ isShowConfirmation: false });
		this.props.del_vehicle_type(this.state.selectedRecord?.value, (acknowledge, message) => {
			if (acknowledge) {
				this.props.fetch_vehicle_type({ page: 1, rowsPerPage: 1000, searchText: this.state.search });
				this.setState({ isShowConfirmation: true, confirmationType: "SUCCESS", confirmationDesc: successDesc });
			} else {
				alert(message);
			}
		});
	};

	handleTrigger = (record = {}) => {
		this.setState({ isShowConfirmation: !this.state.isShowConfirmation }, () => {
			setTimeout(() => {
				this.setState({ confirmationType: "DELETE", confirmationDesc: deleteDesc, selectedRecord: record });
			}, 200);
		});
	};
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onKeyPress = (e) => {
		if (e.key === "Enter") {
			this.setState({ isLoading: true });
			this.props.fetch_vehicle_type({ page: 1, rowsPerPage: 1000, searchText: this.state.search });
		}
	};
	render() {
		return (
			<div>
				<Confirmation
					handleClose={this.handleTrigger}
					handleConfirm={this.handleDelete}
					handleReject={this.handleTrigger}
					isShow={this.state.isShowConfirmation}
					confirmType={this.state.confirmationType}
					desc={this.state.confirmationDesc}
				/>
				<ActionBar title={this.props.actionName}>
					<Input
						name="search"
						value={this.state.search}
						placeholder={translation["searchCar"]}
						rightIcon={faSearch}
						onChange={this.onChange}
						onKeyPress={this.onKeyPress}
					/>
					<Button name={translation["REFRESH"]} buttonStyle="filled"
						onClick={() => {
							this.setState({ isLoading: true, records: [], search: ''});
							this.props.fetch_vehicle_type({ page: 1, rowsPerPage: 1000 });
						}}
					/>
					{this.state.allowAdd && (
						<Button name={translation["ADD NEW"]} color="success" size="md" buttonStyle="filled" onClick={this.handleAdd} />
					)}
				</ActionBar>
				<DataTable
					fixedHeader
					noDataComponent={<span style={{ margin: "20px 0px" }}>គ្មានទិន្នន័យ</span>}
					fixedHeaderScrollHeight="calc(100vh - 220px)"
					noHeader
					pointerOnHover
					striped
					highlightOnHover
					data={this.state.records}
					columns={this.state.dataTableColumns}
					sortIcon={<SortIcon />}
					customStyles={tableStyle}
					progressPending={this.state.isLoading}
					progressComponent={<Loading />}
					pagination={true}
					paginationPerPage={100}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	vehicleTypes: state.VehicleType.vehicleTypes,
	actionName: state.Navs.actionName,
	permissionModuleList: state.Layout.permissionModuleList,
});

export default connect(mapStateToProps, { fetch_vehicle_type, del_vehicle_type, find_vehicle_type })(VehicleTypeList);
