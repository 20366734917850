import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input, Label, LabelSelect } from "../../../../components/UTControls";
import { successDesc } from "../../../../constants/dictionary";
import { GetPath, ResetRecords, handleData } from "../../../../constants/handleData";
import { translation } from "../../../../constants/translate";
import ActionBar from "../../../../containers/ActionBar";
import Confirmation from "../../../../containers/modals/Confirmation";
import { isEmptyOrWhiteSpace, titlePrefix } from "../../../../helpers";
import { add_systemRole, find_systemRole, update_systemRole } from "../../../../redux/SystemRole/actions";

class index extends Component {
	state = {
		id: this.props.match.params.id,
		name: "",
		availableUser: [],
		moduleList: [],
		permissionList: [],

		isSummited: false,
		isRequesting: false,
		isShowConfirmation: false,

		titlePrefix: titlePrefix(this.props.match.url),
		currentUrl: "/app/setting/system-roles/list",
	};

	componentDidMount() {
		GetPath("SystemRole", "system-roles/")
		if (this.state.id) {
			if (this.props.systemRole.label) {
				this.setState({
					name: this.props.systemRole.label,
					availableUser: this.props.systemRole.userList,
					permissionList: this.props.systemRole.moduleTypeList,
				});
			} else this.props.find_systemRole({ id: this.state.id });
		} else {
			this.setState({ permissionList: this.props.moduleTypes });
		}
		// is data is already input
		if (Object.keys(handleData.SystemRole.data).length !== 0) {
			const data = handleData.SystemRole.data;
			this.setState({
				name: data.name,
				availableUser: data.availableUser,
				permissionList: data.permissionList,
			});
		}
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.systemRole !== this.props.systemRole) {
			this.setState({
				name: this.props.systemRole.label,
				availableUser: this.props.systemRole.userList,
				permissionList: this.props.systemRole.moduleTypeList,
			});
		}
		if (prevProps.moduleTypes !== this.props.moduleTypes && !isEmptyOrWhiteSpace(this.state.id)) {
			this.setState({ permissionList: this.props.moduleTypes });
		}
	}
	componentWillUnmount() {
		handleData.SystemRole = {
			...handleData.SystemRole,
			data: {
				name: this.state.name,
				availableUser: this.state.availableUser,
				permissionList: this.state.permissionList,
			}
		};
	}

	resetForm() {
		this.setState({
			id: null,
			name: "",
			availableUser: [],
			moduleList: [],
			permissionList: [],

			isSummited: false,
			isRequesting: false,
			isShowConfirmation: false,
		});
	}

	gatherData() {
		if (isEmptyOrWhiteSpace(this.state.name)) {
			let moduleList = [];
			let userList = this.state.availableUser.length > 0 ? this.state.availableUser.map((user) => {
				return { userId: user.value };
			}) : [];

			this.state.permissionList.forEach((permission) => {
				permission.moduleList.forEach((module) => {
					if (module.checked) moduleList.push({ moduleId: module.id });
				});
			});

			let dataCollection = {
				name: this.state.name,
				userList,
				moduleList,
			};

			return dataCollection;
		} else return null;
	}

	handleCheckPermission = (indexing) => {
		let index = indexing.split("-"),
			tempPermission = this.state.permissionList;

		tempPermission[parseInt(index[0])].moduleList[parseInt(index[1])].checked =
			!tempPermission[parseInt(index[0])].moduleList[parseInt(index[1])].checked;
		this.setState({ permissionList: tempPermission });
	};

	handleBack = () => {
		this.setState({ isShowConfirmation: false });
		setTimeout(() => { this.props.history.push(`${this.state.currentUrl}`) }, 100);
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	handleSave = () => {
		let systemRole = this.gatherData();
		this.setState({ isSummited: true });
		if (systemRole) {
			this.setState({ isRequesting: true });
			if (this.state.id) {
				systemRole.id = this.state.id;
				this.props.update_systemRole(systemRole, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("SystemRole")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			} else {
				this.setState({ isRequesting: true });
				this.props.add_systemRole(systemRole, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("SystemRole")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			}
		}
	};

	render() {
		return (
			<div>
				<Confirmation handleClose={this.handleBack} isShow={this.state.isShowConfirmation} confirmType="SUCCESS" desc={successDesc} />
				<ActionBar title={`${this.state.titlePrefix} System Role`}>
					<Button name={translation["BACK"]} color="secondary" size="md" buttonStyle="filled" onClick={this.handleBack} />
				</ActionBar>
				<div className="ut-setting-scrollable">
					<div className="ut-d-flex">
						<div style={{ width: 400 }}>
							<div>
								<Label title={translation["System Role"]} required />
								<Input
									autoFocus
									disabled={this.state.titlePrefix === "View"}
									name="name"
									placeholder={translation["System Role"]}
									onChange={this.onChange}
									value={this.state.name}
								/>
								<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.name))}>System Role is required</small>
							</div>
						</div>
						<div style={{ width: 400 }}>
							<Label title={translation["Select Available Users"]} />
							<LabelSelect
								isMulti
								placeholder={translation["Select available users"]}
								options={this.props.users}
								isDisabled={this.state.titlePrefix === "View"}
								onChange={(selected) => this.setState({ availableUser: selected })}
								value={this.state.availableUser}
							/>
						</div>
					</div>
					<div style={{ width: 800 }}>
						<div className="ut-permission">
							{this.state.permissionList?.map((module, id) => (
								<div key={id}>
									<ModuleTitle name={module.name} />
									{module.moduleList
										? module.moduleList.map((subsModule, i) => (
											<ModuleList
												key={i}
												id={`${id}-${i}-${subsModule.id}`}
												name={subsModule.name}
												checked={subsModule.checked}
												onChange={this.handleCheckPermission}
												disable={this.state.disable}
											/>
										))
										: null}
								</div>
							))}
						</div>
						{this.state.titlePrefix !== "View" && (
							<Button
								name={
									this.state.id
										? this.state.isRequesting
											? translation["UPDATING"]
											: translation["UPDATE"]
										: this.state.isRequesting
											? translation["SAVING"]
											: translation["SAVE"]
								}
								color={this.state.id ? "warning" : "success"}
								size="md"
								buttonStyle="filled"
								onClick={this.handleSave}
							/>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	users: state.User.users,
	systemRole: state.SystemRole.systemRole,
	moduleTypes: state.ModuleType.moduleTypes,
});

export default connect(mapStateToProps, { find_systemRole, add_systemRole, update_systemRole })(index);

const ModuleTitle = (props) => {
	return (
		<span>
			<b>{props.name}</b>
		</span>
	);
};

const ModuleList = (props) => {
	return (
		<div className="ut-permission-list">
			<span>{props.name}</span>
			<div className="ut-d-flex ut-align-items-center">
				<label className="ut-checkbox">
					<input
						disabled={props.disable}
						onChange={() => props.onChange(props.id)}
						type="checkbox"
						checked={props.checked}
						style={{ cursor: "pointer" }}
					/>
					<span></span>
				</label>
			</div>
		</div>
	);
};
