import { SIDEBAR_MENU } from "./types";

import { data } from "../../constants/menu";
import { authfetch } from "../../auth";
import { baseUrl, headersToken } from "../../auth/secret";

export const SetupSidebar = (callback) => (dispatch) => {
	authfetch(`${baseUrl}/role/menu`, {
		method: "POST",
		headers: headersToken(),
	})
		.then((resData) => {
			let permissionModuleList = {};
			resData.body.data.map((moduleType) => {
				let moduleList = {};
				if (moduleType.name === "Report") {
					moduleType.moduleList.map((module) => {
						moduleList[module.name] = module.checked;
						return null;
					});
				} else {
					moduleType.moduleList.map((module) => {
						moduleList[module.type] = module.checked;
						return null;
					});
				}

				permissionModuleList[moduleType.name] = {
					moduleList,
				};
				return null;
			});
			data.map((menu, menuI) => {
				if (menu.subs) {
					menu.subs.map((subMenu, subMenuI) => {
						if (permissionModuleList[subMenu.id]) {
							data[menuI].status = data[menuI].subs[subMenuI].status = true;
						}
						return null;
					});
				} else {
					if (permissionModuleList[menu.id]) {
						data[menuI].status = true;
					}
				}

				return null;
			});

			dispatch({
				type: SIDEBAR_MENU,
				payload: {
					Menus: data,
					permissionModuleList: permissionModuleList,
				},
			});
			callback(resData.header.result && resData.body.status);
		})
		.catch((error) => {
			console.error(error);

			dispatch({
				type: SIDEBAR_MENU,
				payload: {
					Menus: data,
					permissionModuleList: [],
				},
			});
			callback(false);
		});
};
