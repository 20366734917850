import Moment from "moment";
import { authfetch } from "../../auth";
import { baseUrl, headersJsonToken } from "../../auth/secret";
import { durationFormat } from "../../helpers";
import { GET_DASHBOARD, GET_TVDASHBOARD } from "./types";

export const fetch_dashboard = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/dashboard/Dashboard`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			let dashboard = [],
				no = 1;
			data.body.data.forEach((report) => {
				let passing = [],
					passingText;

				passing = report.vehicleDestinationResponses.map((pass, i) => {
					if (i === 0) {
						passingText = pass.branchToName;
					} else passingText = `${passingText}, ${pass.branchToName}`;

					return {
						value: pass.branchToId,
						label: pass.branchToName,
						startDate: pass.startDate ? Moment(pass.startDate).format("DD-MM-YYYY hh:mm A") : "-",
						stopDate: pass.stopDate ? Moment(pass.stopDate).format("DD-MM-YYYY hh:mm A") : "-",
						state: pass.startDate && pass.stopDate ? 2 : pass.stopDate ? 1 : 0,
					};
				});

				let arrived = passing.filter((pass) => pass.state < 2 && pass.state > 0);
				let tempArrived = passing.filter((pass) => pass.state === 2);

				if (arrived.length && report.stopDate) {
					if (tempArrived.length && arrived.length) {
						if (arrived[0].state > 0) {
							arrived.unshift({
								value: report.branchId,
								label: report.branchName,
							});
						}
					}
				} else {
					if (report.stopDate) {
						arrived.push({
							value: report.branchToBId ? report.branchToBId : report.branchToAId,
							label: report.branchToBName ? report.branchToBName : report.branchToAName,
						});
					} else {
						if (tempArrived.length) {
							arrived.push(tempArrived[tempArrived.length - 1]);
						}
					}
				}

				// if (reportAged < 1440 || reportAged === "-") {
				let duration = report.stopDate ? parseInt(new Moment(report.stopDate).diff(Moment(report.created)) / (1000 * 60)) : "-";
				dashboard.push({
					no,
					vehicle: { value: report.vehicleId, label: report.vehicleName },
					vehicleType: { value: report.vehicleTypeId, label: report.vehicleTypeName },
					origin: { value: report.branchId, label: report.branchName },
					destination: {
						value: report.branchToBId ? report.branchToBId : report.branchToAId,
						label: report.branchToBName ? report.branchToBName : report.branchToAName,
					},
					passingText,
					passing,
					start: report.created ? `${Moment(report.created).format("DD-MM-YYYY hh:mm A")}` : "-",
					end: report.stopDate ? `${Moment(report.stopDate).format("DD-MM-YYYY hh:mm A")}` : "-",
					arrived,
					duration: durationFormat(duration),
					remarkStart: report.remarkStart,
					remarkStop: report.remartStop,
				});

				no++;
				// }
			});

			dispatch({
				type: GET_DASHBOARD,
				payload: { dashboard },
			});
		})
		.catch((error) => {
			console.error(error);
			dispatch({
				type: GET_DASHBOARD,
				payload: { dashboard: [] },
			});
		});
};

export const fetch_tvDashboard = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/dashboard/Dashboard`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			let tvDashboard = [],
				no = 1;

			let pagination = {
				page: payload.page < Math.ceil(data.body.pagination.total / payload.rowsPerPage) ? payload.page + 1 : 1,
				rowsPerPage: payload.rowsPerPage,
				total: data.body.pagination.total ? data.body.pagination.total : 0,
			};

			data.body.data.forEach((report) => {
				let passing = [];

				passing = report.vehicleDestinationResponses.map((pass) => {
					return {
						value: pass.branchToId,
						label: pass.branchToName,
						startDate: pass.startDate ? Moment(pass.startDate).format("DD-MM-YYYY hh:mm A") : "-",
						stopDate: pass.stopDate ? Moment(pass.stopDate).format("DD-MM-YYYY hh:mm A") : "-",
						state: pass.startDate && pass.stopDate ? 2 : pass.stopDate ? 1 : 0,
					};
				});

				let arrived = passing.filter((pass) => pass.state < 2 && pass.state > 0);
				let tempArrived = passing.filter((pass) => pass.state === 2);

				if (arrived.length && report.stopDate) {
					if (tempArrived.length && arrived.length) {
						if (arrived[0].state > 0) {
							arrived.unshift({
								value: report.branchId,
								label: report.branchName,
							});
						}
					}
				} else {
					if (report.stopDate) {
						arrived.push({
							value: report.branchToBId ? report.branchToBId : report.branchToAId,
							label: report.branchToBName ? report.branchToBName : report.branchToAName,
						});
					} else {
						if (tempArrived.length) {
							arrived.push(tempArrived[tempArrived.length - 1]);
						}
					}
				}

				let duration = report.stopDate ? parseInt(new Moment(report.stopDate).diff(Moment(report.created)) / (1000 * 60)) : "-";
				tvDashboard.push({
					no,
					vehicle: { value: report.vehicleId, label: report.vehicleName },
					vehicleType: { value: report.vehicleTypeId, label: report.vehicleTypeName },
					origin: { value: report.branchId, label: report.branchName },
					destination: {
						value: report.branchToBId ? report.branchToBId : report.branchToAId,
						label: report.branchToBName ? report.branchToBName : report.branchToAName,
					},
					passing,
					start: report.created ? `${Moment(report.created).format("DD-MM-YYYY hh:mm A")}` : "-",
					end: report.stopDate ? `${Moment(report.stopDate).format("DD-MM-YYYY hh:mm A")}` : "-",
					arrived,
					duration: durationFormat(duration),
					remarkStart: report.remarkStart,
					remarkStop: report.remartStop,
				});

				no++;
			});

			dispatch({
				type: GET_TVDASHBOARD,
				payload: { tvDashboard, pagination },
			});
		})
		.catch((error) => {
			console.error(error);
			dispatch({
				type: GET_TVDASHBOARD,
				payload: {
					tvDashboard: [],
					pagination: {
						page: 0,
						rowsPerPage: 0,
						total: 0,
					},
				},
			});
		});
};
