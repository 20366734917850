import React from "react";
import ErrorBoundary from "./errorBoundary";
import { APP_NAME } from "../constants/app";
import { enableContext } from "../auth/secret";
import { PrivateRoute, PublicRoute } from "./routeHelpers";
import { BrowserRouter, Switch, Redirect,Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import User from "../views/User";
import App from "../views/app";
import PrivacyPolicy from "../static/PrivacyPolicy";
import AttendancePrivacyPolicy from "../static/AttendancePrivacyPolicy";
import AttendanceTermCondition from "../static/AttendanceTermCondition";

export default function index() {
	return (
		<ErrorBoundary>
			<BrowserRouter basename={enableContext ? `/${APP_NAME}` : undefined}>
				<AnimatePresence>
					<Switch>
						<Route path="/privacy-policy" component={PrivacyPolicy} />
						<Route path="/privacy-policy-att" component={AttendancePrivacyPolicy} />
						<Route path="/terms-condition-att" component={AttendanceTermCondition} />
						<PublicRoute path={`/auth`} component={User} />
						<PrivateRoute path={`/app`} component={App} />
						<Redirect to="/app" />
					</Switch>
				</AnimatePresence>
			</BrowserRouter>
		</ErrorBoundary>
	);
}
