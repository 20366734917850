import Moment from "moment";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { BulletList, Button, Input, Label, LabelDatePicker, LabelSelect, TextArea } from "../../../components/UTControls";
import { successDesc } from "../../../constants/dictionary";
import { GetPath, ResetRecords, handleData } from "../../../constants/handleData";
import { translation } from "../../../constants/translate";
import ActionBar from "../../../containers/ActionBar";
import Confirmation from "../../../containers/modals/Confirmation";
import { formatbulletlist, isEmptyOrWhiteSpace, populateList, titlePrefix } from "../../../helpers";
import { add_accident, find_accident, update_accident } from "../../../redux/Accident/actions";

class index extends Component {
	state = {
		id: this.props.match.params?.id,
		date: new Date(),
		vehicle: "",
		driver: "",
		direction: [""],
		injuryStatus: [""],
		accidentLocation: [""],
		compensationDate: [""],
		remark: "",

		dirRef: createRef(),
		injuRef: createRef(),
		accLoRef: createRef(),
		compenRef: createRef(),

		isSummited: false,
		isRequesting: false,
		isShowConfirmation: false,

		titlePrefix: titlePrefix(this.props.match.url),
		currentUrl: "/app/accident/list",
	};

	componentDidMount() {
		GetPath("Accident", "accident/");
		if (this.state.id) {
			if (this.props.accident.driver) {
				this.setState({
					date: this.props.accident.date ? new Date(this.props.accident.date.split("-").reverse().join("-")) : "",
					vehicle: this.props.accident.vehicle,
					driver: this.props.accident.driver,
					direction: this.props.accident.direction,
					injuryStatus: this.props.accident.injuryStatus,
					remark: this.props.accident.note,
					accidentLocation: this.props.accident.accidentLocation,
					compensationDate: this.props.accident.compensationDate,
				}, () => {
					let dirCurrent = this.state.dirRef.current,
						injuCurrent = this.state.injuRef.current,
						accCurrent = this.state.accLoRef.current,
						compenCurrent = this.state.compenRef.current;

					dirCurrent.innerHTML = "";
					injuCurrent.innerHTML = "";
					accCurrent.innerHTML = "";
					compenCurrent.innerHTML = "";

					populateList(this.state.direction, dirCurrent);
					populateList(this.state.injuryStatus, injuCurrent);
					populateList(this.state.accidentLocation, accCurrent);
					populateList(this.state.compensationDate, compenCurrent);
				});
			} else {
				this.props.find_accident({ id: this.state.id });
			}
		}
		// is data is already input
		if (Object.keys(handleData.Accident.data).length !== 0) {
			const data = handleData.Accident.data;
			const date = Moment(data.date).format("DD-MM-YYYY")
			this.setState({
				date: new Date(date.split("-").reverse().join("-")),
				vehicle: data.vehicle,
				driver: data.driver,
				direction: data.direction,
				injuryStatus: data.injuryStatus,
				remark: data.remark, //
				accidentLocation: data.accidentLocation,
				compensationDate: data.compensationDate,
			},
				() => {
					let dirCurrent = this.state.dirRef.current,
						injuCurrent = this.state.injuRef.current,
						accCurrent = this.state.accLoRef.current,
						compenCurrent = this.state.compenRef.current;

					dirCurrent.innerHTML = "";
					injuCurrent.innerHTML = "";
					accCurrent.innerHTML = "";
					compenCurrent.innerHTML = "";

					populateList(this.state.direction, dirCurrent);
					populateList(this.state.injuryStatus, injuCurrent);
					populateList(this.state.accidentLocation, accCurrent);
					populateList(this.state.compensationDate, compenCurrent);
				}
			);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.accident !== this.props.accident) {
			this.setState(
				{
					date: this.props.accident.date ? new Date(this.props.accident.date.split("-").reverse().join("-")) : "",
					vehicle: this.props.accident.vehicle,
					driver: this.props.accident.driver,
					direction: this.props.accident.direction,
					injuryStatus: this.props.accident.injuryStatus,
					remark: this.props.accident.note,
					accidentLocation: this.props.accident.accidentLocation,
					compensationDate: this.props.accident.compensationDate,
				},
				() => {
					let dirCurrent = this.state.dirRef.current,
						injuCurrent = this.state.injuRef.current,
						accCurrent = this.state.accLoRef.current,
						compenCurrent = this.state.compenRef.current;

					dirCurrent.innerHTML = "";
					injuCurrent.innerHTML = "";
					accCurrent.innerHTML = "";
					compenCurrent.innerHTML = "";

					populateList(this.state.direction, dirCurrent);
					populateList(this.state.injuryStatus, injuCurrent);
					populateList(this.state.accidentLocation, accCurrent);
					populateList(this.state.compensationDate, compenCurrent);
				}
			);
		}
	}
	componentWillUnmount() {
		handleData.Accident = {
			...handleData.Accident,
			data: {
				date: this.state.date,
				vehicle: this.state.vehicle,
				driver: this.state.driver,
				direction: this.state.direction,
				injuryStatus: this.state.injuryStatus,
				remark: this.state.remark,
				accidentLocation: this.state.accidentLocation,
				compensationDate: this.state.compensationDate,
			}
		};
	}
	resetForm() {
		this.setState({
			id: this.props.match.params?.id,
			date: new Date(),
			vehicle: "",
			driver: "",
			direction: [""],
			injuryStatus: [""],
			accidentLocation: [""],
			compensationDate: [""],
			remark: "",

			dirRef: createRef(),
			injuRef: createRef(),
			accLoRef: createRef(),
			compenRef: createRef(),

			isSummited: false,
			isRequesting: false,
			isShowConfirmation: false,
		});
	}

	gatherData() {
		if (isEmptyOrWhiteSpace(this.state.date, this.state.vehicle, this.state.driver, this.state.direction)) {
			let dataCollection = {
				date: Moment(this.state.date).format("YYYY-MM-DD"),
				vehicleId: this.state.vehicle.value,
				driver: this.state.driver,
				destination: formatbulletlist(this.state.direction),
				accidentLocation: formatbulletlist(this.state.accidentLocation),
				injuryStatus: formatbulletlist(this.state.injuryStatus),
				dateOfCompensation: formatbulletlist(this.state.compensationDate),
				note: this.state.remark,
			};

			return dataCollection;
		} else return null;
	}

	handleBack = () => {
		this.setState({ isShowConfirmation: false });
		setTimeout(() => {
			this.props.history.push(`${this.state.currentUrl}`);
		}, 100);
	};

	handleSave = () => {
		let accident = this.gatherData();
		this.setState({ isSummited: true });

		if (accident) {
			this.setState({ isRequesting: true });
			if (this.state.id) {
				accident.id = this.state.id;
				this.props.update_accident(accident, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("Accident")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			} else {
				this.setState({ isRequesting: true });
				this.props.add_accident(accident, (acknowledge, message) => {
					this.setState({ isRequesting: false });
					if (acknowledge) {
						this.resetForm();
						ResetRecords("Accident")
						this.setState({ isShowConfirmation: true });
					} else alert(message);
				});
			}
		}
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		return (
			<div suppressContentEditableWarning={true}>
				<Confirmation handleClose={this.handleBack} isShow={this.state.isShowConfirmation} confirmType="SUCCESS" desc={successDesc} />
				<ActionBar title={`${this.state.titlePrefix} Accident`}>
					<Button name={translation["BACK"]} color="secondary" size="md" buttonStyle="filled" onClick={this.handleBack} />
				</ActionBar>
				<div className="ut-d-flex">
					<div style={{ width: 400 }}>
						<div>
							<Label title={translation["Date"]} required />
							<LabelDatePicker
								disabled={this.state.titlePrefix === "View"}
								styles="ut-custom-label-datePicker-blocked"
								selected={this.state.date}
								onChange={(date) => this.setState({ date })}
							/>
							<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.date))}>Date is required</small>
						</div>
						<div>
							<Label title={translation["Vehicles"]} required />
							<LabelSelect
								isDisabled={this.state.titlePrefix === "View"}
								name="vehicle"
								placeholder={translation["Vehicles"]}
								options={this.props.vehicles}
								onChange={(selected) => this.setState({ vehicle: selected })}
								value={this.state.vehicle}
							/>
							<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.vehicle))}>Vehicle is required</small>
						</div>
						<div>
							<Label title={translation["Driver"]} required />
							<Input
								disabled={this.state.titlePrefix === "View"}
								name="driver"
								placeholder={translation["Driver"]}
								onChange={this.onChange}
								value={this.state.driver}
							/>
							<small hidden={!(this.state.isSummited && !isEmptyOrWhiteSpace(this.state.driver))}>Driver is required</small>
						</div>
						<BulletList
							placeholder={["ទិសដៅពីណា ទៅណា", "ទីតាំងគ្រោះថ្នាក់"]}
							name="ទិសដៅ និងទីតាំងគ្រោះថ្នាក់"
							disabled={this.state.titlePrefix === "View"}
							required
							bulletRef={this.state.dirRef}
							value={this.state.direction}
							isSummited={this.state.isSummited}
							onInput={(list) => this.setState({ direction: [...list] })}
						/>
						<BulletList
							placeholder={["ការខូចខាតរថយន្ដ", "ជនរងគ្រោះនិងស្ថានភាពរបួស"]}
							name={translation["injuryStatus"]}
							disabled={this.state.titlePrefix === "View"}
							bulletRef={this.state.injuRef}
							value={this.state.injuryStatus}
							isSummited={this.state.isSummited}
							onInput={(list) => this.setState({ injuryStatus: [...list] })}
						/>
					</div>
					<div style={{ width: 400 }}>
						<div>
							<Label title={translation["Remark"]} />
							<TextArea
								rows={5}
								value={this.state.remark}
								disabled={this.state.titlePrefix === "View"}
								onChange={this.onChange}
								name="remark"
								size="md"
								blocked
							/>
						</div>
						<BulletList
							placeholder={["មូលហេតុនៃការគ្រោះថ្នាក់"]}
							name="មូលហេតុនៃការគ្រោះថ្នាក់ និងការខូចខាតរថយន្ដ"
							disabled={this.state.titlePrefix === "View"}
							bulletRef={this.state.accLoRef}
							value={this.state.accidentLocation}
							isSummited={this.state.isSummited}
							onInput={(list) => this.setState({ accidentLocation: [...list] })}
						/>
						<BulletList
							placeholder={["កាលបរិច្ឆេទបញ្ចប់រឿង", "សំណងសរុប"]}
							name={translation["Compensation Date"]}
							disabled={this.state.titlePrefix === "View"}
							bulletRef={this.state.compenRef}
							value={this.state.compensationDate}
							isSummited={this.state.isSummited}
							onInput={(list) => this.setState({ compensationDate: [...list] })}
						/>
					</div>
				</div>
				{this.state.titlePrefix !== "View" ? (
					<Button
						name={
							this.state.id
								? this.state.isRequesting
									? translation["UPDATING"]
									: translation["UPDATE"]
								: this.state.isRequesting
									? translation["SAVING"]
									: translation["SAVE"]
						}
						color={this.state.id ? "warning" : "success"}
						size="md"
						buttonStyle="filled"
						onClick={this.handleSave}
					/>
				) : null}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	accident: state.Accident.accident,
	vehicles: state.Vehicle.vehicles,
});

export default connect(mapStateToProps, { add_accident, update_accident, find_accident })(index);
