import React from "react";
import Cookies from "js-cookie";
import { cookiesKey } from "../auth";
import { Redirect, Route } from "react-router-dom";

export const isAuthorized = () => {
	let authToken = Cookies.get(cookiesKey.accessToken);
	if (authToken !== undefined)
		if (authToken.trim() !== "") {
			return true;
		}
	return false;
};

export const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={(props) => (isAuthorized() ? <Component {...props} /> : <Redirect to={`/auth`} />)} />
);

export const PublicRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			isAuthorized() ? (
				props.location.pathname === rest.location.pathname ? (
					<Redirect to={`/app`} />
				) : (
					<Component {...props} />
				)
			) : (
				<Component {...props} />
			)
		}
	/>
);
