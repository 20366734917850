import React from "react";
import { Modal } from "react-bootstrap";

import { Button } from "../../components/UTControls";
import { ReactComponent as Exclamation } from "../../assets/icons/Exclamation.svg";

export default function index(props) {
	return (
		<Modal centered show={props.isShow} size="sm">
			<Modal.Body className="ut-all-center ut-flex-column" style={{ padding: 25 }}>
				<Exclamation className="ut-popup-animate" />
				<span style={{ fontSize: 12, padding: "10px 0", textAlign: "center" }}>តើអ្នកចង់លុបចោលការធ្វើដំណើរមួយនេះមែនទេ?</span>
				<div className="ut-d-flex">
					<Button onClick={props.handleConfirm} color="secondary" name="មែន" size="sm" buttonStyle="filled" />
					<Button onClick={props.handleReject} color="light" name="ទេ" size="sm" buttonStyle="filled" />
				</div>
			</Modal.Body>
		</Modal>
	);
}
