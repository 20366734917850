import React from "react";
import { ESize, EColor, EButtonStyle } from "./EnumOpt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IControl {
	name: string;
	size: ESize;
	buttonStyle: EButtonStyle;
	pilled: boolean;
	color: EColor;
	block: boolean;
	leftIcon?: any;
	rightIcon?: any;
	disabled: boolean;

	onClick(): void;
	onDoubleClick(): void;
}

export default function Button(props: IControl) {
	return (
		<div
			onClick={props.disabled ? undefined : props.onClick}
			onDoubleClick={props.disabled ? undefined : props.onDoubleClick}
			className={`ut-button ${props.size ? `ut-button-${props.size}` : "ut-button-md"} ${
				props.color ? `ut-button-${props.color}` : "ut-button-primary"
			} ${props.block ? `ut-button-block` : "ut-button-nonblock"} ${props.buttonStyle ? `ut-button-${props.buttonStyle}` : ""} ${
				props.pilled ? `ut-button-pilled` : ""
			} ${props.disabled ? `ut-button-disabled` : ""}`}
		>
			{props.leftIcon ? <FontAwesomeIcon className="ut-button-left-icon" size="lg" icon={props.leftIcon} /> : null}
			<span>{props.name}</span>
			{props.rightIcon ? <FontAwesomeIcon className="ut-button-right-icon" size="lg" icon={props.rightIcon} /> : null}
		</div>
	);
}
