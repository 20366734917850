import { GET_VEHICLE_REPAIR, FIND_VEHICLE_REPAIR } from "./types";

const initialState = {
	vehicleRepairs: [],
	vehicleRepair: {},
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case GET_VEHICLE_REPAIR:
			state.vehicleRepairs = actions.payload.vehicleRepairs;
			return { ...state };
		case FIND_VEHICLE_REPAIR:
			state.vehicleRepair = actions.payload.vehicleRepair;
			return { ...state };
		default:
			return { ...state };
	}
};
