import React from "react";
import "./assets/styles/index.scss";

import { Provider } from "react-redux";
import Store from "./store";
import RouterIndex from "./routes";

function App() {
	return (
		<Provider store={Store}>
			<RouterIndex />
		</Provider>
	);
}

export default App;
