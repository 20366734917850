import { ADD_VEHICLE_REPAIR, UPDATE_VEHICLE_REPAIR, DEL_VEHICLE_REPAIR, FIND_VEHICLE_REPAIR, GET_VEHICLE_REPAIR } from "./types";
import { baseUrl, headersJsonToken } from "../../auth/secret";
import { authfetch } from "../../auth";
import Moment from "moment";
import { dateOption } from "../../constants/variables";

export const add_vehicle_repair = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-broke/add`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: ADD_VEHICLE_REPAIR,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const update_vehicle_repair = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-broke/update`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: UPDATE_VEHICLE_REPAIR,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const del_vehicle_repair = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-broke/delete/${payload}`, {
		method: "POST",
		headers: headersJsonToken(),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: DEL_VEHICLE_REPAIR,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const fetch_vehicle_repair = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-broke/list`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			let vehicleRepairs = [];

			vehicleRepairs = data.body.data.map((vehicleRepair, i) => {
				let spareparts = [],
					sparepartsNote = "",
					description = [],
					descriptionNote = "";

				vehicleRepair.vehicleBrokeAccessoriesResponses.map((sparepart, i) => {
					if (i === 0) {
						sparepartsNote = `${sparepart.name} (${sparepart.status === 1 ? "ជួសជុល" : "ផ្លាស់ប្ដូរ"})`;
					} else sparepartsNote = `${sparepartsNote}, ${sparepart.name} (${sparepart.status === 1 ? "ជួសជុល" : "ផ្លាស់ប្ដូរ"})`;
					spareparts.push({
						label: sparepart.name,
						situation: sparepart.status,
					});

					return null;
				});

				vehicleRepair.note.split("\n").map((no, i) => {
					if (i === 0) {
						descriptionNote = no;
					} else descriptionNote = `${descriptionNote}, ${no}`;

					description.push(no);

					return null;
				});

				return {
					no: i + 1,
					value: vehicleRepair.id,
					vehicle: {
						value: vehicleRepair.vehicleId,
						label: vehicleRepair.vehicleName,
					},
					date: vehicleRepair.date ? Moment(vehicleRepair.date).format(dateOption) : "",
					totalAmount: vehicleRepair.amount,
					totalAmountText: `${vehicleRepair.amount} $`,
					description: description,
					descriptionNote: descriptionNote,
					spareparts,
					sparepartsNote,
				};
			});
			dispatch({
				type: GET_VEHICLE_REPAIR,
				payload: { vehicleRepairs },
			});
		})
		.catch((error) => {
			console.error(error);

			dispatch({
				type: GET_VEHICLE_REPAIR,
				payload: { vehicleRepairs: [] },
			});
		});
};

export const find_vehicle_repair = (payload) => (dispatch) => {
	if (payload.vehicleRepair) {
		dispatch({
			type: FIND_VEHICLE_REPAIR,
			payload: { vehicleRepair: payload.vehicleRepair },
		});
	} else {
		authfetch(`${baseUrl}/vehicle-broke/find/${payload.id}`, {
			method: "POST",
			headers: headersJsonToken(),
		})
			.then((data) => {
				let vehicleRepair = [];
				vehicleRepair = data.body.data.map((vehicleRepair, i) => {
					let spareparts = [],
						sparepartsNote = "",
						description = [],
						descriptionNote = "";

					vehicleRepair.vehicleBrokeAccessoriesResponses.map((sparepart, i) => {
						if (i === 0) {
							sparepartsNote = `${sparepart.name} (${sparepart.status === 1 ? "ជួសជុល" : "ផ្លាស់ប្ដូរ"})`;
						} else sparepartsNote = `${sparepartsNote}, ${sparepart.name} (${sparepart.status === 1 ? "ជួសជុល" : "ផ្លាស់ប្ដូរ"})`;
						spareparts.push({
							label: sparepart.name,
							situation: sparepart.status,
						});
						return null;
					});

					vehicleRepair.note.split("\n").map((no, i) => {
						if (i === 0) {
							descriptionNote = no;
						} else descriptionNote = `${descriptionNote}, ${no}`;
						description.push(no);
						return null;
					});

					return {
						no: i + 1,
						value: vehicleRepair.id,
						vehicle: {
							value: vehicleRepair.vehicleId,
							label: vehicleRepair.vehicleName,
						},
						date: vehicleRepair.date ? Moment(vehicleRepair.date).format(dateOption) : "",
						totalAmount: vehicleRepair.amount,
						totalAmountText: `${vehicleRepair.amount} $`,
						description: description,
						descriptionNote: descriptionNote,
						spareparts,
						sparepartsNote,
					};
				});

				dispatch({
					type: FIND_VEHICLE_REPAIR,
					payload: { vehicleRepair: vehicleRepair.length > 0 ? vehicleRepair[0] : {} },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: FIND_VEHICLE_REPAIR,
					payload: { vehicleRepair: {} },
				});
			});
	}
};
