import { SIDEBAR_MENU, UPDATE_USER_INFO } from "./types";
const initialState = {
	userId: "",
	username: "",
	avatar: "",

	Menus: [],
	permissionModuleList: {},
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case SIDEBAR_MENU: {
			state.Menus = actions.payload.Menus;
			state.permissionModuleList = actions.payload.permissionModuleList;
			return { ...state };
		}
		case UPDATE_USER_INFO: {
			state.userId = actions.payload.userId;
			state.username = actions.payload.username;
			state.avatar = actions.payload.avatar;
			return { ...state };
		}
		default:
			return { ...state };
	}
};
