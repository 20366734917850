import React from "react";
import { Modal } from "react-bootstrap";

import { isNA } from "../../helpers";
import { translation } from "../../constants/translate";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Orides } from "../../assets/icons/orides.svg";

export default function index(props) {
	return (
		<Modal centered show={props.isShow} size="md">
			<Modal.Body className="ut-all-center ut-flex-column" style={{ padding: 25 }}>
				<div className="w-100 d-flex align-items-center justify-content-center">
					<span style={{ fontSize: 18 }} className="ut-color-primary">
						{translation["Tracking"]}
					</span>
					<Close onClick={props.toggle} style={{ position: "absolute", right: 25, top: 20, cursor: "pointer" }} />
				</div>
				<br />
				<div className="ut-d-flex flex-column w-100">
					<div className="w-100 d-flex">
						<b className="ut-color-primary" style={{ width: "40%", textAlign: "left", whiteSpace: "nowrap" }}>
							{isNA(props.detail?.origin?.label, "-")}
						</b>
						<Orides />
						<b className="ut-color-primary" style={{ width: "40%", textAlign: "right", whiteSpace: "nowrap" }}>
							{isNA(props.detail?.destination?.label, "-")}
						</b>
					</div>
					<div className="w-100 d-flex justify-content-between">
						<span>{isNA(props.detail?.start, "-")}</span>
						<span>{isNA(props.detail?.end, "-")}</span>
					</div>
					<br />
				</div>
				<div className="w-100 d-flex flex-column">
					<div className="ut-bg-secondary w-100 d-flex" style={{ padding: "10px", borderRadius: 5, margin: "5px 0px" }}>
						<span className="ut-color-light" style={{ fontSize: 15, fontWeight: "bold", width: "40%" }}>
							ឆ្លងកាត់
						</span>
						<span className="ut-color-light" style={{ fontSize: 15, fontWeight: "bold", width: "30%" }}>
							ម៉ោងមកដល់
						</span>
						<span className="ut-color-light" style={{ fontSize: 15, fontWeight: "bold", width: "30%" }}>
							ម៉ោងចេញដំណើរ
						</span>
					</div>
					{props.detail?.passing &&
						props.detail?.passing.map((pass) => (
							<div
								className="w-100 d-flex"
								style={{ padding: "10px", borderRadius: 5, margin: "10px 0px", backgroundColor: "#F1F1F1" }}
							>
								<span style={{ fontSize: 13, width: "40%", whiteSpace: "nowrap" }}>{isNA(pass.label, "-")}</span>
								<span style={{ fontSize: 13, width: "30%", whiteSpace: "nowrap" }}>{isNA(pass.stopDate, "-")}</span>
								<span style={{ fontSize: 13, width: "30%", whiteSpace: "nowrap" }}>{isNA(pass.startDate, "-")}</span>
							</div>
						))}
				</div>
			</Modal.Body>
		</Modal>
	);
}
