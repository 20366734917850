import { ADD_ACCIDENT, UPDATE_ACCIDENT, DEL_ACCIDENT, FIND_ACCIDENT, GET_ACCIDENT } from "./types";
import { baseUrl, headersJsonToken, headersToken } from "../../auth/secret";
import { authfetch } from "../../auth";

import Moment from "moment";
import { dateOption } from "../../constants/variables";

export const add_accident = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-accident-info/add`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: ADD_ACCIDENT,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const update_accident = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-accident-info/update`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: UPDATE_ACCIDENT,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const del_accident = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-accident-info/delete/${payload}`, {
		method: "POST",
		headers: headersToken(),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: DEL_ACCIDENT,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const fetch_accident = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/vehicle-accident-info/list`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			let accidents = [];
			accidents = data.body.data.map((accident, i) => {
				let direction = [],
					directionNote = "",
					injuryStatus = [],
					injuryStatusNote = "",
					accidentLocation = [],
					accidentLocationNote = "",
					compensationDate = [],
					compensationDateNote = "";

				accident.destination &&
					accident.destination.split("\n").map((des, i) => {
						if (i === 0) {
							directionNote = des;
						} else directionNote = `${directionNote}, ${des}`;
						direction.push(des);

						return null;
					});

				accident.injuryStatus &&
					accident.injuryStatus.split("\n").map((inju, i) => {
						if (i === 0) {
							injuryStatusNote = inju;
						} else injuryStatusNote = `${injuryStatusNote}, ${inju}`;
						injuryStatus.push(inju);

						return null;
					});

				accident.accidentLocation &&
					accident.accidentLocation.split("\n").map((acc, i) => {
						if (i === 0) {
							accidentLocationNote = acc;
						} else accidentLocationNote = `${accidentLocationNote}, ${acc}`;
						accidentLocation.push(acc);

						return null;
					});

				accident.dateOfCompensation &&
					accident.dateOfCompensation.split("\n").map((compen, i) => {
						if (i === 0) {
							compensationDateNote = compen;
						} else compensationDateNote = `${compensationDateNote}, ${compen}`;
						compensationDate.push(compen);

						return null;
					});

				return {
					no: i + 1,
					value: accident.id,
					vehicle: { value: accident.vehicleId, label: accident.vehicleName },
					driver: accident.driver,
					direction,
					directionNote,

					injuryStatus,
					injuryStatusNote,

					note: accident.note,
					accidentLocation,
					accidentLocationNote,

					compensationDate,
					compensationDateNote,

					date: accident.date ? Moment(accident.date).format(dateOption) : "",
					createdDate: Moment(accident.created).format(dateOption),
				};
			});

			dispatch({
				type: GET_ACCIDENT,
				payload: { accidents },
			});
		})
		.catch((error) => {
			console.error(error);

			dispatch({
				type: GET_ACCIDENT,
				payload: { accidents: [] },
			});
		});
};

export const find_accident = (payload) => (dispatch) => {
	if (payload.accident) {
		dispatch({
			type: FIND_ACCIDENT,
			payload: { accident: payload.accident },
		});
	} else {
		authfetch(`${baseUrl}/vehicle-accident-info/find/${payload.id}`, {
			method: "POST",
			headers: headersToken(),
		})
			.then((data) => {
				let accident = [];
				accident = data.body.data.map((accident, i) => {
					let direction = [],
						directionNote = "",
						injuryStatus = [],
						injuryStatusNote = "",
						accidentLocation = [],
						accidentLocationNote = "",
						compensationDate = [],
						compensationDateNote = "";

					accident.destination &&
						accident.destination.split("\n").map((des, i) => {
							if (i === 0) {
								directionNote = des;
							} else directionNote = `${directionNote}, ${des}`;
							direction.push(des);

							return null;
						});

					accident.injuryStatus &&
						accident.injuryStatus.split("\n").map((inju, i) => {
							if (i === 0) {
								injuryStatusNote = inju;
							} else injuryStatusNote = `${injuryStatusNote}, ${inju}`;
							injuryStatus.push(inju);

							return null;
						});

					accident.accidentLocation &&
						accident.accidentLocation.split("\n").map((acc, i) => {
							if (i === 0) {
								accidentLocationNote = acc;
							} else accidentLocationNote = `${accidentLocationNote}, ${acc}`;
							accidentLocation.push(acc);

							return null;
						});

					accident.dateOfCompensation &&
						accident.dateOfCompensation.split("\n").map((compen, i) => {
							if (i === 0) {
								compensationDateNote = compen;
							} else compensationDateNote = `${compensationDateNote}, ${compen}`;
							compensationDate.push(compen);

							return null;
						});

					return {
						no: i + 1,
						value: accident.id,
						vehicle: { value: accident.vehicleId, label: accident.vehicleName },
						driver: accident.driver,
						direction,
						directionNote,
						injuryStatus,
						injuryStatusNote,
						note: accident.note,
						accidentLocation,
						accidentLocationNote,
						compensationDate,
						compensationDateNote,
						date: accident.date ? Moment(accident.date).format(dateOption) : "",
						createdDate: Moment(accident.created).format(dateOption),
					};
				});

				dispatch({
					type: FIND_ACCIDENT,
					payload: { accident: accident.length > 0 ? accident[0] : {} },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: FIND_ACCIDENT,
					payload: { accident: {} },
				});
			});
	}
};
