import React from "react";
import { connect } from "react-redux";
import { logout } from "../../auth";
import { baseUrl } from "../../auth/secret";
import Logout from "../LogoutDropDown";
import Profile from "../../assets/icons/profile.svg";
import Logo from "../../assets/images/logo.png";
import { isEmptyOrWhiteSpace } from "../../helpers";
import { APP_VERSION } from "../../constants/app";

class index extends React.Component {
   state = {
      isHidden: true,
   };
   _onToggle = () => {
      this.setState({ isHidden: !this.state.isHidden });
   };
   _onLogout = () => {
      logout();
   };

   render() {
      return (<div className="ut-top-navbar">
         <Logout hidden={this.state.isHidden} ToggleOverlay={this._onToggle} onLogout={this._onLogout} />
         <span>
            <img src={Logo} alt="" width="65px" />
            <span className="ut-app-title ml-3">Vehicle Tracking</span>
            <span style={{fontSize: "10px"}}> V{APP_VERSION}</span>
         </span>
         <div className="d-flex">
            {/*button GPS*/}
            {/* <a href="http://gpsvetjs.utebi.com/map" target="_blank" rel="noopener noreferrer">
                        <Button name="Open GPS" color="info" size="sm" buttonStyle="filled" />
                    </a> */}
            <span className="cursor-pointer ut-d-flex ut-align-items-center" onClick={this._onToggle}>
               <span className="mr-2" style={{ fontSize: "18px" }}>
                  {this.props.username}
               </span>
               <img src={isEmptyOrWhiteSpace(this.props.avatar) ? `${baseUrl}${this.props.avatar}` : Profile}
                  alt="" style={{ width: 35, height: 35, objectFit: "cover", borderRadius: 20 }} />
            </span>
         </div>
      </div>);
   }
}

const mapStateToProps = (state) => ({
   username: state.Layout.username, avatar: state.Layout.avatar,
});

export default connect(mapStateToProps, {})(index);
