export const handleData = {
   Dashboard: {
      records: []
   },
   VehicleRepair: {
      path: "list",
      data: {},
      filters: {},
      records: []
   },
   ChangeTire: {
      path: "list",
      data: {},
      filters: {},
      records: []
   },
   ChangeOil: {
      path: "list",
      data: {},
      filters: {},
      records: []
   },
   Accident: {
      path: "list",
      data: {},
      filters: {},
      records: []
   },
   VehicleType: {
      path: "list",
      data: {},
      filters: {},
      records: []
   },
   Vehicle: {
      path: "list",
      data: {},
      filters: {},
      records: []
   },
   Branch: {
      path: "list",
      data: {},
      filters: {},
      records: []
   },
   Tracking: {
      path: "list",
      data: {},
      filters: {},
      records: []
   },
   Report: {
      // data: {},
      filters: {},
      records: []
   },
   Setting: {
      path: "change-password"
   },
   SystemRole: {
      path: "list",
      data: {},
      filters: {},
      records: []
   },
   User: {
      path: "list",
      filters: {},
      records: [],
      data: {
         reset: {},
         addEdit: {},
      }
   },
   ChangePassword: {
      data: {},
      records: [],
      filters: {},
   }
}
export function GetPath(object, pathname) {
   const path = window.location.pathname;
   const currentPath = path.split(`app/${pathname}`)[1];

   if (currentPath !== object.path) {
      handleData[object].path = currentPath;
   }
}
export function SaveData(object, filters, records) {
   handleData[object] = {
      ...handleData[object],
      filters: filters,
      records: records
   };
}
export function ResetRecords(object) {
   handleData[object] = {
      ...handleData[object],
      filters: {},
      records: []
   };
}