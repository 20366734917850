import { GET_CHANGE_TIRE, FIND_CHANGE_TIRE } from "./types";

const initialState = {
	changeTires: [],
	changeTire: {},
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case GET_CHANGE_TIRE:
			state.changeTires = actions.payload.changeTires;
			return { ...state };
		case FIND_CHANGE_TIRE:
			state.changeTire = actions.payload.changeTire;
			return { ...state };
		default:
			return { ...state };
	}
};
