Object.byString = function (o, s) {
	s = s.replace(/\[(\w+)\]/g, ".$1");
	s = s.replace(/^\./, "");
	let a = s.split(".");
	for (let i = 0, n = a.length; i < n; ++i) {
		let k = a[i];
		if (k in o) {
			o = o[k];
		} else {
			return;
		}
	}
	return o;
};

export function Capitalizing(string, spliter) {
	let tempString = string.split(spliter),
		Capitalized = "";
	tempString.forEach((temp) => {
		Capitalized = `${Capitalized} ${temp.charAt(0).toUpperCase()}${temp.substring(1).toLowerCase()}`;
	});

	return Capitalized.trim();
}

export function titlePrefix(url) {
	let splittedUrl = url.split("/");
	if (splittedUrl[splittedUrl.length - 1] === "add") {
		return "Add";
	} else {
		return Capitalizing(splittedUrl[splittedUrl.length - 2], "-");
	}
}

export function isEmptyOrWhiteSpace(...testObject) {
	let objectCount = 0;
	testObject.forEach(function (element) {
		if (element !== null && element !== undefined) {
			switch (typeof element) {
				case "object":
					if (element.length !== undefined) {
						element.length !== 0 ? objectCount++ : (objectCount = objectCount + 0);
					} else {
						if (element instanceof Date) {
							element.valueOf() ? objectCount++ : (objectCount = objectCount + 0);
						} else Object.keys(element).length !== 0 && element.constructor === Object ? objectCount++ : (objectCount = objectCount + 0);
					}
					break;
				case "string":
					element.trim() !== "" ? objectCount++ : (objectCount = objectCount + 0);
					break;
				case "number":
					!isNaN(element) ? objectCount++ : (objectCount = objectCount + 0);
					break;
				default:
					return (objectCount = objectCount + 0);
			}
		}
		return (objectCount = objectCount + 0);
	});

	return objectCount === testObject.length;
}

export function formatbulletlist(objarray) {
	if (objarray.length) {
		let formatedlist = "";
		objarray.map((obj, i) => {
			if (isEmptyOrWhiteSpace(obj)) {
				if (i === objarray.length - 1) {
					formatedlist += `${obj}`;
				} else formatedlist += `${obj}\n`;
			}
			return null;
		});

		return formatedlist;
	} else return "";
}

export function populateList(list, ref) {
	if (list.length) {
		list.forEach((desc) => {
			let li = document.createElement("li");
			li.innerText = desc;
			ref.appendChild(li);
		});
	} else {
		let li = document.createElement("li");
		ref.appendChild(li);
	}
}

export function isNA(string, replacement = "-") {
	if (typeof string === "string" || typeof string === "number") {
		if (isEmptyOrWhiteSpace(string)) {
			return string.toString();
		} else return replacement.toString();
	} else return replacement.toString();
}

export const startOf = (type, date = new Date()) => {
	let result;
	switch (type) {
		case "day":
			result = new Date();
			break;
		case "week":
			let day = date.getDay();
			result = new Date(date.getFullYear(), date.getMonth(), date.getDate() + (day === 0 ? -6 : 1) - day);
			break;
		case "month":
			result = new Date(date.getFullYear(), date.getMonth(), 1);
			break;
		case "year":
			result = new Date(date.getFullYear(), 0, 1);
			break;
		default:
			result = date;
	}
	return result;
};

export const endOf = (type, date = new Date()) => {
	let result;
	switch (type) {
		case "day":
			result = new Date();
			break;
		case "week":
			let day = date.getDay();
			result = new Date(date.getFullYear(), date.getMonth(), date.getDate() + (day === 0 ? 0 : 7) - day);
			break;
		case "month":
			result = new Date(date.getFullYear(), date.getMonth() + 1, 0);
			break;
		case "year":
			result = new Date(date.getFullYear(), 11, 31);
			break;
		default:
			result = date;
	}
	return result;
};

export const lightOrDark = (color) => {
	// Variables for red, green, blue values
	var r, g, b, hsp;

	// Check the format of the color, HEX or RGB?
	if (color.match(/^rgb/)) {
		// If HEX --> store the red, green, blue values in separate variables
		color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

		r = color[1];
		g = color[2];
		b = color[3];
	} else {
		// If RGB --> Convert it to HEX: http://gist.github.com/983661
		color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

		r = color >> 16;
		g = (color >> 8) & 255;
		b = color & 255;
	}

	// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
	hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

	// Using the HSP value, determine whether the color is light or dark
	if (hsp > 127.5) {
		//Light
		return "#000000";
	} else {
		//Dark
		return "#FFFFFF";
	}
};
export function byteToAny(size, count = 0) {
	let SIZE = ["byte", "KB", "MB", "GB"],
		index = count;
	if (size) {
		if (size > 1024) {
			size = (size / 1024).toFixed(2);
			index = index + 1;
			if (size > 1024) {
				size = (size / 1024).toFixed(2);
				index = index + 1;
				if (size > 1024) {
					size = (size / 1024).toFixed(2);
					index = index + 1;
				}
			}
		}
		return `${size} ${SIZE[index]}`;
	} else return size;
}

export function validNumberOrZero(value) {
	if (value === null || isNaN(+value)) {
		return 0;
	} else {
		return +value;
	}
}

export function durationFormat(min) {
	if (min !== "-") {
		let hours = Math.floor(min / 60);
		let mins = min - hours * 60;

		return `${hours}h ${mins}m`;
	} else return min;
}

const khmerNum = ["០", "១", "២", "៣", "៤", "៥", "៦", "៧", "៨", "៩"];
export function toKhmerNum(enNum) {
	return enNum
		.toString()
		.split("")
		.map((en) => khmerNum[en])
		.join("");
}
