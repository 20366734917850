import moment from 'moment';
import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading';
import { Button, LabelDatePicker, LabelSelect } from '../../../components/UTControls';
import { deleteDesc, successDesc, vehicleRepairHeaders } from '../../../constants/dictionary';
import { SaveData, handleData } from '../../../constants/handleData';
import { translation } from '../../../constants/translate';
import ActionBar from '../../../containers/ActionBar';
import Confirmation from '../../../containers/modals/Confirmation';
import { SortIcon, _generateTableHeader, tableStyle } from '../../../helpers/tableHelper';
import { fetch_vehicle } from '../../../redux/Vehicle/actions';
import { del_vehicle_repair, fetch_vehicle_repair, find_vehicle_repair } from '../../../redux/VehicleRepair/actions';
import { fetch_vehicle_type } from '../../../redux/VehicleType/actions';

class index extends Component {
	state = {
		records: [],
		selectedRecord: {},
		dateFrom: '',
		vehicle: '',
		vehicleType: '',
		isLoading: true,
		isShowConfirmation: false,
		confirmationDesc: deleteDesc,
		confirmationType: 'DELETE',

		currentUrl: '/app/vehicle-repair',
		dataTableColumns: [],

		allowAdd: this.props.permissionModuleList[this.props.actionName]?.moduleList.ADD,
		allowEdit: this.props.permissionModuleList[this.props.actionName]?.moduleList.EDIT,
		allowDelete: this.props.permissionModuleList[this.props.actionName]?.moduleList.DELETE,
	};

	componentDidMount = () => {
		handleData.VehicleRepair = {
			...handleData.VehicleRepair,
			path: "list",
			data: {}
		};
		// check if data already exists set2State but not fetch it
		const vehicleRepair = handleData.VehicleRepair
		if (vehicleRepair.records.length > 0) {
			this.setState({
				isLoading: false,
				records: vehicleRepair.records,
				dateFrom: vehicleRepair.filters.dateFrom,
				vehicleType: vehicleRepair.filters.vehicleType,
				vehicle: vehicleRepair.filters.vehicle,
			});
		} else {
			this.props.fetch_vehicle_repair({ page: 1, rowsPerPage: 1000 });
			this.props.fetch_vehicle_type({ page: 1, rowsPerPage: 1000 });
			this.props.fetch_vehicle({ page: 1, rowsPerPage: 1000 });
		}
		this.setPermissions();
	};
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.permissionModuleList !== this.props.permissionModuleList) {
			this.setPermissions();
		}
		if (prevProps.vehicleRepairs !== this.props.vehicleRepairs) {
			this.setState({ records: this.props.vehicleRepairs, isLoading: false }, () => {
				const filters = {
					dateFrom: this.state.dateFrom,
					vehicleType: this.state.vehicleType,
					vehicle: this.state.vehicle
				}
				SaveData("VehicleRepair", filters, this.state.records)
			});
		}
	}
	setPermissions = () => {
		this.setState({
			allowAdd: this.props.permissionModuleList[this.props.actionName]?.moduleList.ADD,
			allowEdit: this.props.permissionModuleList[this.props.actionName]?.moduleList.EDIT,
			allowDelete: this.props.permissionModuleList[this.props.actionName]?.moduleList.DELETE
		}, () => {
			let permission = this.handleLayoutPermission();
			this.setState({ dataTableColumns: _generateTableHeader(vehicleRepairHeaders, permission.actions, permission.events) });
		});
	};

	handleLayoutPermission = () => {
		let actions = ['view'],
			events = [this.handleView];

		if (this.state.allowEdit) {
			actions.push('edit');
			events.push(this.handleEdit);
		}
		if (this.state.allowDelete) {
			actions.push('delete');
			events.push(this.handleTrigger);
		}
		return { actions, events };
	};
	handleAdd = () => {
		handleData.VehicleRepair = { ...handleData.VehicleRepair, path: 'add' };
		this.props.history.push(`${this.state.currentUrl}/add`);
	};
	handleView = (record) => {
		handleData.VehicleRepair = { ...handleData.VehicleRepair, path: `view/${record.value}` };
		this.props.find_vehicle_repair({ vehicleRepair: record });
		this.props.history.push(`${this.state.currentUrl}/view/${record.value}`);
	}
	handleEdit = (record) => {
		handleData.VehicleRepair = { ...handleData.VehicleRepair, path: `edit/${record.value}` };
		this.props.find_vehicle_repair({ vehicleRepair: record });
		this.props.history.push(`${this.state.currentUrl}/edit/${record.value}`);
	};
	handleDelete = () => {
		this.setState({ isShowConfirmation: false });
		this.props.del_vehicle_repair(this.state.selectedRecord?.value, (acknowledge, message) => {
			if (acknowledge) {
				this.props.fetch_vehicle_repair({ page: 1, rowsPerPage: 1000 });
				this.setState({ isShowConfirmation: true, confirmationType: 'SUCCESS', confirmationDesc: successDesc });
			} else alert(message);
		});
	};
	handleTrigger = (record = {}) => {
		this.setState({ isShowConfirmation: !this.state.isShowConfirmation }, () => {
			setTimeout(() => {
				this.setState({ confirmationType: 'DELETE', confirmationDesc: deleteDesc, selectedRecord: record });
			}, 200);
		});
	};

	render() {
		return (
			<div>
				<Confirmation
					handleClose={this.handleTrigger}
					handleConfirm={this.handleDelete}
					handleReject={this.handleTrigger}
					isShow={this.state.isShowConfirmation}
					confirmType={this.state.confirmationType}
					desc={this.state.confirmationDesc}
				/>
				<ActionBar title={this.props.actionName}>
					<LabelDatePicker
						isClearable
						selected={this.state.dateFrom}
						placeholder={translation['DATE']}
						floater={translation['DATE']}
						onChange={(date) => {
							this.setState({ isLoading: true })
							if (date !== null) this.setState({ dateFrom: date }, () => {
								let requestDate = this.state.dateFrom ? moment(this.state.dateFrom).format('YYYY-MM-DD') : undefined;
								this.props.fetch_vehicle_repair({ page: 1, rowsPerPage: 1000, dateFrom: requestDate, dateTo: requestDate, vehicleId: this.state.vehicle?.value, vehicleTypeId: this.state.vehicleType?.value });
							});
							else this.setState({ dateFrom: '' }, () => {
								this.props.fetch_vehicle_repair({ page: 1, rowsPerPage: 1000, vehicleId: this.state.vehicle?.value, vehicleTypeId: this.state.vehicleType?.value });
							});
						}}
					/>
					<LabelSelect
						floater={translation['Vehicle Type']}
						placeholder={translation['Vehicle Type']}
						isClearable
						value={this.state.vehicleType}
						onChange={(selected) => {
							this.setState({ vehicleType: selected, isLoading: true });
							let requestDate = this.state.dateFrom ? moment(this.state.dateFrom).format('YYYY-MM-DD') : undefined;
							this.props.fetch_vehicle_repair({ page: 1, rowsPerPage: 1000, dateFrom: requestDate, dateTo: requestDate, vehicleId: this.state.vehicle?.value, vehicleTypeId: selected?.value });
							this.props.fetch_vehicle({ page: 1, rowsPerPage: 1000, vehicleTypeId: selected?.value });
						}}
						options={this.props.vehicleTypes}
					/>
					<LabelSelect
						floater={translation['Vehicles']}
						placeholder={translation['Vehicles']}
						isClearable
						value={this.state.vehicle}
						onChange={(selected) => {
							this.setState({ vehicle: selected, isLoading: true });
							let requestDate = this.state.dateFrom ? moment(this.state.dateFrom).format('YYYY-MM-DD') : undefined;
							this.props.fetch_vehicle_repair({
								page: 1, rowsPerPage: 1000,
								dateFrom: requestDate,
								dateTo: requestDate,
								vehicleId: selected?.value,
								vehicleTypeId: this.state.vehicleType?.value
							});
						}}
						options={this.props.vehicles}
					/>
					<Button name={translation["REFRESH"]} buttonStyle="filled"
						onClick={() => {
							this.setState({ isLoading: true, records: [], dateFrom: '', vehicle: '', vehicleType: '' });
							this.props.fetch_vehicle_repair({ page: 1, rowsPerPage: 1000 });
						}}
					/>
					{this.state.allowAdd && <Button name={translation['ADD NEW']} color="success" size="md" buttonStyle="filled" onClick={this.handleAdd} />}
				</ActionBar>
				<DataTable
					fixedHeader
					noDataComponent={<span style={{ margin: '20px 0px' }}>គ្មានទិន្នន័យ</span>}
					fixedHeaderScrollHeight="calc(100vh - 220px)"
					noHeader
					pointerOnHover
					striped
					highlightOnHover
					data={this.state.records}
					columns={this.state.dataTableColumns}
					sortIcon={<SortIcon />}
					customStyles={tableStyle}
					progressPending={this.state.isLoading}
					progressComponent={<Loading />}
					pagination={true}
					paginationPerPage={100}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	vehicleRepairs: state.VehicleRepair.vehicleRepairs,
	vehicleTypes: state.VehicleType.vehicleTypes,
	vehicles: state.Vehicle.vehicles,
	actionName: state.Navs.actionName,

	permissionModuleList: state.Layout.permissionModuleList,
});

export default connect(mapStateToProps, { fetch_vehicle_repair, del_vehicle_repair, find_vehicle_repair, fetch_vehicle_type, fetch_vehicle })(index);
