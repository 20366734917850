import { CHANGE_SIDEBAR, CHANGE_REPORT_SIDEBAR, CHANGE_SETTING_SIDEBAR } from "./types";

export const ChangeSidebar = (payload) => (dispatch) => {
	dispatch({
		type: CHANGE_SIDEBAR,
		payload,
	});
};
export const ChangeReportSidebar = (payload) => (dispatch) => {
	dispatch({
		type: CHANGE_REPORT_SIDEBAR,
		payload,
	});
};
export const ChangeSettingSidebar = (payload) => (dispatch) => {
	dispatch({
		type: CHANGE_SETTING_SIDEBAR,
		payload,
	});
};
