import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import { Button, Select } from "../../components/UTControls";

function UpdateDestination(props) {
	let [selectedDestination, setDestination] = useState();
	useEffect(() => {
		setDestination(props.destination);
	}, [props.destination]);
	return (
		<Modal centered show={props.isShow} size="md">
			<Modal.Body className="ut-all-center ut-flex-column ut-w-100" style={{ padding: 25 }}>
				<span className="ut-color-primary" style={{ padding: "10px 0", textAlign: "center", fontWeight: "bold" }}>
					ផ្លាស់ប្ដូរសាខា
				</span>
				<div style={{ width: "100%" }}>
					<span style={{ fontSize: 14, padding: "0 10px" }}>
						សាខា <code>*</code>
					</span>
					<Select options={props.allbranches} onChange={(selected) => setDestination(selected)} value={selectedDestination} size="sm" />
				</div>
				<div className="ut-d-flex ut-w-100">
					<div style={{ width: "50%" }}>
						<Button block onClick={props.handleReject} color="light" name="ចាកចេញ" size="sm" buttonStyle="filled" />
					</div>
					<div style={{ width: "50%" }}>
						<Button
							block
							onClick={() => props.handleConfirm(selectedDestination)}
							color="secondary"
							name="កែប្រែ"
							size="sm"
							buttonStyle="filled"
						/>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
}

const mapStateToProps = (state) => ({
	allbranches: state.Branch.allbranches,
});

export default connect(mapStateToProps, {})(UpdateDestination);
