import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileCopyTwoTone } from "@material-ui/icons";
import React from "react";
import InputMask from "react-input-mask";
import { EColor, EInputStyle, ESize } from "./EnumOpt";

interface IControl {
	title: string;
	name: string;
	placeholder: string;
	value: string;
	mask: string;
	maskChar: string;
	alwaysShowMask: boolean;
	size: ESize;
	inputStyle: EInputStyle;
	color: EColor;
	type: string;
	leftIcon?: any;
	rightIcon?: any;
	autoFocus: boolean;
	disabled: boolean;
	multiple: boolean;
	accept: string;
	hidden: boolean;
	isCopy: boolean;

	onChange(): void;
	onKeyUp(): void;
	onKeyDown(): void;
	onKeyPress(): void;
	onFocus(): void;
	onBlur(): void;
	onClick(): void;
}
export default function index(props: IControl) {
	const handleCopy = () => {
		navigator.clipboard.writeText(props.value).then(function () {
			console.log(`Copied ${props.value}`);
		})
	};

	return (
		<div className={`ut-input ${props.size ? `ut-input-${props.size}` : "ut-input-md"} ${props.color ? `ut-input-${props.color}` : "ut-input-primary"
			} ${props.inputStyle ? `ut-input-${props.inputStyle}` : "ut-input-rounded"} ${props.disabled ? `ut-input-disabled` : ""}`}>
			{props.leftIcon && <FontAwesomeIcon className="ut-input-left-icon" size="lg" icon={props.leftIcon} />}
			<InputMask
				title={props.title}
				autoFocus={props.autoFocus}
				mask={props.mask}
				maskChar={props.maskChar}
				alwaysShowMask={props.alwaysShowMask}
				autoComplete="off"
				className="ut-inner-input"
				onChange={props.onChange}
				onKeyUp={props.onKeyUp}
				onKeyDown={props.onKeyDown}
				onKeyPress={props.onKeyPress}
				onFocus={props.onFocus}
				onBlur={props.onBlur}
				name={props.name}
				type={props.type}
				value={props.value}
				disabled={props.disabled}
				placeholder={props.placeholder}
				hidden={props.hidden}
				multiple={props.multiple}
				accept={props.accept}
			/>

			{props.rightIcon && !props.isCopy && <FontAwesomeIcon className="ut-input-right-icon" icon={props.rightIcon} />}
			{props.isCopy && (
				<span style={{ cursor: "pointer" }} hidden={props.disabled}>
					<FileCopyTwoTone style={{ fontSize: "20px" }} onClick={handleCopy} />
				</span>
			)}
		</div>
	);
}
