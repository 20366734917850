import React from "react";
import { connect } from "react-redux";

import { APP_NAME } from "../constants/app";
import { enableContext } from "../auth/secret";
import { getUserInfo } from "../auth";
import { SetupSidebar } from "../redux/Layouts/actions";

import TopNavBar from "../containers/navigations/TopNavBar";
import Sidebar from "../containers/navigations/Sidebar";

const AllMenu = ["Dashboard", "Vehicle Type", "Vehicles", "Branches", "Report", "Users", "System Roles"];

class index extends React.Component {
	state = {
		isLoading: true,
		showSideBar: true,
	};

	componentDidMount() {
		getUserInfo();
		this.props.SetupSidebar((acknowledge) => {
			if (acknowledge === true) {
				this.setState({ isLoading: false });
			}
		});
	}

	handleSideBar() {
		AllMenu.map((menu, i) => {
			if (i === 0) {
				this.setState({ showSideBar: !this.props.permissionModuleList[menu]?.moduleList?.VIEW });
			} else {
				if (this.props.permissionModuleList[menu]?.moduleList?.VIEW) {
					this.setState({ showSideBar: true });
				}
			}
			return null;
		});
	}

	componentDidUpdate(prevProps, prevState, snapShot) {
		if (prevProps.permissionModuleList !== this.props.permissionModuleList) {
			if (enableContext) {
				if (window.location.pathname === `/${APP_NAME}/app/tv-view`) {
					this.setState({ showSideBar: false });
				}
			} else {
				if (window.location.pathname === `/app/tv-view`) {
					this.setState({ showSideBar: false });
				}
			}

			// this.handleSideBar();
		}
	}

	render() {
		return (
			<div className="ut-admin-layout">
				<TopNavBar />
				{!this.state.isLoading &&
					(this.state.showSideBar ? (
						<div className="ut-admin-menu">
							<Sidebar />
							{this.props.children}
						</div>
					) : (
						this.props.children
					))}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	permissionModuleList: state.Layout.permissionModuleList,
});

export default connect(mapStateToProps, {
	SetupSidebar,
})(index);
