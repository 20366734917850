import { GET_DASHBOARD, GET_TVDASHBOARD } from "./types";

const initialState = {
	dashboard: [],
	tvDashboard: [],
	pagination: {
		page: 0,
		rowsPerPage: 0,
		total: 0,
	},
};

export default (state = initialState, actions) => {
	switch (actions.type) {
		case GET_DASHBOARD:
			state.dashboard = actions.payload.dashboard;
			return { ...state };
		case GET_TVDASHBOARD:
			state.tvDashboard = actions.payload.tvDashboard;
			state.pagination = actions.payload.pagination;
			return { ...state };
		default:
			return { ...state };
	}
};
