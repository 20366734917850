import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { ReactComponent as ExportExcelIco } from "../../../../assets/icons/export-excel.svg";
import Loading from "../../../../components/Loading";
import { Button, Input } from "../../../../components/UTControls";
import { deleteDesc, successDesc, userHeaders } from "../../../../constants/dictionary";
import { SaveData, handleData } from "../../../../constants/handleData";
import { translation } from "../../../../constants/translate";
import ActionBar from "../../../../containers/ActionBar";
import Confirmation from "../../../../containers/modals/Confirmation";
import { SortIcon, _generateTableHeader, tableStyle } from "../../../../helpers/tableHelper";
import { del_user, fetch_user, find_user } from "../../../../redux/User/actions";

class index extends Component {
	state = {
		records: [],
		selectedRecord: {},
		search: "",
		isLoading: true,
		isShowConfirmation: false,
		confirmationDesc: deleteDesc,
		confirmationType: "DELETE",

		currentUrl: "/app/setting/users",
		dataTableColumns: [],

		allowAdd: this.props.permissionModuleList[this.props.actionName]?.moduleList.ADD,
		allowEdit: this.props.permissionModuleList[this.props.actionName]?.moduleList.EDIT,
		allowDelete: this.props.permissionModuleList[this.props.actionName]?.moduleList.DELETE,
	};

	componentDidMount() {
		handleData.User = { ...handleData.User, path: "list", data: { reset: {}, addEdit: {} } };
		// check if data already exists setState but not fetch it
		const users = handleData.User
		if (users.records.length > 0) {
			this.setState({ records: users.records, search: users.filters.search, isLoading: false });
		} else {
			this.props.fetch_user({ page: 1, rowsPerPage: 1000, searchText: this.state.search });
		}
		this.setPermissions()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.permissionModuleList !== this.props.permissionModuleList) {
			this.setPermissions()
		}
		if (prevProps.users !== this.props.users) {
			this.setState({ records: this.props.users, isLoading: false }, () => {
				const filters = { search: this.state.search }
				SaveData("User", filters, this.state.records)
			});
		}
	}
	setPermissions = () => {
		this.setState({
			allowAdd: this.props.permissionModuleList[this.props.actionName]?.moduleList.ADD,
			allowEdit: this.props.permissionModuleList[this.props.actionName]?.moduleList.EDIT,
			allowDelete: this.props.permissionModuleList[this.props.actionName]?.moduleList.DELETE,
		}, () => {
			let permission = this.handleLayoutPermission();
			this.setState({ dataTableColumns: _generateTableHeader(userHeaders, permission.actions, permission.events) });
		});
	}
	handleLayoutPermission = () => {
		let actions = [],
			events = [];

		if (this.state.allowEdit) {
			actions.push("reset");
			events.push(this.handleReset);

			actions.push("edit");
			events.push(this.handleEdit);
		}
		if (this.state.allowDelete) {
			actions.push("delete");
			events.push(this.handleTrigger);
		}
		return { actions, events };
	};

	handleAdd = () => {
		handleData.User = { ...handleData.User, path: 'add' };
		this.props.history.push(`${this.state.currentUrl}/add`);
	};

	handleReset = (record) => {
		handleData.User = { ...handleData.User, path: `reset/${record.value}` };
		this.props.find_user({ user: record });
		this.props.history.push(`${this.state.currentUrl}/reset/${record.value}`);
	};

	handleEdit = (record) => {
		handleData.User = { ...handleData.User, path: `edit/${record.value}` };
		this.props.find_user({ user: record });
		this.props.history.push(`${this.state.currentUrl}/edit/${record.value}`);
	};

	handleDelete = () => {
		this.setState({ isShowConfirmation: false });
		this.props.del_user(this.state.selectedRecord?.value, (acknowledge, message) => {
			if (acknowledge) {
				this.props.fetch_user({ page: 1, rowsPerPage: 1000, searchText: this.state.search });
				this.setState({ isShowConfirmation: true, confirmationType: "SUCCESS", confirmationDesc: successDesc });
			} else alert(message);
		});
	};

	handleTrigger = (record = {}) => {
		this.setState({ isShowConfirmation: !this.state.isShowConfirmation }, () => {
			setTimeout(() => {
				this.setState({ confirmationType: "DELETE", confirmationDesc: deleteDesc, selectedRecord: record });
			}, 200);
		});
	};

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	onKeyPress = (e) => {
		if (e.key === "Enter") {
			this.setState({ isLoading: true });
			this.props.fetch_user({ page: 1, rowsPerPage: 1000, /* vehicleTypeId: this.state.vehicleType?.value, */ searchText: this.state.search });
		}
	};

	render() {
		return (
			<div style={{ width: "100%", overflow: "auto" }}>
				<Confirmation
					handleClose={this.handleTrigger}
					handleConfirm={this.handleDelete}
					handleReject={this.handleTrigger}
					isShow={this.state.isShowConfirmation}
					confirmType={this.state.confirmationType}
					desc={this.state.confirmationDesc}
				/>
				<ActionBar title="Users" underlined>
					<Input
						name="search"
						value={this.state.search}
						placeholder={translation["search"]}
						rightIcon={faSearch}
						onChange={this.onChange}
						onKeyPress={this.onKeyPress}
					/>
					<ExcelExport
						data={this.state.records}
						ref={(excel) => { this._export = excel }}
						fileName={`User ${new Date()}.xlsx`}
						filterable={true}
					>
						<div className="ut-export-button-wrapper"
							onClick={() => { this._export.save() }}>
							<div><ExportExcelIco /></div>
							<span className="ut-export-button">EXPORT AS EXCEL</span>
						</div>
						<ExcelExportColumn field="no" title="N°" />
						<ExcelExportColumn field="label" title="NAME" />
						<ExcelExportColumn field="username" title="USERNAME" />
						<ExcelExportColumn field="systemRole.label" title="SYSTEM ROLE" />
						<ExcelExportColumn field="createdDate" title="CREATED DATE" />
					</ExcelExport>
					<Button name={translation["REFRESH"]} buttonStyle="filled"
						onClick={() => {
							this.setState({ isLoading: true, records: [], search: '' });
							this.props.fetch_user({ page: 1, rowsPerPage: 1000 });
						}}
					/>
					{this.state.allowAdd && (
						<Button name={translation["ADD NEW"]} color="success" size="md" buttonStyle="filled" onClick={this.handleAdd} />
					)}
				</ActionBar>
				<DataTable
					fixedHeader
					noDataComponent={<span style={{ margin: "20px 0px" }}>គ្មានទិន្នន័យ</span>}
					fixedHeaderScrollHeight="calc(100vh - 220px)"
					noHeader
					pointerOnHover
					striped
					highlightOnHover
					data={this.state.records}
					columns={this.state.dataTableColumns}
					sortIcon={<SortIcon />}
					customStyles={tableStyle}
					progressPending={this.state.isLoading}
					progressComponent={<Loading />}
					pagination={true}
					paginationPerPage={100}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	users: state.User.users,
	actionName: state.Navs.actionName,
	permissionModuleList: state.Layout.permissionModuleList,
});

export default connect(mapStateToProps, { fetch_user, del_user, find_user })(index);
