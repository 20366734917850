import { GET_BRANCH, GET_ALL_BRANCH, ADD_BRANCH, UPDATE_BRANCH, DEL_BRANCH, FIND_BRANCH } from "./types";
import { baseUrl, headersJsonToken, headersToken } from "../../auth/secret";
import { authfetch } from "../../auth";

import Moment from "moment";
import { dateOption } from "../../constants/variables";

export const add_branch = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/Branche/branche-add`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: ADD_BRANCH,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const update_branch = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/Branche/branche-update`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: UPDATE_BRANCH,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const del_branch = (payload, callback) => (dispatch) => {
	authfetch(`${baseUrl}/Branche/delete/${payload}`, {
		method: "POST",
		headers: headersToken(),
	})
		.then((data) => {
			callback(data.header.result && data.body.status, data.body.message);
			dispatch({
				type: DEL_BRANCH,
			});
		})
		.catch((error) => {
			console.error(error);
			callback(false, error);
		});
};

export const fetch_branch = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/Branche/listForWeb`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			let branches = [];
			branches = data.body.data.map((branch, i) => {
				let userList = [],
					vehicleTypeList = [];

				userList = branch.userResponseList.map((user) => {
					return { value: user.userId, label: user.fullName, avatar: user.photo };
				});

				vehicleTypeList = branch.branchVehicleTypeResponseList.map((vehicleType) => {
					return { value: vehicleType.vehicleTypeId, label: vehicleType.vehicleTypeName };
				});

				return {
					no: i + 1,
					value: branch.id,
					label: branch.name,
					userList,
					vehicleTypeList,
					createdDate: Moment(branch.created).format(dateOption),
				};
			});

			dispatch({
				type: GET_BRANCH,
				payload: { branches },
			});
		})
		.catch((error) => {
			console.error(error);
			dispatch({
				type: GET_BRANCH,
				payload: { branches: [] },
			});
		});
};

export const fetch_allbranch = (payload) => (dispatch) => {
	authfetch(`${baseUrl}/Branche/list`, {
		method: "POST",
		headers: headersJsonToken(),
		body: JSON.stringify(payload),
	})
		.then((data) => {
			let allbranches = [];
			allbranches = data.body.data.map((branch, i) => {
				let userList = [],
					vehicleTypeList = [];

				userList = branch.userResponseList.map((user) => {
					return { value: user.userId, label: user.fullName, avatar: user.photo };
				});

				vehicleTypeList = branch.branchVehicleTypeResponseList.map((vehicleType) => {
					return { value: vehicleType.vehicleTypeId, label: vehicleType.vehicleTypeName };
				});

				return {
					no: i + 1,
					value: branch.id,
					label: branch.name,
					userList,
					vehicleTypeList,
					createdDate: Moment(branch.created).format(dateOption),
				};
			});

			dispatch({
				type: GET_ALL_BRANCH,
				payload: { allbranches },
			});
		})
		.catch((error) => {
			console.error(error);
			dispatch({
				type: GET_ALL_BRANCH,
				payload: { allbranches: [] },
			});
		});
};

export const find_branch = (payload) => (dispatch) => {
	if (payload.branch) {
		dispatch({
			type: FIND_BRANCH,
			payload: { branch: payload.branch },
		});
	} else {
		authfetch(`${baseUrl}/Branche/find/${payload.id}`, {
			method: "POST",
			headers: headersToken(),
		})
			.then((data) => {
				let branch = [];
				branch = data.body.data.map((branch, i) => {
					let userList = [],
						vehicleTypeList = [];

					userList = branch.userResponseList.map((user) => {
						return { value: user.userId, label: user.fullName, avatar: user.photo };
					});

					vehicleTypeList = branch.branchVehicleTypeResponseList.map((vehicleType) => {
						return { value: vehicleType.vehicleTypeId, label: vehicleType.vehicleTypeName };
					});

					return {
						no: i + 1,
						value: branch.id,
						label: branch.name,
						userList,
						vehicleTypeList,
						createdDate: Moment(branch.created).format(dateOption),
					};
				});

				dispatch({
					type: FIND_BRANCH,
					payload: { branch: branch.length > 0 ? branch[0] : {} },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: FIND_BRANCH,
					payload: { branch: {} },
				});
			});
	}
};
