import React from "react";

export default class errorBoundary extends React.Component {
	state = {
		isError: false,
		error: null,
		errorInfo: null,
	};

	componentDidCatch(error, errorInfo) {
		this.setState({
			isError: true,
			error,
			errorInfo,
		});
	}

	render() {
		if (this.state.isError) {
			return (
				<div className="ut-error-wrapper">
					<div className="ut-error-card">
						<h3>Error</h3>
						<span>The website encountered an unexprected error.</span>
						<div className="ut-error-message">
							<i>
								{this.state.error ? this.state.error : `Something went wrong. ${window.location.hostname}${window.location.pathname}`}
							</i>
						</div>
					</div>
					<span>
						Contact Us: <b>023 881 887 | 081 881 887</b>
					</span>
					<span>
						Powered By: <b>UDAYA Technology Co., Ltd.</b>
					</span>
				</div>
			);
		}
		return this.props.children;
	}
}
