import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { handleData } from "../../../constants/handleData";
import SettingSidebar from "../../../containers/navigations/SettingSidebar";
import { GetModuleType } from "../../../redux/ModuleType/actions";
import { ChangeSettingSidebar, ChangeSidebar } from "../../../redux/Navs/actions";
import ChangePassword from "./changePassword";
import SystemRole from "./systemRoles";
import User from "./users";

class index extends React.Component {
	state = { componentMounted: false };
	componentDidMount() {
		this.props.GetModuleType({ page: 1, rowsPerPage: 1000 });
		this.props.ChangeSidebar({ currentPage: this.props.match.url, actionName: "change-password" });
		this.setState({ componentMounted: true });
	}

	render() {
		const { match } = this.props;
		return (
			this.state.componentMounted && (
				<div className="ut-setting-layout">
					<SettingSidebar />
					<Switch>
						<Redirect exact from={`${match.url}`} to={`${match.url}/${handleData.Setting.path}`} />
						<Route path={`${match.url}/change-password`} component={ChangePassword} />
						<Route path={`${match.url}/system-roles`} component={SystemRole} />
						<Route path={`${match.url}/users`} component={User} />
					</Switch>
				</div>
			)
		);
	}
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { ChangeSidebar, ChangeSettingSidebar, GetModuleType })(index);
